import { DocChildFragment, CustomerFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, Tooltip, CustomerAvatar as CustomerAvatarUI } from '@cycle-app/ui';
import { CustomerIconOutline, AddIcon, OpenIcon } from '@cycle-app/ui/icons';
import { isEmail } from '@cycle-app/utilities';
import {
  useMemo, FC, useCallback, MouseEvent, useState, ReactChild, ReactNode, useEffect,
} from 'react';

import { CompanyAddModal } from 'src/components/CustomersList/CompanyAddModal';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useMaybeMeV2 } from 'src/hooks';
import { useCustomerDocFromCache } from 'src/hooks/api/cache/cacheCustomerDoc';
import { useUpdateDocCustomer } from 'src/hooks/api/mutations/useUpdateDocCustomer';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { useMeCustomer } from 'src/hooks/api/queries/customers/useMeCustomer';
import { useCustomerFromBoardConfig } from 'src/hooks/api/useCustomerFromBoardConfig';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useGetDocType, useGetDocTypeName } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getCustomerOption, isDefaultCustomer, renderCustomerOptionLabel } from 'src/utils/customers.util';

import { CustomersAddCustomerModal } from '../CustomersList/CustomersAddCustomerModal';
import DropdownLayer from '../DropdownLayer/DropdownLayer';
import { FloatingPenButton } from '../FloatingPenButton/FloatingPenButton';
import {
  EditButtonContainer, StyledActionButton,
  TooltipCustomerContent, TooltipCustomerContentUpdateLabel,
  OpenCustomerButton, StyledCustomerCompanyAction, ActionButtonCursor, Name,
  CreateSection, CreateButton,
} from './DocCustomer.styles';

type DocCustomerProps = {
  className?: string;
  /* eslint-disable @typescript-eslint/indent */
  doc: Pick<DocChildFragment,
    'assignee' |
    'attributes' |
    'childrenCount' |
    'threads' |
    'threadsCount' |
    'createdAt' |
    'creator' |
    'customer' |
    'docTargetsCount' |
    'docTargetsAiCount' |
    'doctype' |
    'id' |
    'insightsCount' |
    'isDraft' |
    'source' |
    'status' |
    'title'>;
  /* eslint-enable @typescript-eslint/indent */
  isCompact?: boolean;
  isDisabled?: boolean;
  onCompanyModalOpen?: (companyId: string) => void;
  onCustomerModalOpen?: (customerId: string) => void;
  onSelect?: (isNotCompatible: boolean) => void;
  onDocUpdated?: (customer: CustomerFragment | undefined) => void;
  placeholderIcon?: ReactChild;
  isRemovable?: boolean;
  showWarnings?: boolean;
  context?: 'doc-item';
  hideIncompatibleValues?: boolean;
  disableCreation?: boolean;
  children?: ReactNode;
  forceOpenEditCustomer?: boolean;
  onCloseChange?: VoidFunction;
  openDropdownTimeout?: number | null;
};

export const DocCustomer: FC<React.PropsWithChildren<DocCustomerProps>> = ({
  className,
  doc,
  isCompact,
  isDisabled = false,
  onCompanyModalOpen,
  onCustomerModalOpen,
  onSelect,
  onDocUpdated,
  placeholderIcon = <CustomerIconOutline />,
  isRemovable = true,
  showWarnings,
  context,
  hideIncompatibleValues,
  disableCreation,
  children,
  forceOpenEditCustomer = false,
  onCloseChange,
  openDropdownTimeout,
}) => {
  const docType = useGetDocType(doc.doctype.id);
  if (!docType?.customer?.id) return null;

  if (isDisabled) {
    return doc.customer?.id
      ? (
        <StyledActionButton
          className={className}
          size="L"
          onClick={(e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
            if (!doc?.customer?.id || !onCustomerModalOpen) return;
            e.stopPropagation();
            e.preventDefault();
            onCustomerModalOpen(doc.customer.id);
          }}
          $isSelected={!!doc.customer?.id}
          $context={context}
        >
          <CustomerAvatar
            customer={doc.customer}
            isCompact={isCompact}
            onCompanyModalOpen={onCompanyModalOpen}
            isDisabled
            showCompanyAvatar
          />
          {!isCompact && <Name>{doc.customer.displayName}</Name>}
        </StyledActionButton>
      )
      : (
        <ActionButtonCursor $isCompact={isCompact} $context={context}>
          <StyledActionButton className={className} disabled size="L" $context={context}>
            <AvatarPlaceholder isCompact={isCompact} isDisabled icon={placeholderIcon} />
          </StyledActionButton>
        </ActionButtonCursor>
      );
  }

  return (
    <EditCustomer
      className={className}
      onCustomerModalOpen={onCustomerModalOpen}
      onSelect={(isNotCompatible) => {
        onSelect?.(isNotCompatible);
        onCloseChange?.();
      }}
      onDocUpdated={onDocUpdated}
      doc={doc}
      isCompact={isCompact}
      isRemovable={isRemovable}
      showWarnings={showWarnings}
      context={context}
      hideIncompatibleValues={hideIncompatibleValues}
      disableCreation={disableCreation}
      showEditButton={!children}
      forceOpen={forceOpenEditCustomer}
      onCloseChange={onCloseChange}
      openDropdownTimeout={openDropdownTimeout}
    >
      {children}
      {doc.customer?.id && !children && (
        <>
          <CustomerAvatar
            customer={doc.customer}
            isCompact={isCompact}
            onCompanyModalOpen={onCompanyModalOpen}
            isDisabled={isDisabled}
            showCompanyAvatar
          />
          {!isCompact && <Name>{doc.customer.displayName}</Name>}
        </>
      )}
      {!doc.customer?.id && !children && <AvatarPlaceholder isCompact={isCompact} icon={placeholderIcon} />}
    </EditCustomer>
  );
};

type EditCustomerProps = {
  className?: string;
  doc: DocCustomerProps['doc'];
  isCompact: DocCustomerProps['isCompact'];
  onCustomerModalOpen: DocCustomerProps['onCustomerModalOpen'];
  onSelect: DocCustomerProps['onSelect'];
  onDocUpdated?: (customer: CustomerFragment | undefined) => void;
  isRemovable?: boolean;
  showWarnings?: boolean;
  context?: 'doc-item';
  hideIncompatibleValues?: boolean;
  disableCreation?: boolean;
  showEditButton?: boolean;
  forceOpen?: boolean;
  onCloseChange?: VoidFunction;
  openDropdownTimeout?: number | null;
};

export const EditCustomer: FC<React.PropsWithChildren<EditCustomerProps>> = ({
  className,
  children,
  doc,
  isCompact,
  onCustomerModalOpen,
  onSelect,
  onDocUpdated,
  isRemovable = true,
  showWarnings,
  context,
  hideIncompatibleValues,
  disableCreation,
  showEditButton = true,
  forceOpen = false,
  onCloseChange,
  openDropdownTimeout = null,
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { me } = useMaybeMeV2();
  const docTypeName = useGetDocTypeName(doc.doctype.id);

  const [isDropdownVisible, {
    setFalseCallback: hideSelectDropdown,
    setTrueCallback: showSelectDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (openDropdownTimeout !== null) {
      timeoutId = setTimeout(showSelectDropdown, openDropdownTimeout);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [openDropdownTimeout, showSelectDropdown]);

  const [isAddCustomerVisible, { toggleCallback: toggleAddCustomer }] = useOptimizedBooleanState(false);
  const [isAddCompanyVisible, { toggleCallback: toggleAddCompany }] = useOptimizedBooleanState(false);

  const [searchText, setSearchText] = useState('');
  const { customers } = useCustomers({ defaultSearch: searchText });

  const customerDocCache = useCustomerDocFromCache();
  const {
    isCustomerCompatibleWithBoardConfig,
    customerIsRequired,
    companyIsRequired,
  } = useCustomerFromBoardConfig();

  // find me as customer in customers result
  const meCustomerFromList = useMemo(() => customers.find(customer => customer.email === me?.email), [customers, me?.email]);

  const meCustomerQuery = useMeCustomer({
    // skip query if me is already present in the customers result
    skip: !!meCustomerFromList || customers.length === 0,
  });

  const customerOptions = useMemo<SelectOption[]>(() => {
    // place me as first customer in list
    const meCustomer = meCustomerFromList ?? meCustomerQuery?.me;
    const restCustomers = meCustomer ? customers.filter(customer => customer.id !== meCustomer?.id) : customers;
    const sortedCustomers = meCustomer ? [meCustomer, ...restCustomers] : customers;

    return sortedCustomers
      .filter(customer => (!hideIncompatibleValues || isCustomerCompatibleWithBoardConfig(customer)))
      .map(customer => ({
        ...customer,
        _compatibleWithBoardConfig: doc.isDraft || isCustomerCompatibleWithBoardConfig(customer),
      })).map(customer => ({
        ...getCustomerOption(customer, showWarnings, docTypeName),
        ...(onCustomerModalOpen && {
          renderLabel: (filterText) => (
            renderCustomerOptionLabel({
              customer,
              filterText,
              lineHover: (
                <OpenCustomerButton
                  size="S"
                  onClick={e => {
                    e.stopPropagation();
                    onCustomerModalOpen(customer.id);
                  }}
                >
                  <OpenIcon />
                  Open
                </OpenCustomerButton>
              ),
            })
          ),
        }),
      }));
  }, [
    meCustomerFromList, meCustomerQuery?.me, customers, hideIncompatibleValues,
    isCustomerCompatibleWithBoardConfig, doc.isDraft, showWarnings, docTypeName, onCustomerModalOpen,
  ]);

  const { updateDocCustomer } = useUpdateDocCustomer();

  const handleCloseSelectDropdown = async () => {
    hideSelectDropdown();
    setSearchText('');
    onCloseChange?.();
  };

  const onButtonClicked = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (doc.customer?.id && !isCompact && onCustomerModalOpen) {
      onCustomerModalOpen?.(doc.customer.id);
      return;
    }
    showSelectDropdown();
  }, [showSelectDropdown, doc, isCompact, onCustomerModalOpen]);

  const isVisible = isDropdownVisible || forceOpen;

  return (
    <>
      <DropdownLayer
        layer={Layer.DropdownModal}
        visible={isVisible}
        hide={handleCloseSelectDropdown}
        placement="bottom-start"
        width={490}
        content={(
          customerOptions && (
            <SelectPanel
              filterOptionsOnInputChange={false}
              options={customerOptions}
              onSearchChange={setSearchText}
              onOptionChange={handleOnOptionChange}
              onClearValue={isRemovable ? handleRemoveCustomer : undefined}
              warningOnNoneValue={!doc.isDraft && (customerIsRequired || companyIsRequired)}
              docTypeName={docTypeName}
              debounceSearch
            >
              {!disableCreation && (
                <CreateSection>
                  <CreateButton
                    variant="secondary"
                    isSelected={false}
                    useCycleColor={false}
                    hoverDisabled={false}
                    onClick={toggleAddCompany}
                  >
                    Create company
                  </CreateButton>
                  <CreateButton
                    variant="secondary"
                    isSelected={false}
                    useCycleColor={false}
                    hoverDisabled={false}
                    onClick={toggleAddCustomer}
                  >
                    Create person
                  </CreateButton>
                </CreateSection>
              )}
            </SelectPanel>
          )
        )}
      >
        {showEditButton ? (
          <EditButtonContainer
            $isCompact={isCompact}
            $context={context}
          >
            <StyledActionButton
              className={className}
              size="L"
              forceFocus={isVisible}
              onClick={onButtonClicked}
              $isSelected={!!doc.customer?.id}
              $context={context}
              forwardedAs="div"
            >
              {children}
            </StyledActionButton>
            {!isCompact && doc.customer?.id && !doc.isDraft && (
              <FloatingPenButton tooltip="Edit customer" tooltipPlacement="top" onClick={showSelectDropdown} />
            )}
          </EditButtonContainer>
        ) : (
          <StyledActionButton
            className={className}
            size="L"
            forceFocus={isVisible}
            onClick={onButtonClicked}
            $isSelected={!!doc.customer?.id}
            $context={context}
            forwardedAs="div"
          >
            {children}
          </StyledActionButton>
        )}

      </DropdownLayer>
      {isAddCustomerVisible && (
        <CustomersAddCustomerModal
          productId={productId}
          defaultValues={{
            name: isEmail(searchText, false) ? '' : searchText,
            email: isEmail(searchText, false) ? searchText : '',
          }}
          layer={Layer.ModalZ2}
          onClose={toggleAddCustomer}
          onCreated={async data => {
            await handleCloseSelectDropdown();
            await handleOnOptionChange({
              value: data.id,
              label: '',
            });
          }}
        />
      )}
      {isAddCompanyVisible && (
        <CompanyAddModal
          productId={productId}
          defaultValues={{ name: searchText }}
          layer={Layer.ModalZ2}
          onClose={toggleAddCompany}
          onCreated={async data => {
            /**
             * When a company is created, it comes with a default customer
             * called `Main contact`. This is the new customer to attach to
             * the doc.
             */
            await handleCloseSelectDropdown();

            const newCustomerId = data.createCompany?.customers.edges?.[0]?.node.id;

            if (!newCustomerId) return;

            await handleOnOptionChange({
              value: newCustomerId,
              label: data.createCompany?.customers.edges?.[0]?.node.name ?? '',
            });
          }}
        />
      )}
    </>
  );

  async function handleOnOptionChange({ value }: SelectOption) {
    const isNotCompatible = !isCustomerCompatibleWithBoardConfig(customers.find(({ id }) => value === id));
    onSelect?.(isNotCompatible);
    const newCustomer = customers.find(c => value === c.id);
    customerDocCache.toggleCustomerDoc({
      customer: doc.customer,
      newCustomer,
      doc,
      doctypeId: doc.doctype.id,
    });
    await updateDocCustomer({
      docId: doc.id,
      customerId: value,
    }, isNotCompatible);
    await handleCloseSelectDropdown();
    onDocUpdated?.(newCustomer);
  }

  async function handleRemoveCustomer() {
    if (doc.customer?.id) {
      customerDocCache.removeCustomerDoc({ doc });
    }
    await updateDocCustomer({
      docId: doc.id,
    }, customerIsRequired || companyIsRequired);
    await handleCloseSelectDropdown();
  }
};

type CustomerAvatarProps = {
  customer: CustomerFragment;
  isCompact: DocCustomerProps['isCompact'];
  isDisabled?: DocCustomerProps['isDisabled'];
  onClick?: (e: MouseEvent) => void;
  onCompanyModalOpen?: DocCustomerProps['onCompanyModalOpen'];
  showCompanyAvatar?: boolean;
};

export const CustomerAvatar: FC<React.PropsWithChildren<CustomerAvatarProps>> = ({
  customer,
  isCompact,
  isDisabled = false,
  onClick,
  onCompanyModalOpen,
  showCompanyAvatar = false,
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  return (
    <CustomerAvatarUI
      customer={customer}
      src={showCompanyAvatar && customer.company?.logo ? customer.company.logo : undefined}
      tooltip={isCompact && (
        <TooltipCustomerContent>
          <div>{customer.displayName}</div>
          {!isDisabled && (
            <TooltipCustomerContentUpdateLabel>
              Change customer
            </TooltipCustomerContentUpdateLabel>
          )}
        </TooltipCustomerContent>
      )}
      title="Customer"
      size="S"
      isCompanyEditable={!isDefaultCustomer(customer) && !isDisabled}
      onCompanyClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (customer?.company?.id) {
          onCompanyModalOpen?.(customer.company.id);
        }
      }}
      {...onCompanyModalOpen && !showCompanyAvatar && customer.company && {
        companyTitle: customer.company.name,
        companyTooltip: 'Open company page',
      }}
      companyInput={!isDisabled && !showCompanyAvatar ? (
        <StyledCustomerCompanyAction customer={customer} size="S" productId={productId}>
          <AddIcon />
        </StyledCustomerCompanyAction>
      ) : undefined}
      onClick={onClick}
      hideCompany={showCompanyAvatar}
      showCompanyAvatar
    />
  );
};

type AvatarPlaceholderProps = {
  isCompact: DocCustomerProps['isCompact'];
  icon: DocCustomerProps['placeholderIcon'];
  isDisabled?: boolean;
};

const AvatarPlaceholder: FC<React.PropsWithChildren<AvatarPlaceholderProps>> = ({
  isCompact,
  isDisabled,
  icon,
}) => {
  const compactContent = isDisabled
    ? <>{icon}</>
    : (
      <Tooltip
        title="Add customer"
        content=""
        placement="top"
        withPortal
      >
        {icon}
      </Tooltip>
    );

  return isCompact
    ? compactContent : (
      <>
        {icon}
        <Name>{isDisabled ? 'No customer' : 'Add customer'}</Name>
      </>
    );
};
