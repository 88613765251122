import { Spinner } from '@cycle-app/ui';
import { WorkspaceIcon, DocumentIcon, InboxIcon, YCIcon, DashboardIcon } from '@cycle-app/ui/icons';

import { DaysInput } from 'src/components/CommandBar/CommandK/CommandK.styles';
import { getCommandbar, setCommandbar, useGetCommandbar } from 'src/reactives/commandbar.reactive';
import { setTemplate } from 'src/reactives/template.reactive';
import { CommandActionCategory, CommandActionType } from 'src/types/commandbar.types';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { setDashboardUpdateModal } from '../../reactives/dashboardUpdateModal.reactive';
import { addToaster } from '../../utils/toasters.utils';
import { useProduct } from '../api/useProduct';
import { closeCommandBar, openCommandBar } from '../modals/useCommandBarModal';
import { useProductYcDeal } from '../product/useProductYcDeal';
import { useProductYcDealUpdate } from '../product/useProductYcDealUpdate';
import { useBulkProcessFeedbacks } from '../useBulkProcessFeedbacks';
import { useExtendTrial } from '../useExtendTrial';
import { useStartTrial } from '../useStartTrial';

export const useAdminActions = (): CommandActionCategory => {
  const { startTrial } = useStartTrial();
  const { extendTrial } = useExtendTrial();
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { isYcDeal } = useProductYcDeal({ productId });
  const {
    enableYcDeal, isLoading: enableYcDealLoading,
  } = useProductYcDealUpdate();
  const { processFeedbacks } = useBulkProcessFeedbacks();
  const { product } = useProduct();
  const { extendTrialDays } = useGetCommandbar();

  let actions: CommandActionType[] = [
    {
      id: 'create-workspace',
      label: 'Create workspace',
      icon: <WorkspaceIcon />,
      onSelect: () => openCommandBar('create-workspace'),
    },
    {
      id: 'edit-templates',
      label: 'Update templates',
      icon: <DocumentIcon />,
      onSelect: () => {
        setTemplate({
          modalVisible: true,
          admin: true,
          selectedTemplateId: null,
          mode: 'list',
        });
        closeCommandBar();
      },
    },
    {
      id: 'start-trial',
      label: 'Start trial on this workspace',
      icon: <WorkspaceIcon />,
      onSelect: async () => {
        if (!product) return;
        await startTrial({ productId: product.id });
        closeCommandBar();
      },
    },
    {
      id: 'bulk-process-feedbacks',
      label: 'Bulk process feedback',
      icon: <InboxIcon />,
      onSelect: () => {
        if (!product) return;
        // No need to wait to close the modal
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        processFeedbacks(product.id);
        closeCommandBar();
      },
    },
    {
      id: 'toggle-addon',
      label: 'Toggle addon',
      icon: <WorkspaceIcon />,
      onSelect: () => openCommandBar('toggle-addon'),
    },
    {
      id: 'update-dashboards',
      label: 'Update dashboards',
      icon: <DashboardIcon />,
      onSelect: () => {
        closeCommandBar();
        setDashboardUpdateModal({ isOpened: true });
      },
    },
  ];

  const isFreeTrial = !!product?.endOfFreeTrial;

  if (isFreeTrial) {
    actions = [
      ...actions,
      {
        id: 'extend-trial',
        label: 'Set trial on this workspace to',
        postLabel: (
          <>
            <DaysInput
              type="number"
              value={extendTrialDays}
              onChange={e => setCommandbar({ extendTrialDays: Number(e.target.value) })}
              onClick={e => e.stopPropagation()}
            />
            <span>days</span>
          </>
        ),
        icon: <WorkspaceIcon />,
        onSelect: async () => {
          if (!product) return;
          await extendTrial(product.id, getCommandbar().extendTrialDays);
          closeCommandBar();
        },
      },
      ...isYcDeal ? [] : [{
        id: 'enable-yc',
        label: 'Enable YC Deal in this workspace',
        icon: enableYcDealLoading ? (
          <Spinner style={{
            width: '16px',
            height: '16px',
          }}
          />
        ) : <YCIcon width={16} height={16} />,
        onSelect: async () => {
          const result = await enableYcDeal();
          if (result.data?.setYcDeal?.id) {
            addToaster({
              title: 'YC Deal enabled',
            });
            closeCommandBar();
          }
        },
      }],
    ];
  }

  return {
    id: 'admin-actions',
    label: 'Admin',
    actions,
  };
};

export const useCreateWorkspaceResult = (): Array<CommandActionCategory> => [{
  id: 'create-workspace',
  actions: [],
}];
