import { QuoteFragment, UpdateQuoteDocument } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import uniq from 'lodash/uniq';
import { useState } from 'react';
import { isPresent } from 'ts-is-present';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import { getDocType, getInsightDocType, useInsightParentDocTypeIds } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocResultFromCache } from 'src/utils/cache.utils';

import { EditQuoteFeatureTitle } from './EditQuoteFeatureTitle';
import { QuoteFeatureOptions } from './QuoteFeatureOptions';
import { ParentLabel, ParentButton, ParentButtonContainer, ParentText, StyledAddIcon } from './VerifyQuoteModal.styles';

type Props = {
  quote: QuoteFragment;
};

export const QuoteFeatureDropdown = ({ quote }: Props) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [isEditing, setIsEditing] = useState(false);
  const [updateQuote] = useSafeMutation(UpdateQuoteDocument);
  const parentDocTypeIds = useInsightParentDocTypeIds();
  const possibleDoctypeIds = uniq([
    quote?.parent?.doctype.id,
    quote?.suggestedParentDoctype?.id,
    ...parentDocTypeIds,
  ].filter(isPresent));

  const insightDocType = getInsightDocType();
  if (!insightDocType) return null;

  const doctypeName = getDocType(possibleDoctypeIds[0])?.name.toLowerCase() ?? 'feature';

  // Possible doctype names separated by commas and "or" for the last one
  const possibleDoctypeNames = possibleDoctypeIds
    .reduce((acc, id, index) => {
      const name = getDocType(id)?.name.toLowerCase();
      if (!name) return acc;
      if (index === 0) return name;
      if (index === possibleDoctypeIds.length - 1) return `${acc} or ${name}`;
      return `${acc}, ${name}`;
    }, '');

  if (isEditing && quote?.parent) {
    return (
      <EditQuoteFeatureTitle
        parentId={quote.parent.id}
        defaultValue={quote.parent.title}
        hide={() => setIsEditing(false)}
      />
    );
  }

  return (
    <ToggleDropdown
      withPortal
      withWrapper={false}
      layer={Layer.DropdownModalZ1}
      placement="bottom-start"
      content={props => (
        <DocParentPanel
          docType={insightDocType}
          placeholder={`Link to ${possibleDoctypeNames}`}
          hide={props.hide}
          onChange={parentId => {
            const parent = parentId ? getDocResultFromCache(parentId) : null;
            if (!parentId || !parent) return;
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateQuote({
              variables: {
                id: quote?.id,
                parentId,
              },
              optimisticResponse: {
                updateQuote: {
                  ...quote,
                  parent: {
                    id: parentId,
                    title: parent.title,
                    doctype: {
                      id: parent.doctype.id,
                    },
                  },
                },
              },
            });
          }}
          showNoneOption={false}
          possibleDoctypeIds={possibleDoctypeIds}
          suggestedParentName={quote?.suggestedParentName}
          searchVariables={{
            doctypeIds: possibleDoctypeIds,
            childDoctypeId: null,
          }}
          searchSuggestionsVariables={{
            productId,
            text: quote.content,
            doctypeIds: possibleDoctypeIds[0],
            childDoctypeId: null,
          }}
          recentSearchVariables={{
            doctypeIds: possibleDoctypeIds[0],
            childDoctypeId: null,
          }}
        />
      )}
      button={props => (
        <ParentButtonContainer
          $active={props['data-active']}
        >
          <Tooltip
            withPortal
            withWrapper={false}
            placement="top"
            content={`Change ${doctypeName}`}
            disabled={!quote?.parent}
          >
            <ParentButton
              type="button"
              onClick={props.toggle}
            >
              {quote?.parent ? (
                <ParentLabel>
                  <DocTypeIcon
                    size={12}
                    doctype={getDocType(quote.parent.doctype.id)}
                  />
                  <ParentText>{quote.parent.title}</ParentText>
                </ParentLabel>
              ) : (
                <ParentLabel>
                  <StyledAddIcon size={12} />
                  <ParentText $gradient>{`Link to recommended ${doctypeName}`}</ParentText>
                  <AiIcon hasGradient size={14} />
                </ParentLabel>

              )}
            </ParentButton>
          </Tooltip>

          {quote.parent && (
            <QuoteFeatureOptions
              quote={quote}
              onSelectEdit={() => setIsEditing(true)}
            />
          )}
        </ParentButtonContainer>
      )}
    />
  );
};
