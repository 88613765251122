import { Language, Tone } from '@cycle-app/graphql-codegen';
import { Popover } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { ERROR_CODE, hasMessage } from '@cycle-app/utilities';
import { useState, useRef, useCallback, useEffect } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useAiStreamSubscription } from 'src/hooks/api/useAiStreamSubscription';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { useEditorAi, resetEditorAi, getEditorAi, setEditorAi, useEditorAiValue } from 'src/reactives';
import { posToDOMRect } from 'src/utils/editor/editor.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { EditorAiActionButtons } from './EditorAiActionButtons';
import { useEditorAiActions } from './EditorAIMenu.hooks';
import {
  AiMenuContainer,
  PromptClose,
} from './EditorAiMenu.styles';
import { getHTMLFromSelection } from './EditorAiMenu.utils';
import { EditorAiNextStepButtons } from './EditorAiNextStepButtons';
import { EditorAiPromptContainer } from './EditorAiPromptContainer';
import { EditorAiSuggestionContainer } from './EditorAiSuggestionContainer';

import type { EditorAiAction } from 'src/types/editor.types';

export const EditorAiMenu = () => {
  const editor = useEditorContext(ctx => ctx.editor);
  const editorId = useEditorContext(ctx => ctx.id);
  const [{
    overlay, visible, isPromptCommand, suggestion,
  }] = useEditorAi();

  const {
    state: {
      selection: {
        from, to,
      },
    },
  } = editor;

  useEffect(() => {
    if (visible === editorId && !isPromptCommand) {
      resetEditorAi();
    }
    /**
     * Intended dependencies, we want to reset ai only when selection is
     * changing and is empty
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const offsetAdapter = useTippyOffsetAdapter();

  useEffect(() => {
    const timeoutId = setTimeout(offsetAdapter.forceUpdate, 100);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestion]);

  return (
    <Popover
      content={(
        // visible !== editorId ? <AiContent /> : undefined
        <AiContentGuard />
      )}
      placement="bottom-start"
      interactive
      getReferenceClientRect={() => posToDOMRect(editor.view, from, to)}
      visible={visible === editorId}
      hide={() => {
        const {
          visible: isV, suggestion: suggest, isLanguageDropdownOpen, isToneDropdownOpen,
        } = getEditorAi();
        if (isV === editorId && !suggest && !isLanguageDropdownOpen && !isToneDropdownOpen) {
          resetEditorAi();
        }
      }}
      appendTo={overlay || undefined}
      {...offsetAdapter.tippyProps}
    />
  );
};

const AiContentGuard = () => {
  const { visible } = useEditorAiValue();
  return visible ? <AiContent /> : null;
};

const AiContent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  /**
   * Used to store the last translate or tone used, so when used wants to retry
   * we use that value
   */
  const lastDataUsed = useRef<Language | Tone | null>(null);
  const [prompt, setPrompt] = useState('');
  const editor = useEditorContext(ctx => ctx.editor);
  const editorId = useEditorContext(ctx => ctx.id);
  const { askEditorAi } = useEditorAiActions();
  useAiStreamSubscription();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    const { isPromptCommand } = getEditorAi();
    if (isPromptCommand) {
      timeoutId = setTimeout(() => {
        inputRef.current?.click();
      }, 200);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const { visible } = getEditorAi();
      if (e.key === 'Escape' && visible) {
        e.stopPropagation();
        const {
          isLoading, suggestion,
        } = getEditorAi();
        if (suggestion || isLoading) {
          setEditorAi({ isWarningVisible: true });
        } else {
          setEditorAi({ visible: '' });
        }
      }
    };
    window.document.addEventListener('keyup', handleKeyPress);
    return () => {
      window.document.removeEventListener('keyup', handleKeyPress);
    };
  }, []);

  const askAi = useCallback(async (
    actionParam: EditorAiAction,
    data?: Language | Tone | string,
  ) => {
    setEditorAi({ action: actionParam });
    try {
      setEditorAi({ isLoading: true });
      const {
        snapshotSelection, visible,
      } = getEditorAi();
      const selection = snapshotSelection
        ? getHTMLFromSelection(editor, snapshotSelection)
        : '';
      const params = (() => {
        if (actionParam === 'prompt') {
          return {
            action: actionParam,
            prompt,
            selection,
          };
        }
        if (actionParam === 'adjust-tone') {
          return {
            action: actionParam,
            text: selection,
            tone: data as Tone,
          };
        }
        if (actionParam === 'translate') {
          return {
            action: actionParam,
            text: selection,
            language: data as Language,
          };
        }
        return {
          action: actionParam as Exclude<EditorAiAction, 'prompt' | 'adjust-tone' | 'translate'>,
          text: selection,
        };
      })();

      await askEditorAi(params);
      if (visible === editorId) {
        setEditorAi({
          isLoading: false,
        });
      }
    } catch (e) {
      setEditorAi({ isLoading: false });
      addErrorToaster({
        message: hasMessage(e) && e.message === ERROR_CODE.NOT_AUTHENTICATED
          ? ErrorMessage.NOT_AUTHENTICATED
          : ErrorMessage._GENERIC,
      });
    }
  }, [askEditorAi, prompt, editor, editorId]);

  return (
    <AiMenuContainer onMouseDown={e => e.preventDefault()}>
      <PromptClose onClick={() => {
        const {
          isLoading, suggestion,
        } = getEditorAi();
        if (suggestion || isLoading) {
          setEditorAi({ isWarningVisible: true });
        } else {
          resetEditorAi();
        }
      }}
      >
        <CloseIcon />
      </PromptClose>
      <EditorAiSuggestionContainer />
      <EditorAiPromptContainer
        ref={inputRef}
        askAi={askAi}
        prompt={prompt}
        setPrompt={setPrompt}
        onInputFocus={() => inputRef.current?.focus()}
      />
      <EditorAiNextStepButtons askAi={askAi} lastDataUsed={lastDataUsed} />
      <EditorAiActionButtons askAi={askAi} lastDataUsed={lastDataUsed} />
    </AiMenuContainer>
  );
};
