import { Flex } from '@cycle-app/ui';
import { DraggableAttributes } from '@dnd-kit/core';
import { ComponentProps, forwardRef } from 'react';

import { routing, PageId } from 'src/constants/routing.constant';
import { useReleaseNoteBase, useReleaseNotesContext } from 'src/contexts/releaseNotesContext';
import { useNavigate, useRouteMatch } from 'src/hooks';
import { resetReleasesAction, useGetReleasesAction } from 'src/reactives/releases.reactive';

import { ReleaseDocInsights } from './ReleaseDocInsights';
import { ReleaseNoteActionsMenu } from './ReleaseNoteActionMenu';
import {
  CardNew, Title, ToggleNoteEditor, Content, StyledStatusTag, StyledDocumentIcon,
} from './ReleaseNoteCard.styles';
import { ReleaseNoteTitleForm } from './ReleaseNoteTitleForm';

type Props = {
  noteId: string;
  isOverlay?: boolean;
  isPlaceholder?: boolean;
  handleDragProps?: DraggableAttributes;
};

export const ReleaseNoteCard = forwardRef<HTMLDivElement, ComponentProps<typeof CardNew> & Props>(({
  noteId,
  isOverlay,
  isPlaceholder,
  handleDragProps,
  ...props
}, ref) => {
  const matchReleaseNote = useRouteMatch(routing[PageId.ReleaseNote]);
  const isActive = matchReleaseNote?.params.noteId === noteId;
  const content = !isPlaceholder && (
    <ReleaseNoteCardContent
      noteId={noteId}
      isOverlay={isOverlay}
      isActive={isActive}
      handleDragProps={handleDragProps}
    />
  );
  return (
    <CardNew
      ref={ref}
      {...props}
      $isActive={isActive}
      $isPlaceholder={isPlaceholder}
      $isOverlay={isOverlay}
    >
      {content}
    </CardNew>
  );
});

export const ReleaseNoteCardContent = ({
  noteId, isOverlay, isActive, handleDragProps,
}: {
  noteId: string;
  isActive: boolean;
  isOverlay?: boolean;
  handleDragProps?: DraggableAttributes;
}) => {
  const action = useGetReleasesAction();
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);
  const isReadonly = useReleaseNotesContext(ctx => ctx.isReadonly);
  const isNoValue = useReleaseNotesContext(ctx => ctx.isNoValue);
  const note = useReleaseNoteBase(noteId);
  const docStatus = note?.doc?.status;
  const {
    navigateToRelease, navigateToReleaseNote,
  } = useNavigate();

  if (!note) return null;

  const isRenaming = action.type === 'updateReleaseNote' && action.id === noteId;

  return (
    <>
      {!isOverlay && !isRenaming && (
        <ToggleNoteEditor
          onClick={() => {
            resetReleasesAction();
            return isActive ? navigateToRelease(releaseId) : navigateToReleaseNote(releaseId, noteId);
          }}
          {...handleDragProps}
        />
      )}

      <Content>
        {!isOverlay && isRenaming
          ? <ReleaseNoteTitleForm noteId={noteId} />
          : (
            <Flex $grow={1} $gap={8} $align="center" style={{ overflow: 'hidden' }}>
              {!isNoValue && docStatus && (
                <StyledStatusTag
                  hideLabel
                  isReadOnly
                  value={docStatus.value}
                  category={docStatus.category}
                />
              )}
              {!isNoValue && <StyledDocumentIcon size={16} />}
              <Title>{note.title}</Title>
              {!isNoValue && <ReleaseDocInsights noteId={noteId} />}
            </Flex>
          )}

        {!isOverlay && !isRenaming && (
          <ReleaseNoteActionsMenu noteId={noteId} disabled={isReadonly} />
        )}
      </Content>
    </>
  );
};
