import { DocAttribute, DoctypeFragment, DocBaseFragment, DocDocSourceFragment, AiState } from '@cycle-app/graphql-codegen';
import { Badge, InsightCard, Flex } from '@cycle-app/ui';
import { AddIcon, BulbIcon, QuoteIcon } from '@cycle-app/ui/icons';
import { getHighlightHash, nodeToArray } from '@cycle-app/utilities';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isPresent } from 'ts-is-present';

import { AiStateTag } from 'src/components/AiStateTag';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { DocSource } from 'src/components/DocSource';
import { DocStatus } from 'src/components/DocStatus';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { InsightCardOptions } from 'src/components/InsightCardOptions';
import { PropertiesContainer, ParentContainer, CustomerContainer } from 'src/components/InsightsList/InsightsList.styles';
import LoadMore from 'src/components/LoadMore/LoadMore';
import { useDocInsights, useLocation } from 'src/hooks';
import { useDocChildren } from 'src/hooks/api/useDoc';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { useDocUrl } from 'src/hooks/useUrl';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { Location } from 'src/types/routes.types';
import { getDocFromCache } from 'src/utils/cache.utils';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { insightName } from 'src/utils/doc.util';
import { SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

import { DocHierarchyInsightsSkeletons } from '../DocHierarchyItem/DocHierarchyInsightsSkeletons';
import { Items } from './DocHierarchyGroup.styles';
import { DoctypeInfo, DoctypeLabel, AddButton, OptionsContainer, StyledAiGenetaredInsights } from './DocHierarchyGroupInsight.styles';
import { OpenInsightCreateModal } from './OpenInsightCreateModal';

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

export type DocHierarchyGroupInsightProps = {
  parentId?: string;
  doctypeChildren: DoctypeFragment;
  onClickItem?: VoidFunction;
  isFeedback?: boolean;
  count: number;
  isReadOnly?: boolean;
};

export const DocHierarchyGroupInsight = ({
  parentId,
  doctypeChildren,
  onClickItem,
  isFeedback,
  count,
  isReadOnly = false,
}: DocHierarchyGroupInsightProps) => {
  const childrenQuery = useDocChildren({
    doctypeId: doctypeChildren.id,
    docId: parentId,
  }, {
    skip: isFeedback,
  });
  const {
    getDocItemUrlFromView, getDocPanelUrl,
  } = useDocUrl();
  const insightsQuery = useDocInsights(parentId, {
    skip: !isFeedback,
    aiStates: [AiState.UserValidated, null],
  });

  const isLoading = insightsQuery.isLoading || childrenQuery.loading || childrenQuery.isPaginationLoading;

  const docs = isFeedback
    ? insightsQuery.insights.map(i => i?.doc).filter(isPresent)
    : childrenQuery.docs;

  const parentDoc = parentId && getDocFromCache(parentId);

  const location = useLocation();
  const fromStarredBoard = location.state?.fromStarredBoard;

  const getDocUrl = useCallback((doc: DocBaseFragment) => {
    // doc.docSource.doc is the feedback.
    if (doc.docSource?.doc && doc.doctype) {
      const pathname = getDocItemUrlFromView({
        doc,
        docDocSource: doc.docSource.doc,
        doctype: doc.doctype,
      });
      if (!pathname) return undefined;
      // Insight as children -> item opens feedback with focus on the insight.
      return {
        pathname,
        hash: getHighlightHash({
          docId: doc.id,
          blockId: doc.docSource.blockId,
        }),
        search: '',
        state: {
          isDocRelative: true,
          fromStarredBoard,
        },
      };
    }
    const pathname = getDocPanelUrl(doc);
    if (!pathname) return undefined;
    return {
      pathname,
      hash: '',
      search: '',
      state: {
        fromStarredBoard,
      },
    };
  }, [fromStarredBoard, getDocItemUrlFromView, getDocPanelUrl]);

  const childrenLoading = !childrenQuery.isPaginationLoading && childrenQuery.pageInfo?.hasNextPage;

  return (
    <>
      <Flex
        key={doctypeChildren.id}
        $column
        $align="stretch"
        $gap={16}
      >
        <DoctypeInfo>
          <DoctypeLabel>
            {quotesEnabled ? <QuoteIcon /> : <BulbIcon />}
            {insightName({
              plural: true,
              uppercase: true,
            })}
            <Badge>
              <span>{count}</span>
            </Badge>
          </DoctypeLabel>

          {parentDoc && !isReadOnly && (
            <OpenInsightCreateModal
              layer={Layer.DropdownModalZ2}
              parentDoc={parentDoc}
              button={props => (
                <AddButton
                  onClick={e => {
                    e.stopPropagation();
                    requestInsightCreate(props?.onClick);
                  }}
                  forceFocus={props.isOpen}
                >
                  <AddIcon size={12} />
                  {`Add ${insightName()}`}
                </AddButton>
              )}
            />
          )}
        </DoctypeInfo>
        {(docs.length || isLoading || childrenLoading) &&
        (
          <Items>
            {docs.map(doc => (
              <Item
                key={doc.id}
                doc={doc}
                getDocUrl={getDocUrl}
                onClickItem={onClickItem}
                isFeedback={isFeedback}
              />
            ))}

            {isLoading && <DocHierarchyInsightsSkeletons count={Math.min(count, 3)} />}

            {childrenLoading && (
              <LoadMore
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await childrenQuery.loadMore(childrenQuery.pageInfo?.endCursor ?? '');
                }}
                count={Math.min((count - docs.length), SIZE_ITEMS_HIERARCHY)}
              />
            )}
          </Items>
        )}

        {!quotesEnabled && isFeedback && parentDoc && <StyledAiGenetaredInsights doc={parentDoc} />}
      </Flex>
    </>
  );
};

type ItemProps = {
  doc: DocBaseFragment & DocDocSourceFragment;
  getDocUrl: (doc: DocBaseFragment) => Location | undefined;
  onClickItem: DocHierarchyGroupInsightProps['onClickItem'];
  isFeedback: DocHierarchyGroupInsightProps['isFeedback'];
  canUpdateQuote?: boolean;
};

const Item = ({
  doc,
  onClickItem,
  isFeedback,
  getDocUrl,
  // TODO: fix the update issue with feedback doc id from hierarchy view
  canUpdateQuote = false,
}: ItemProps) => {
  const history = useHistory();
  const {
    contextUpdateEl,
    isClickOnEditForm,
    contextEl,
    startEdit,
  } = useInsightCardEditQuoteProps({
    docTarget: doc.docSource,
  });

  const docType = useGetDocType(doc.doctype.id);

  const rightSlot = (
    <>
      <AiStateTag
        docId={doc.id}
        docTypeId={doc.doctype.id}
        aiState={doc.aiState}
      />
      {doc._docKey && (
        <OptionsContainer>
          <InsightCardOptions
            layer={Layer.DropdownModalZ3}
            blockId={doc.docSource?.blockId}
            doc={{
              ...doc,
              _docKey: doc._docKey,
            }}
            editParent={!doc.parent}
            onUpdateQuoteSelect={doc?.docSource?.doc?.id && canUpdateQuote ? startEdit : undefined}
          />
        </OptionsContainer>
      )}
    </>
  );

  const showParentSlot = isFeedback && !!doc.parent;
  const showProperties = !!nodeToArray(doc.attributes).length || (isFeedback && !doc.parent);

  return (
    <InsightCard
      key={doc.id}
      blockId={doc.docSource?.blockId}
      context={contextEl}
      contextText={doc.docSource?.content ?? ''}
      onContextCopied={text => copyToClipboard({
        text,
        notification: 'Text copied to clipboard!',
      })}
      onClick={(e) => {
        if (isClickOnEditForm(e)) return;
        onClickItem?.();
        const url = getDocUrl(doc);
        if (url) history.push(url);
      }}
      properties={(showProperties || !showParentSlot) && (
        <Flex $gap={8} $justify="space-between" style={{ width: '100%' }}>
          {showProperties && (
            <PropertiesContainer>
              {!doc.parent && !!doc.status?.id && (
                <DocStatus
                  docTypeId={doc.doctype.id}
                  statusId={doc.status.id}
                  isDisabled
                  hideLabel
                />
              )}
              <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />
            </PropertiesContainer>
          )}
          <Flex $gap={4}>
            {!showParentSlot && rightSlot}
          </Flex>
        </Flex>
      )}
      title={doc.title}
      footerRight={!isFeedback && (
        <>
          <DocSource doctypeId={doc.doctype.id} docId={doc.id} source={doc.source} />
          {!!doc.assignee?.id && docType && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isDisabled={!!doc.docSource}
              isRemovable={false}
              layer={Layer.DropdownModalZ1}
              dropdownPlacement="bottom-end"
              docId={doc.id}
              docTypeName={docType.name}
              docTypeType={docType.type}
            />
          )}
        </>
      )}
      parentSlot={showParentSlot && (
        <ParentContainer>
          {!!doc.status?.id && (
            <DocStatus
              docTypeId={doc.doctype.id}
              statusId={doc.status.id}
              isDisabled
              hideLabel
            />
          )}
          <DocParentDropdown
            showParentTitle
            docId={doc.id}
            docTypeId={doc.doctype.id}
            minimal
            placeholder="Add"
            context="doc-item"
            layer={Layer.DropdownModalZ3}
            onParentClick={onClickItem}
          />
          {rightSlot}
        </ParentContainer>
      )}
      contextFooter={!isFeedback && !!doc.customer && (
        <CustomerContainer>
          <DocCompanyCustomer
            doc={doc}
            isDisabled
            onProfileOpen={e => {
              e.stopPropagation();
              onClickItem?.();
            }}
            showCompanyName
          />
        </CustomerContainer>
      )}
      contextUpdate={canUpdateQuote ? contextUpdateEl : undefined}
    />
  );
};
