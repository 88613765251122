import { Flex } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';
import { useId, useState } from 'react';

import { navigate, useLocation } from 'src/hooks';
import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';

import { PageId } from '../../constants/routing.constant';
import { ChangelogBuilderPreset } from './ChangelogBuilderPreset';
import {
  Container, Section, StyledButtonBack, StyledInput, Tab, Tabs, Top,
} from './ChangelogBuilderSidebar.styles';
import { ChangelogBuilderSidebarAdvanced } from './ChangelogBuilderSidebarAdvanced';
import { ChangelogBuilderSocialLink } from './ChangelogBuilderSocialLink';
import { ChangelogBuilderTags } from './ChangelogBuilderTags';
import { LabelWithReset } from './LabelWithReset';

export const ChangelogBuilderSidebar = () => {
  const { navigate: navigateToSettings } = useNavigateToSettings();
  const previousPageId = useLocation().state?.changelogBuilder?.previousPageId;
  const [tab, setTab] = useState<'content' | 'design'>('content');
  return (
    <Container>
      <Top style={{ marginBottom: '24px' }}>
        <StyledButtonBack
          iconStart={<RightIcon size={21} />}
          onClick={() => {
            if (previousPageId === PageId.SettingsReleases) {
              navigateToSettings(previousPageId);
              return;
            }
            navigate(PageId.Releases);
          }}
          size="L"
          variant="light"
        >
          {previousPageId === PageId.SettingsReleases ? 'Settings' : 'Releases'}
        </StyledButtonBack>
      </Top>
      <Tabs>
        <Tab onClick={() => setTab('content')} $isActive={tab === 'content'} variant="nospace">Content</Tab>
        <Tab onClick={() => setTab('design')} $isActive={tab === 'design'} variant="nospace">Design</Tab>
      </Tabs>
      {tab === 'content' && (
        <div>
          <Titles />
          <ChangelogBuilderTags />
          <ChangelogBuilderSocialLink />
        </div>
      )}
      {tab === 'design' && (
        <div>
          <ChangelogBuilderPreset />
          <ChangelogBuilderSidebarAdvanced />
        </div>
      )}
    </Container>
  );
};

const Titles = () => {
  const {
    register, watch, formState, resetField,
  } = useChangelogBuilderFormContext();
  const id = useId();
  const title = watch('title');
  const subtitle = watch('subtitle');
  return (
    <Section>
      <Flex $column $align="stretch" $gap={20}>
        <div>
          <LabelWithReset
            id={`${id}-title`}
            label="Title"
            reset={() => resetField('title')}
            showReset={title !== formState.defaultValues?.title}
          />
          <StyledInput
            error={formState.errors.title?.message}
            id={`${id}-title`}
            {...register('title', { validate: (value) => (!value.trim().length ? 'Title is required' : undefined) })}
          />
        </div>
        <div>
          <LabelWithReset
            id={`${id}-subtitle`}
            label="Subtitle"
            reset={() => resetField('subtitle')}
            showReset={subtitle !== formState.defaultValues?.subtitle}
          />
          <StyledInput
            error={formState.errors.subtitle?.message}
            id={`${id}-subtitle`}
            {...register('subtitle', { validate: (value) => (!value.trim().length ? 'Subtitle is required' : undefined) })}
          />
        </div>
      </Flex>
    </Section>
  );
};
