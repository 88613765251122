import { Button, Shortcuts } from '@cycle-app/ui';
import { CloseIcon, DownIcon } from '@cycle-app/ui/icons';
import { useReducer, useCallback, useRef, useState } from 'react';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import DocEditor from 'src/components/DocEditor/DocEditor';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useRemoveDoc, useUpdateDocTitle } from 'src/hooks/api/mutations/updateDocHooks';
import { usePublishDoc } from 'src/hooks/api/mutations/usePublishDoc';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { getDocImport, setDocImport, useGetDocImport } from 'src/reactives/docImport.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';
import { ShortcutCreateModal } from 'src/types/shortcuts.types';

import {
  Container,
  PortalModalStyled,
  Actions,
  BigActionButton,
  DocTop,
  Content,
  Toolbar,
  ContentEditableStyled,
  Attributes,
  Buttons,
  DocContainer,
  ScrollableContent,
  SkeletonCustomer,
  SkeletonAvatar,
  SkeletonUsername,
  CustomerSelect,
  CustomerButton,
  Placeholder,
  StyledErrorPage,
  CustomerContainer,
} from './CreateDocModal.styles';
import { ToggleAutopilot } from './ToggleAutopilot';
import { useWithAutopilot } from './useWithAutopilot';

/**
 * This is a forked version of the CreateDocModal component
 * It is used to publish a draft feedback doc created with the dropzone
 * The title is initially the file name and is only updated on publish
 * So if the user closes the modal without publishing, the file name is still displayed in the dropzone
 */
export const CreateDocFromFileModal = () => {
  const { draftIdOpen } = useGetDocImport();
  if (!draftIdOpen) return null;
  return (
    <BoardConfigContextProvider>
      <ModalContent docId={draftIdOpen} />
    </BoardConfigContextProvider>
  );
};

const ModalContent = ({ docId }: { docId: string }) => {
  const [isCustomerSelected, setCustomerSelected] = useState(false);

  const [isModalReady, setModalReady] = useReducer(() => true, false);

  // Title is updated on doc publish
  const [updatedTitle, setUpdatedTitle] = useState<string | null>(null);

  const {
    updateDocTitle, loading: isUpdatingTitle,
  } = useUpdateDocTitle();

  const { doc } = useDocPanelProps(true, docId);

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    publishDoc, isPublishingDoc,
  } = usePublishDoc();

  const isMobile = useIsMobile();

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror');
    if (!(editor instanceof HTMLElement)) return;
    editor?.focus();
  }, [containerRef]);

  const isPublishDisabled = !isCustomerSelected;

  const [editorText, setEditorText] = useState('');

  const createDoc = async (openDoc?: boolean) => {
    getDocImport().onPublish?.();
    if (updatedTitle) {
      await updateDocTitle({
        docId,
        title: updatedTitle,
      });
    }
    return publishDoc(doc, {
      openDoc,
      withAutopilot: withAutopilot && editorText.length > 0,
    });
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutCreateModal.Save]: () => createDoc(),
      [ShortcutCreateModal.SaveAndOpen]: () => createDoc(true),
    },
    shortcuts: Object.values(ShortcutCreateModal),
    enabled: () => !isPublishDisabled || !doc,
  });

  const inputTitleRef = useRef<HTMLDivElement | null>(null);

  const hideModal = () => setDocImport({ draftIdOpen: null });

  const {
    withAutopilot, setWithAutopilot, isAutopilotEnabled,
  } = useWithAutopilot();

  const { removeDoc } = useRemoveDoc();

  const buttons = (
    <Buttons
      $isDisabled={!isCustomerSelected}
    >
      <ToggleAutopilot
        checked={withAutopilot}
        onChange={e => setWithAutopilot(e.target.checked)}
        disabled={!isAutopilotEnabled}
        onTooltipClick={hideModal}
        style={{ marginRight: 16 }}
      />

      <Button
        variant="secondary"
        size="M"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          removeDoc(docId);
          hideModal();
        }}
      >
        Discard
      </Button>

      <Button
        isLoading={isPublishingDoc || isUpdatingTitle}
        onClick={() => createDoc()}
        size="M"
        tooltipPlacement="top"
        tooltip={(
          <Shortcuts
            shortcuts={[
              {
                label: 'Save',
                keys: shortcuts[ShortcutCreateModal.Save],
              },
              {
                label: 'Save & Open',
                keys: shortcuts[ShortcutCreateModal.SaveAndOpen],
              },
            ]}
          />
        )}
        disabled={isPublishDisabled}
      >
        Create feedback
      </Button>
    </Buttons>
  );

  return (
    <PortalModalStyled
      hide={hideModal}
      motionVariants={variantsContent}
      onAnimationComplete={setModalReady}
      layer={Layer.DocPanel}
    >
      <Container ref={containerRef}>
        <ErrorBoundary fallback={<StyledErrorPage />}>
          {isMobile ? buttons : (
            <Actions>
              <BigActionButton
                onClick={hideModal}
                tooltipPlacement="top"
                tooltip={(
                  <Shortcuts
                    shortcuts={[{
                      label: 'Cancel',
                      keys: ['esc'],
                    }]}
                  />
                )}
              >
                <CloseIcon />
              </BigActionButton>
            </Actions>
          )}

          <ScrollableContent>
            <Content>
              <DocTop className="doc-top">
                <Toolbar>
                  {!isCustomerSelected && (
                    <CustomerSelect>
                      {doc ? (
                        <DocCustomer
                          doc={doc}
                          openDropdownTimeout={150}
                          isRemovable={false}
                          showWarnings={false}
                          onDocUpdated={() => {
                            setCustomerSelected(true);
                          }}
                        >
                          <Placeholder>
                            Select customer…
                            <DownIcon size={14} />
                          </Placeholder>
                        </DocCustomer>
                      ) : (
                        <CustomerButton disabled isLoading />
                      )}
                    </CustomerSelect>
                  )}

                  {isCustomerSelected && (
                    <>
                      {doc?.doctype.customer ? (
                        <CustomerContainer>
                          <DocCompanyCustomer
                            doc={doc}
                            canRemoveCustomer={false}
                            showArrow
                          />
                        </CustomerContainer>
                      ) : (
                        <SkeletonCustomer>
                          <SkeletonAvatar />
                          <SkeletonUsername />
                        </SkeletonCustomer>
                      )}
                    </>
                  )}
                </Toolbar>

                {doc && (
                  <ContentEditableStyled
                    ref={inputTitleRef}
                    placeholder="Untitled"
                    onChange={title => setUpdatedTitle(title)}
                    onNext={() => containerRef.current?.click()}
                    initialValue={doc.title}
                    onEnter={focusToContent}
                    isDisabled={!doc || !isCustomerSelected}
                  />
                )}

                <Attributes $isDisabled={!isCustomerSelected}>
                  {doc && (
                    <DocPanelDocAttributes
                      doc={doc}
                      layer={Layer.DropdownModal}
                      showDoctype={false}
                      showAssignee
                    />
                  )}
                </Attributes>
              </DocTop>
            </Content>
            <DocContainer $isDisabled={!isCustomerSelected}>
              {isModalReady && (
                <DocEditor
                  defaultDoctypeId={doc?.doctype.id}
                  docId={doc?.id}
                  parentRef={containerRef}
                  displayLoader={isCustomerSelected}
                  isChildrenCreationEnabled={false}
                  applyTemplateOnDoctypeUpdate
                  hideHierarchy
                  hideEmptyBlock
                  disabledShortcuts={['Mod-Enter', 'Shift-Enter']}
                  disabledActions={[ActionId.TurnTextIntoInsight]}
                  onUpdate={props => setEditorText(props.text.trim())}
                />
              )}
            </DocContainer>
          </ScrollableContent>
          {!isMobile && buttons}
        </ErrorBoundary>
      </Container>
    </PortalModalStyled>
  );
};
