import { Rect } from '@cycle-app/utilities';
import ActiveTippy, { TippyProps } from '@tippyjs/react/headless';
import {
  AnimatePresence, motion, MotionProps, Variants, useReducedMotion, AnimationLifecycles,
} from 'framer-motion';
import {
  useEffect,
  CSSProperties, ReactNode, useState, isValidElement, forwardRef, PropsWithChildren, useRef, useImperativeHandle,
} from 'react';
import { Placement, GetReferenceClientRect, Instance } from 'tippy.js';

import { LazyTippy } from '../LazyTippy';
import {
  motionProps as defaultMotionProps,
  variantsContent as defaultMotionVariants,
} from './Popover.motion';

export interface Props {
  controlled?: boolean;
  content: ReactNode | TippyProps['render'];
  disabled?: boolean;
  placement?: Placement;
  popperOptions?: TippyProps['popperOptions'];
  animation?: boolean;
  interactive?: boolean;
  interactiveBorder?: number;
  withPortal?: boolean;
  withWrapper?: boolean;
  onMount?: TippyProps['onMount'];
  onHide?: () => void;
  onClickOutside?: TippyProps['onClickOutside'];
  visible?: boolean;
  hide?: () => void;
  offset?: [number, number];
  overridePosition?: Rect;
  enableOnMobile?: boolean;
  zIndex?: number;
  motionProps?: MotionProps;
  motionVariants?: Variants;
  style?: CSSProperties;
  appendTo?: TippyProps['appendTo'];
  getReferenceClientRect?: TippyProps['getReferenceClientRect'];
  onAnimationComplete?: AnimationLifecycles['onAnimationComplete'];
  exitAnimation?: boolean;
  delay?: TippyProps['delay'];
  lazy?: boolean;
  isHideDisabled?: () => boolean;
  isShowDisabled?: () => boolean;
}

export const Popover = forwardRef<Instance, PropsWithChildren<Props>>(({
  interactive,
  interactiveBorder,
  controlled = false,
  content,
  children,
  placement = 'bottom-end',
  popperOptions,
  disabled = false,
  onMount: onMountProps,
  onHide: onHideProps,
  animation = false,
  enableOnMobile = true,
  withPortal = false,
  withWrapper = true,
  visible,
  hide,
  offset = [0, 8],
  overridePosition,
  onClickOutside,
  zIndex,
  motionProps,
  motionVariants,
  style,
  appendTo,
  getReferenceClientRect,
  onAnimationComplete,
  exitAnimation = true,
  delay,
  lazy = true,
  isHideDisabled,
  isShowDisabled,
}, ref) => {
  const popperInstance = useRef<Instance | null>(null);
  const [renderContent, setRenderContent] = useState(false);
  // Will be used if !withWrapper (+ add a fallback to render a valid element).
  const validChildren = isValidElement(children)
    ? children
    : <div data-popover style={style}>{children}</div>;

  // ref depends on the renderContent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useImperativeHandle(ref, () => popperInstance.current!, [renderContent]);

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        e.stopImmediatePropagation();
        hide?.();
      }
    };
    if (renderContent && controlled) {
      document.addEventListener('keyup', onKeyUp);
    }
    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [renderContent, controlled, hide]);

  const reducedMotion = useReducedMotion();

  const Tippy = lazy ? LazyTippy : ActiveTippy;

  return (
    <Tippy
      zIndex={zIndex}
      onMount={onMount}
      onHide={onHide}
      onShow={onShow}
      disabled={disabled}
      visible={visible}
      placement={placement}
      popperOptions={popperOptions}
      animation={animation}
      interactive={interactive}
      interactiveBorder={interactiveBorder}
      {...controlled && (onClickOutside || hide) && {
        onClickOutside: onClickOutside ?? hide,
      }}
      appendTo={appendTo ?? (controlled || withPortal ? document.body : 'parent')}
      offset={offset}
      touch={enableOnMobile}
      getReferenceClientRect={getReferenceClientRect ?? (overridePosition ? (() => overridePosition) as GetReferenceClientRect : null)}
      delay={delay}
      render={(attrs) => {
        if (!animation) return <>{content}</>;

        let variantDelay = controlled ? 0 : 0.1;

        if (typeof delay === 'number') {
          variantDelay = delay / 1000;
        } else if (Array.isArray(delay) && delay[0]) {
          variantDelay = delay[0] / 1000;
        }

        return (
          <AnimatePresence>
            {renderContent ? (
              <motion.div
                onAnimationComplete={onAnimationComplete}
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
                tabIndex={-1}
                variants={motionVariants ?? defaultMotionVariants({
                  placement,
                  delay: variantDelay,
                  reducedMotion,
                  exitDuration: exitAnimation ? 0.15 : 0,
                })}
                {...motionProps ?? defaultMotionProps}
                {...attrs}
              >
                {typeof content === 'function' ? content(attrs) : content}
              </motion.div>
            ) : null}
          </AnimatePresence>
        );
      }}
    >
      {withWrapper ? (
        <div data-popover style={style}>
          {children}
        </div>
      ) : validChildren}
    </Tippy>
  );

  function onMount(instance: Instance): void {
    popperInstance.current = instance;
    setRenderContent(true);
    onMountProps?.(instance);
  }

  function onHide(): void | false {
    if (isHideDisabled?.()) return false;
    onHideProps?.();
    setRenderContent(false);
    return undefined;
  }

  function onShow(): void | false {
    if (isShowDisabled?.()) return false;
    return undefined;
  }
});
