import { PenIcon, AddIcon } from '@cycle-app/ui/icons';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { requestInsightUpdate } from 'src/utils/requestInsightUpdate';

import { ButtonItem } from './QuotePopover.styles';

type Props = Omit<ToggleDropdownProps, 'content' | 'button'> & {
  insightId: string;
};

export const ChangeQuoteFeature = ({
  insightId, ...props
}: Props) => {
  const insight = useDocPanelContext(ctx => ctx.doc?.docTargets.edges.find(edge => edge.node?.id === insightId)?.node);
  const hasParent = !!insight?.doc?.parent;
  return (
    <ToggleDropdown
      withPortal={false}
      withWrapper={false}
      controlled={false}
      animation={false}
      content={contentProps => (
        <Content
          hide={contentProps.hide}
          insightId={insightId}
        />
      )}
      button={buttonProps => (
        <ButtonItem
          active={buttonProps['data-active']}
          onClick={() => {
            if (!requestInsightUpdate()) return;
            buttonProps.toggle();
          }}
        >
          {hasParent ? <PenIcon size={14} /> : <AddIcon size={12} />}
          {hasParent ? 'Change feature' : 'Link feature'}
        </ButtonItem>
      )}
      {...props}
    />
  );
};

type ContentProps = {
  hide: VoidFunction;
  insightId: string;
};

const Content = ({
  hide, insightId,
}: ContentProps) => {
  const insight = useDocPanelContext(ctx => ctx.doc?.docTargets.edges.find(edge => edge.node?.id === insightId)?.node);
  const docType = getDocType(insight?.doc?.doctype.id);
  if (!docType || !insight?.doc) return null;
  return (
    <DocParentPanel
      docId={insight.doc.id}
      docType={docType}
      showNoneOption={!!insight.doc?.parent}
      hide={hide}
      onChange={hide}
    />
  );
};
