import { Color, Feature, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { Flex, Button, Tag, InfiniteScroll, SelectOption } from '@cycle-app/ui';
import {
  ReleaseIcon, CloseIcon, CursorIcon, ReleaseNoteIcon, CalendarEditIcon, TrashIcon, LinkArrowIcon,
} from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';

import releasesPreview from 'src/assets/release-preview.png';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { FeatureEmptyState } from 'src/components/FeatureEmptyState';
import { FeedbackLifecycle } from 'src/components/FeedbackLifecycle';
import { ReleaseEditModal, ReleaseRemoveModal } from 'src/components/ReleaseModals';
import { ReleasesEmpty } from 'src/components/ReleasesEmpty/';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { HELP_URL_RELEASES } from 'src/constants/help.constants';
import { PageId } from 'src/constants/routing.constant';
import { ReleasesProvider, useReleasesContext } from 'src/contexts/releasesContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useOptimizedBooleanState, useProductBase, useFeatureFlagUpdate, useFeatureFlagReload, navigate } from 'src/hooks';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { openRemoveRelease, openUpdateRelease } from 'src/reactives/releases.reactive';
import { changelogUrl } from 'src/utils/changelog.utils';

import { Page } from '../SettingsWorkflows/SettingsWorkflows.styles';
import { SettingsChangelog } from './SettingsChangelog';
import {
  Title, Description, Date,
  ReleasesListContainer,
  ReleaseItems, ReleaseItem,
  CloseButton,
  Modal, ModalTitle, ModalItem,
  Header,
} from './SettingsReleases.styles';

export const SettingsReleases = () => {
  const [isModalOpen, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);
  const isReleasesEnabled = useIsReleasesEnabled();
  const product = useProductBase();
  if (!product) return null;
  return (
    <Page>
      <Header>
        <SettingsViewHeader
          title="Releases"
          description="Communicate your releases"
          helpUrl={HELP_URL_RELEASES}
        />

        <FeedbackLifecycle step="release" />
      </Header>

      {isReleasesEnabled
        ? (
          <>
            <SettingsChangelog />
            <ReleasesProvider productId={product.id}>
              <ReleasesList />
            </ReleasesProvider>
          </>
        )
        : (
          <>
            <FeatureEmptyState
              onClick={setTrueCallback}
              src={releasesPreview}
              title="Releases"
            // eslint-disable-next-line max-len
              description="Enable Releases to manage your and communicate your releases, write your release notes, and publish a beautiful changelog."
            />
            <EnableReleaseModal isOpen={isModalOpen} hide={setFalseCallback} />
          </>
        )}
    </Page>
  );
};

const ReleasesList = () => {
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  const releasesList = useReleasesContext(ctx => ctx.releasesList);
  const hasNextPage = useReleasesContext(ctx => ctx.hasNextPage);
  const loadMore = useReleasesContext(ctx => ctx.loadMore);
  const isLoadingMore = useReleasesContext(ctx => ctx.isLoadingMore);
  const hasReleases = !!releasesCount;
  return (
    <>
      <ReleasesListContainer>
        {hasReleases && (
          <Flex $justify="space-between">
            <Title>Releases</Title>
          </Flex>
        )}
        <InfiniteScroll
          hasMoreData={hasNextPage}
          loadMore={loadMore}
          isLoading={isLoadingMore}
        >
          <ReleaseItems $hasMinDimensions={!hasReleases}>
            {hasReleases ? releasesList.filter(release => !!release.date).map(release => (
              <ReleaseItem key={release.id}>
                <Flex $gap={8} $align="flex-start">
                  <Date>
                    {toShortLocaleDateString(release.date)}
                  </Date>
                  {!!release.title && (
                    <>
                      <Description>·</Description>
                      <Description>{release.title}</Description>
                    </>
                  )}
                </Flex>
                <Flex $gap={8}>
                  {release.publicStatus === ReleasePublicStatus.Published && (
                    <Tag color={Color.C}>
                      Published
                    </Tag>
                  )}
                  <ReleaseItemAction
                    releaseId={release.id}
                    isReleasePublished={release.publicStatus === ReleasePublicStatus.Published}
                    publicId={release.publicRelease?.id || null}
                  />
                </Flex>
              </ReleaseItem>
            )) : <ReleasesEmpty fromSettings />}
          </ReleaseItems>
        </InfiniteScroll>
      </ReleasesListContainer>

      <ReleaseEditModal />
    </>
  );
};

const ReleaseItemAction = ({
  isReleasePublished, releaseId, publicId,
}: { isReleasePublished: boolean; releaseId: string; publicId: string | null }) => {
  const { changelog } = useChangelog();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const url = `${changelogUrl(productSlug, changelog?.domain)}/${publicId ? `release-${publicId}` : ''}`;
  const options: SelectOption[] = [
    {
      value: 'go',
      label: 'Go to release',
      icon: <ReleaseIcon />,
      onSelect: () => navigate(PageId.Release, { releaseId }),
    },
    {
      value: 'open',
      label: 'Open in Changelog',
      icon: <LinkArrowIcon size={16} />,
      ...(!isReleasePublished || !changelog?.isPublished) && {
        disabled: true,
        tooltipContent: 'Publish the changelog and the release first',
      },
      onSelect: () => {
        window.open(url, '_blank');
      },
    },
    {
      value: 'edit',
      label: 'Edit release info',
      icon: <CalendarEditIcon width={14} style={{ marginLeft: '2px' }} />,
      onSelect: openUpdateRelease(releaseId),
      ...isReleasePublished && {
        disabled: true,
        tooltipContent: 'Make updates or unpublish first',
      },
    },
    {
      value: 'delete',
      label: 'Delete',
      icon: <TrashIcon />,
      onSelect: openRemoveRelease(releaseId),
      ...isReleasePublished && {
        disabled: true,
        tooltipContent: 'Unpublish first',
      },
    },
  ];
  return (
    <>
      <DotsMenuLayer
        placement="right-start"
        options={options}
      />
      <ReleaseRemoveModal releaseId={releaseId} />
    </>
  );
};

type EnableReleaseModalProps = {
  isOpen: boolean;
  hide: VoidFunction;
};

const EnableReleaseModal = ({
  isOpen, hide,
}:EnableReleaseModalProps) => {
  const {
    activateFeature, loading,
  } = useFeatureFlagUpdate();
  const reloadFlags = useFeatureFlagReload();
  if (!isOpen) return null;
  return (
    <Modal hide={hide}>
      <CloseButton onClick={hide}>
        <CloseIcon />
      </CloseButton>
      <ModalTitle>
        Releases
      </ModalTitle>
      <ModalItem>
        <div>
          <ReleaseIcon />
        </div>
        <div>
          <h5>Manage your releases</h5>
          <p>Organize your features into releases to communicate progress</p>
        </div>
      </ModalItem>
      <ModalItem>
        <div>
          <ReleaseNoteIcon />
        </div>
        <div>
          <h5>Write your release notes</h5>
          <p>For each new feature, write a release note that explains its value.</p>
        </div>
      </ModalItem>
      <ModalItem>
        <div>
          <CursorIcon />
        </div>
        <div>
          <h5>Publish your changelog</h5>
          <p>Publish a beautiful changelog to show off your shipping velocity.</p>
        </div>
      </ModalItem>
      <Button
        size="L"
        style={{
          width: '100%',
          marginTop: '20px',
          maxHeight: '32px',
        }}
        onClick={async () => {
          const featureFlagUpdated = await activateFeature(Feature.Release);
          if (featureFlagUpdated) {
            reloadFlags();
            navigate(PageId.Releases);
          }
        }}
        isLoading={loading}
      >
        Enable
      </Button>
    </Modal>
  );
};
