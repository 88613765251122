import { z } from 'zod';

/**
 * Get an item from localStorage and validate with Zod schema
 * Return a default value on error
 *
 * @example
 * const count = getLocalStorageItem({
 *   key: LocalKey.Count,
 *   schema: z.number().int(),
 *   defaultValue: 0,
 * });
 */
export const getLocalStorageItem = <T>({
  key, schema, defaultValue,
}: {
  key: string;
  schema: z.Schema<T>;
  defaultValue: T;
}): T => {
  const item = localStorage.getItem(key);
  if (!item) return defaultValue;
  try {
    return schema.parse(JSON.parse(item));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to parse item from localStorage with key: ${key}`, error);
    return defaultValue;
  }
};

/**
 * Subscribe to changes in localStorage and validate with Zod schema
 *
 * @example
 * const unsubscribe = subscribeLocalStorage({
 *   key: LocalKey.Count,
 *   schema: z.number().int(),
 *   callback: console.log,
 * });
 * unsubscribe();
 */
export const subscribeToLocalStorage = <T>({
  key, callback, schema,
}: {
  key: string;
  callback: (value: T) => void;
  schema?: z.Schema<T>;
}) => {
  const onStorage = (e: StorageEvent) => {
    if (e.storageArea !== localStorage) return;
    if (e.key !== key) return;
    if (!e.newValue) return;
    try {
      if (schema) {
        callback(schema.parse(JSON.parse(e.newValue)));
      } else {
        callback(JSON.parse(e.newValue));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to parse item from localStorage with key: ${key}`, error);
    }
  };
  window.addEventListener('storage', onStorage);
  return () => {
    window.removeEventListener('storage', onStorage);
  };
};
