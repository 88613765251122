import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { DotsMenu, SelectPanel } from '@cycle-app/ui';
import {
  ShareIcon, TrashIcon, LinkArrowIcon, TwitterIcon, LinkIcon, DuplicateIcon, LinkedinIcon,
} from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { setLimitationsModal, useGetPermission } from '../../reactives';
import { openRemoveReleaseNote } from '../../reactives/releases.reactive';
import { Layer } from '../../types/layers.types';
import { releaseNoteUrl } from '../../utils/changelog.utils';
import { copyToClipboard } from '../../utils/clipboard.utils';
import { DropdownLayer } from '../DropdownLayer';
import { SubmenuIcon } from './ReleaseNoteDocOpen.styles';

type Submenu = 'share' | null;

export const ReleaseNoteDocOpen = ({
  releaseId, noteId, onCopyContent, isReadOnly, onTwitterShare, onLinkedinShare,
}: {
  isReadOnly: boolean;
  releaseId: string;
  noteId: string;
  onCopyContent: VoidFunction;
  onTwitterShare: VoidFunction;
  onLinkedinShare: VoidFunction;
}) => {
  const { release } = useRelease(releaseId);
  const { releaseNote } = useReleaseNote(noteId);
  const { changelog } = useChangelog();

  const [submenu, setSubmenu] = useState<Submenu>(null);
  const { canDeleteReleaseNote } = useGetPermission();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);

  const setMouseEnterMenu = (value: string | null) => {
    setSubmenu(value as typeof submenu);
  };

  if (!release || !releaseNote?.doc) return null;

  const isReleaseUnpublished = release.publicStatus === ReleasePublicStatus.Unpublished;
  const isChangelogPublished = changelog?.isPublished;
  const releaseNotePublicId = releaseNote.publicReleaseNote?.id;
  const isPrivate = !isChangelogPublished || isReleaseUnpublished || !releaseNotePublicId;
  const url = releaseNotePublicId ? releaseNoteUrl(productSlug, changelog?.domain, releaseNotePublicId) : null;

  return (
    <DotsMenu
      onMouseEnterItem={setMouseEnterMenu}
      onMouseLeaveItem={() => setSubmenu(null)}
      onHide={() => setSubmenu(null)}
      placement="bottom-end"
      options={[{
        value: 'share',
        icon: <ShareIcon />,
        label: 'Share',
        onSelect: () => setSubmenu('share'),
        end: <SubmenuIcon $isActive={submenu === 'share'} />,
        renderLabel: () => {
          return (
            <DropdownLayer
              layer={Layer.DropdownZ2}
              visible={submenu === 'share'}
              placement="left-start"
              offset={[-10, 40]}
              content={(
                <SelectPanel
                  hideSearch
                  options={[
                    {
                      value: 'share-x',
                      icon: <TwitterIcon />,
                      label: 'X (Twitter)',
                      ...isPrivate && {
                        disabled: true,
                        tooltipContent: 'Publish the changelog and the release first',
                      },
                    },
                    {
                      icon: <LinkedinIcon />,
                      value: 'share-linkedin',
                      label: 'Linkedin',
                      ...isPrivate && {
                        disabled: true,
                        tooltipContent: 'Publish the changelog and the release first',
                      },
                    },
                    {
                      icon: <LinkIcon />,
                      value: 'copy-link',
                      label: 'Copy link',
                      ...isPrivate && {
                        disabled: true,
                        tooltipContent: 'Publish the changelog and the release first',
                      },
                    },
                    {
                      icon: <DuplicateIcon />,
                      value: 'copy-content',
                      label: 'Copy content',
                    },
                  ]}
                  onOptionChange={async (option) => {
                    if (option.value === 'copy-content') {
                      onCopyContent();
                    }
                    if (option.value === 'share-x') {
                      onTwitterShare();
                    }
                    if (option.value === 'share-linkedin') {
                      onLinkedinShare();
                    }
                    if (url && option.value === 'copy-link') {
                      copyToClipboard({
                        text: url,
                        notification: 'URL copied to clipboard',
                      });
                    }
                  }}
                />
              )}
            >
              Share
            </DropdownLayer>
          );
        },
      }, {
        icon: <LinkArrowIcon size={16} />,
        value: 'open-changelog',
        label: 'Open in Changelog',
        ...isPrivate && {
          disabled: true,
          tooltipContent: 'Publish the changelog and the release first',
        },
        onSelect: () => {
          if (url) {
            window.open(url, '_blank');
          }
        },
      }, {
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        onSelect: canDeleteReleaseNote ? openRemoveReleaseNote(noteId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
        disabled: isReadOnly,
        tooltipContent: isReadOnly ? 'Make updates or unpublish first' : null,
      }]}
    />
  );
};
