import { DocFullFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { useHotkeys, Setter } from '@cycle-app/utilities';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { Editor as TiptapCoreEditor } from '@tiptap/core';
import { useEditor } from '@tiptap/react';
import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  memo,
} from 'react';
import { useTheme } from 'styled-components';
import { Doc } from 'yjs';

import Bubble from 'src/components/Editor/Bubble/Bubble';
import { EditorAiMenu } from 'src/components/EditorAiMenu';
import { EditorContextProvider, EditorContextValue } from 'src/contexts/editorContext';
import { fullEditorExtensions } from 'src/editorExtensions/editorExtensions';
import { useProduct } from 'src/hooks';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useForcedFocus } from 'src/hooks/editor/useForcedFocus';
import { useDetectKeyboardHeight } from 'src/hooks/useDetectKeyboardEvent';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import useUploadFile from 'src/hooks/useUploadFile';
import { useThreadsPanel } from 'src/reactives/comments.reactive';
import { getEditorIsAfterDragging, unsetEditorIsAfterDragging } from 'src/reactives/editor.reactive';
import { resetEditorAi } from 'src/reactives/editorAi.reactive';
import { useGetHighlight } from 'src/reactives/highlight.reactive';
import { useGetLayerDropdown, useGetLayerDropdownZ1 } from 'src/reactives/layer.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { setToasters } from 'src/reactives/toasters.reactive';
import { resetTranscriptTooltips, setTranscriptTooltip } from 'src/reactives/transcript.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { isInsight } from 'src/utils/docType.util';
import { insertFile, reorderProsemirrorPlugins } from 'src/utils/editor/editor.utils';
import { clearColors } from 'src/utils/html.utils';

import { useDiscoverTooltip } from '../DiscoverTooltip';
import { HighlightMenu } from '../HighlightMenu/HighlightMenu';
import { InlineComments } from '../InlineComments';
import { TableMenu } from '../TableMenu/TableMenu';
import { EmptyBlock, EditorContentWrapper, EditorContent } from './Editor.styles';
import { EditorBubbleContainer } from './EditorBubbleContainer';
import { EditorQuickActions } from './EditorQuickActions';
import { useEditorTemplate } from './useEditorTemplate';

export interface EditorProps {
  className?: string;
  doc?: DocFullFragment | null;
  parentRef?: RefObject<HTMLDivElement>;
  collaboration?: {
    document: Doc;
  };
  cursors?: {
    provider: HocuspocusProvider;
    user: {
      color: string;
      name: string;
    };
  };
  autoFocus?: boolean;
  content?: string;
  onUpdate?: (p: { html: string; json: string; text: string }) => void;
  applyTemplateOnDoctypeUpdate?: boolean;
  disabledActions?: ActionId[];
  defaultDoctypeId?: string;
  setDocContent?: Setter<string>;
  autoFocusStart?: boolean;
  onEditorReady?: (editor: TiptapCoreEditor) => void;
  isReadOnly?: boolean;
  isDraft?: boolean;
  hideQuickActions?: boolean;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
  emptyPlaceholder?: string;
  smallEmptyBlock?: boolean;
  hideEmptyBlock?: boolean;
  disabledShortcuts?: string[];
  disabledFeatures?: EditorContextValue['disabledFeatures'];
}
export const Editor: FC<React.PropsWithChildren<EditorProps>> = memo(({
  className,
  doc,
  parentRef,
  collaboration,
  cursors,
  autoFocus = false,
  autoFocusStart,
  content,
  onUpdate,
  applyTemplateOnDoctypeUpdate = false,
  disabledActions = [],
  defaultDoctypeId,
  setDocContent,
  onEditorReady,
  isDraft,
  hideQuickActions,
  showAddTemplate,
  showIntegrations,
  emptyPlaceholder,
  smallEmptyBlock,
  hideEmptyBlock,
  disabledShortcuts,
  disabledFeatures,
  ...props
}) => {
  const {
    section, hoverBlockId, openBlockId,
  } = useThreadsPanel();
  const isFeedback = doc?.doctype.type === DoctypeType.Feedback;
  const { isEnabled: isEditorAiEnabled } = useFeatureFlag('editor-ai');
  const { isEnabled: isInlineCommentsEnabled } = useFeatureFlag('inline-comments');
  const { isEnabled: isInsightTurnIntoEnabled } = useFeatureFlag('insight-doc-link-turn-into');
  const { isEnabled: isDnDEnabled } = useFeatureFlag('dnd-editor-node');
  const disabledEditorActions = useMemo(
    () => {
      const actionsToDisable = [...disabledActions];
      if (isDraft) actionsToDisable.push(...[ActionId.Notion, ActionId.GithubIssue, ActionId.Linear]);
      if (!isInsightTurnIntoEnabled || !isFeedback) actionsToDisable.push(ActionId.TurnTextIntoInsight);
      if (!isEditorAiEnabled) actionsToDisable.push(ActionId.Ai);
      if (!isInlineCommentsEnabled) actionsToDisable.push(ActionId.Comments);
      if (isFeedback) {
        actionsToDisable.push(
          ActionId.TurnTextIntoDocMention,
          ActionId.TurnTextIntoDocMentionLastUsed,
        );
      }
      return actionsToDisable;
    },
    [
      disabledActions,
      isDraft,
      isInlineCommentsEnabled,
      isInsightTurnIntoEnabled,
      isFeedback,
      isEditorAiEnabled,
    ],
  );
  const theme = useTheme();
  const {
    onError,
    onUserMentioned,
  } = useEditorLogic(doc?.id);
  const {
    isUploading, onUpload, source,
  } = useUploadFile();
  const highlight = useGetHighlight();
  const initialDoctypeId = useRef(defaultDoctypeId);

  const [dragFileIsDisabled, setDragFileIsDisabled] = useState(false);

  const [editorId, setEditorId] = useState('');
  const isUpdated = useRef(false);
  const templateUpdated = useRef(false);

  const templateJson = doc?.doctype.template?.contentJSON;

  const isMobile = useIsMobile();

  const onPastedFiles = useCallback(async (editor: TiptapCoreEditor, files: File[]) => {
    // Open discovered tooltip only once
    let transcriptOpened = false;
    for (const file of files) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onUpload?.(file, 'paste')
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .then(uploadedFile => {
          if (!uploadedFile) return;

          const dataId = insertFile({
            editor,
            file: uploadedFile,
          });

          if (!transcriptOpened) {
            transcriptOpened = true;
            setTranscriptTooltip(dataId, uploadedFile);
          }
        });
    }
  }, [onUpload]);

  const {
    discoverTooltip, handlePaste,
  } = useDiscoverTooltip();
  const isDocInsight = isInsight(doc?.doctype);
  const isReadOnly = isDocInsight || props.isReadOnly;

  const { visible: dropdownVisible } = useGetLayerDropdown();
  const { visible: dropdownZ1Visible } = useGetLayerDropdownZ1();
  // Like https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/inert
  const isInert = dropdownVisible || dropdownZ1Visible;

  const editor = useEditor({
    immediatelyRender: true,
    content,
    onCreate: (params) => {
      setEditorId(crypto.randomUUID());
      reorderProsemirrorPlugins(params.editor);
    },
    onUpdate: ({
      editor: e, transaction,
    }) => {
      const html = e.getHTML();
      const json = JSON.stringify(e.getJSON());

      const text = e.getText();
      setDocContent?.(html);
      onUpdate?.({
        html,
        json,
        text,
      });
      if (!e.isEmpty && json !== templateJson) {
        isUpdated.current = true;
      }
      if (e.isFocused) {
        transaction.scrollIntoView();
      }
    },
    onSelectionUpdate: () => {
      if (getEditorIsAfterDragging()) {
        unsetEditorIsAfterDragging();
      }
    },
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
      transformPastedHTML: clearColors,
    },
    extensions: fullEditorExtensions({
      emptyPlaceholder,
      disabledActions: disabledEditorActions,
      userColor: theme.userColors.main,
      onUserMentioned,
      onPastedFiles,
      isMobile,
      ...(collaboration ? { collaboration } : {}),
      ...(cursors ? { cursors } : {}),
      handlePaste,
      disabledShortcuts,
      isDnDEnabled,
    }),
    ...(autoFocus ? { autofocus: autoFocusStart ? 'start' : 'end' } : {}),
    editable: !isReadOnly,
  }, [collaboration?.document.clientID]);

  useEffect(() => {
    if (editor) {
      onEditorReady?.(editor);
    }
  }, [editor, onEditorReady]);

  useEffect(() => {
    if (collaboration?.document.clientID) {
      console.info(`Editor for ClientID ${collaboration?.document.clientID}`);
    }
  }, [collaboration?.document.clientID]);

  useEffect(() => {
    // Reset transcript discovery tooltips when editor is mounted
    resetTranscriptTooltips();
    return () => {
      // Make sure we reset ai action when editor is unmounted
      resetEditorAi();
    };
  }, []);

  useForcedFocus({
    editor,
    parentRef,
    isEnabled: autoFocus,
    position: autoFocusStart ? 'start' : 'end',
  });

  useHotkeys('tab', (e) => {
    if (editor?.isFocused) e.preventDefault();
  });

  const {
    applyTemplate,
    previewTemplateModal,
    openPreviewTemplateModal,
    closePreviewTemplateModal,
  } = useEditorTemplate({
    editor,
    docType: doc?.doctype,
    onUpdate,
  });

  useEffect(() => {
    if (
      applyTemplateOnDoctypeUpdate &&
      !isUpdated.current &&
      doc?.doctype.id &&
      (doc.doctype.id !== initialDoctypeId.current || templateUpdated.current)
    ) {
      applyTemplate();
      templateUpdated.current = true;
    }
  }, [doc?.doctype.id]);

  useDetectKeyboardHeight();

  const { product } = useProduct();

  if (!editor) return null;

  return (
    <EditorContextProvider
      doc={doc}
      editor={editor}
      isUploading={isUploading}
      onError={onError}
      onUpload={onUpload}
      setDragFileIsDisabled={setDragFileIsDisabled}
      disabledActions={disabledEditorActions}
      isReadOnly={isReadOnly}
      uploadingSource={source}
      isInert={isInert}
      id={editorId}
      nbAiQueries={product?.nbAiQueries}
      disabledFeatures={disabledFeatures}
    >
      <EditorBubbleContainer className={className} disabled={isReadOnly || dragFileIsDisabled}>
        {onMouseDown => (
          // The order of the children is important and can lead to errors
          // Somewhere there's a synchronization issue between react rendering and dom mutations by Prosemirror or Tippy
          <>
            <div>
              <HighlightMenu
                isEnabled={!disabledEditorActions.includes(ActionId.TurnTextIntoInsight)}
                tippyOptions={{
                  popperOptions: {
                    strategy: 'fixed',
                  },
                }}
              />

              {!isReadOnly && (
                <div>
                  <Bubble disabledActions={disabledEditorActions} />
                  <TableMenu editor={editor} />
                  {!disabledEditorActions.includes(ActionId.Ai) && <EditorAiMenu />}
                </div>
              )}

              {!disabledEditorActions.includes(ActionId.Comments) && (
                <InlineComments />
              )}
              <EditorContentWrapper
                onMouseDown={onMouseDown}
                $highlightId={highlight.blockId || undefined}
                $isReadonly={props.isReadOnly}
                data-inline-comments={isInlineCommentsEnabled}
                data-thread-section={section}
                $section={section}
                $hoverBlockId={hoverBlockId}
                $openBlockId={openBlockId}
              >
                <EditorContent
                  editor={editor}
                  onBlur={() => setToasters({ areShortcutsEnabled: true })}
                  onFocus={() => setToasters({ areShortcutsEnabled: false })}
                  readOnly={isReadOnly}
                />
                {!hideQuickActions && editor.isEmpty && (
                  <EditorQuickActions
                    applyTemplate={applyTemplate}
                    disabledActions={disabledEditorActions}
                    docType={doc?.doctype}
                    onHidePreviewTemplate={closePreviewTemplateModal}
                    onShowPreviewTemplate={openPreviewTemplateModal}
                    showAddTemplate={showAddTemplate}
                    showIntegrations={showIntegrations}
                  />
                )}
                {discoverTooltip}
              </EditorContentWrapper>
            </div>

            {!hideEmptyBlock && (
              <EmptyBlock
                $hasBigHeight={!smallEmptyBlock}
                onClick={e => {
                  e.stopPropagation();
                  editor?.chain().focus('end').createParagraphNear().run();
                }}
              />
            )}
          </>
        )}
      </EditorBubbleContainer>
      {previewTemplateModal}
    </EditorContextProvider>
  );
});
