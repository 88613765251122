export enum PageId {
  Auth = 'auth',
  Login = 'login',
  OAuth = 'oauth',
  ResetPwd = 'reset-password',
  Welcome = 'welcome',
  Callback = 'callback',
  SSO = 'sso',

  Main = 'main',
  HomeDoc = 'home-doc',

  Board = 'board',

  Inbox = 'inbox',
  InboxView = 'inbox-view',
  InboxDoc = 'inbox-doc',

  Insight = 'insight',
  InsightView = 'insight-view',
  InsightDoc = 'insight-doc',

  Roadmap = 'roadmap',
  RoadmapView = 'roadmap-view',
  RoadmapDoc = 'roadmap-doc',

  Releases = 'releases',
  Release = 'release',
  ReleaseNote = 'release-note',
  ReleaseDoc = 'releases-doc',

  Analyses = 'analyses',
  AnalysesDoc = 'analyses-doc',

  Doc = 'doc',
  DocFullPage = 'doc-fullpage',

  // Settings
  Settings = 'settings',
  SettingsUsers = 'settings-users',
  SettingsFeedback = 'settings-feedback',
  SettingsInsights = 'settings-insights',
  SettingsRoadmaps = 'settings-roadmaps',
  SettingsRoadmapsDocType = 'settings-roadmaps-doctype',
  SettingsReleases = 'settings-releases',
  SettingsAttributes = 'settings-attributes',
  SettingsIntegrations = 'settings-integrations',
  SettingsAPI = 'settings-api',
  SettingsCustomers = 'settings-customers',
  SettingsCustomer = 'settings-customer',
  SettingsCompany = 'settings-company',
  SettingsCompanies = 'settings-companies',
  SettingsBilling = 'settings-billing',
  SettingsWorkflows = 'settings-workflows',
  SettingsAi = 'settings-ai',

  NewDoc = 'new-doc',
  Video = 'video',

  // Billing related
  RequestMakerAccess = 'request-maker-access',

  // Onboarding
  GetStarted = 'get-started',

  // Changelog builder
  Changelog = 'changelog',
}

export type SettingsPageId =
  | typeof PageId.Settings
  | typeof PageId.SettingsUsers
  | typeof PageId.SettingsFeedback
  | typeof PageId.SettingsInsights
  | typeof PageId.SettingsRoadmaps
  | typeof PageId.SettingsRoadmapsDocType
  | typeof PageId.SettingsReleases
  | typeof PageId.SettingsAttributes
  | typeof PageId.SettingsIntegrations
  | typeof PageId.SettingsAPI
  | typeof PageId.SettingsAi
  | typeof PageId.SettingsCustomers
  | typeof PageId.SettingsCustomer
  | typeof PageId.SettingsCompany
  | typeof PageId.SettingsCompanies
  | typeof PageId.SettingsBilling
  | typeof PageId.SettingsWorkflows;

export type MainPageId = Exclude<PageId, SettingsPageId>;

export const routing: Record<PageId, string> = {
  [PageId.Auth]: '/',
  [PageId.ResetPwd]: '/password_reset/:token?',
  [PageId.Login]: '/login',
  [PageId.Welcome]: '/welcome',
  [PageId.OAuth]: '/oauth/authorize',

  [PageId.Callback]: '/callback/:provider',
  [PageId.SSO]: '/sso/:provider',

  [PageId.Main]: '/app/:productSlug?',
  [PageId.HomeDoc]: '/app/:productSlug/home/doc/:docSlug',

  [PageId.Inbox]: '/app/:productSlug/feedback',
  [PageId.InboxView]: '/app/:productSlug/feedback/:boardSlug',
  [PageId.InboxDoc]: '/app/:productSlug/feedback/:boardSlug/doc/:docSlug',

  [PageId.Insight]: '/app/:productSlug/insights',
  [PageId.InsightView]: '/app/:productSlug/insights/:boardSlug',
  [PageId.InsightDoc]: '/app/:productSlug/insights/:boardSlug/doc/:docSlug',

  [PageId.Roadmap]: '/app/:productSlug/roadmaps',
  [PageId.RoadmapView]: '/app/:productSlug/roadmaps/:boardSlug',
  [PageId.RoadmapDoc]: '/app/:productSlug/roadmaps/:boardSlug/doc/:docSlug',

  [PageId.Releases]: '/app/:productSlug/releases',
  [PageId.Release]: '/app/:productSlug/releases/:releaseId',
  [PageId.ReleaseNote]: '/app/:productSlug/releases/:releaseId/notes/:noteId',
  [PageId.ReleaseDoc]: '/app/:productSlug/releases/:releaseId/notes/:noteId/doc/:docSlug',

  [PageId.Analyses]: '/app/:productSlug/analyses/:dashboardId?',
  [PageId.AnalysesDoc]: '/app/:productSlug/analyses/doc/:docSlug',

  [PageId.Board]: '/app/:productSlug/board/:boardSlug',
  [PageId.Doc]: '/app/:productSlug/board/:boardSlug/doc/:docSlug',
  [PageId.DocFullPage]: '/app/:productSlug/doc/:docSlug',

  [PageId.Settings]: '/app/:productSlug/settings',
  [PageId.SettingsUsers]: '/app/:productSlug/settings/members',
  [PageId.SettingsFeedback]: '/app/:productSlug/settings/feedback',
  [PageId.SettingsInsights]: '/app/:productSlug/settings/insights',
  [PageId.SettingsRoadmaps]: '/app/:productSlug/settings/roadmaps',
  [PageId.SettingsRoadmapsDocType]: '/app/:productSlug/settings/roadmaps/:doctypeId',
  [PageId.SettingsReleases]: '/app/:productSlug/settings/releases',
  [PageId.SettingsAttributes]: '/app/:productSlug/settings/properties',
  [PageId.SettingsIntegrations]: '/app/:productSlug/settings/integrations',
  [PageId.SettingsAi]: '/app/:productSlug/settings/ai',
  [PageId.SettingsAPI]: '/app/:productSlug/settings/api',
  [PageId.SettingsCustomers]: '/app/:productSlug/settings/customers',
  [PageId.SettingsCustomer]: '/app/:productSlug/settings/customers/:customerId',
  [PageId.SettingsCompany]: '/app/:productSlug/settings/companies/:companyId',
  [PageId.SettingsCompanies]: '/app/:productSlug/settings/companies',
  [PageId.SettingsBilling]: '/app/:productSlug/settings/billing',
  [PageId.SettingsWorkflows]: '/app/:productSlug/settings/workflows',

  [PageId.RequestMakerAccess]: '/app/:productSlug/request-maker-access',

  [PageId.Changelog]: '/app/:productSlug/settings/changelog',

  [PageId.NewDoc]: '/new',
  [PageId.Video]: '/video/:src',
  [PageId.GetStarted]: '/get-started',
};

export const ALL_DOCS_BOARD_SLUG = 'all-docs';
