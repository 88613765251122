import { Skeleton, Flex } from '@cycle-app/ui';
import { CloseIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DocPanelWithBoardConfig } from 'src/app/Main/Board/DocPanel/DocPanel';
import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { PageId, routing } from 'src/constants/routing.constant';
import { ReleaseNotesProvider } from 'src/contexts/releaseNotesContext';
import { useNavigate } from 'src/hooks';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useParams } from 'src/hooks/router/useParams';
import { usePageId } from 'src/hooks/usePageId';
import { useGetPermission } from 'src/reactives';
import { getNodeContents } from 'src/utils/html.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import { useChangelog } from '../../hooks/releases/useChangelog';
import { releaseNoteUrl } from '../../utils/changelog.utils';
import {
  Container, ReleaseNoteHeader, HeaderContent, Left, Right, Nav, Action,
  EditorContainer, EditorHeader, Alert, StyledDocPanelRealtime,
  HiddenEditorContainer,
} from './ReleaseNote.styles';
import { ReleaseNoteDocFeature } from './ReleaseNoteDocFeature';
import { ReleaseNoteDocOpen } from './ReleaseNoteDocOpen';
import { ReleaseNoteEditor } from './ReleaseNoteEditor';
import { ReleaseNoteEditorTitle } from './ReleaseNoteEditorTitle';
import { ReleaseNotePrevNext } from './ReleaseNotePrevNext';
import { ReleaseNoteTags } from './ReleaseNoteTags';

const useCloseReleaseNote = () => {
  const { releaseId } = useParams();
  const { navigateToRelease } = useNavigate();
  return () => {
    if (!releaseId) return;
    navigateToRelease(releaseId);
  };
};

type State = {
  isCopying: boolean;
  action: 'copy' | 'share-x' | 'share-linkdin' | null;
};

export const ReleaseNote = () => {
  const [copy, setIsCopying] = useState<State>({
    isCopying: false,
    action: null,
  });
  const hiddenEditorContainerRef = useRef<HTMLDivElement>(null);
  const { canUpdateReleaseNote } = useGetPermission();
  const {
    releaseId, noteId,
  } = useParams();
  const {
    release, isReleaseLoading, isReadonly,
  } = useRelease(releaseId);
  const {
    releaseNote, isReleaseNoteLoading,
  } = useReleaseNote(noteId);
  const closeReleaseNote = useCloseReleaseNote();
  const showContent = usePageId(pageId => pageId !== PageId.ReleaseDoc);
  const { changelog } = useChangelog();
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);

  if (!noteId || !releaseId || !release) return null;

  const isLoading = isReleaseLoading || isReleaseNoteLoading;
  const releaseDate = toShortLocaleDateString(release.date);

  return (
    <>
      {releaseNote?.title && (
        <ReleasesPageTitle
          releaseDate={releaseDate}
          releaseNoteTitle={releaseNote?.title}
        />
      )}
      <Container
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {
            opacity: 1,
            transition: {
              duration: 0.1,
              delay: 0.1,
            },
          },
          hidden: {
            opacity: 0,
            transition: {
              duration: 0.1,
            },
          },
        }}
      >
        <EditorContainer>
          {showContent && (
            <>
              <EditorHeader>
                <ReleaseNoteAlert releaseNoteId={noteId} />
                {releaseNote?.doc && (
                  <ReleaseNoteDocFeature
                    releaseId={releaseId}
                    noteId={releaseNote.id}
                    doc={releaseNote.doc}
                  />
                )}
                <ReleaseNoteTags isReadonly={isReadonly} releaseNoteId={noteId} />
                <ReleaseNoteEditorTitle
                  releaseNoteId={noteId}
                  isReadOnly={isReadonly || !canUpdateReleaseNote}
                />
              </EditorHeader>

              <ReleaseNoteEditor
                releaseNoteId={noteId}
                isReadOnly={isReadonly || !canUpdateReleaseNote}
              />
            </>
          )}
        </EditorContainer>

        <ReleaseNoteHeader>
          {isLoading ? (
            <HeaderSkeleton />
          ) : (
            <HeaderContent>
              <Left>
                <Nav>
                  <Action onClick={closeReleaseNote}>
                    <CloseIcon />
                  </Action>
                  <ReleaseNotesProvider
                    isReadonly={isReadonly}
                    showBugAndImprovements={release.showBugAndImprovements}
                    publicStatus={release.publicStatus}
                    releaseId={releaseId}
                    isNoValue={null}
                  >
                    <ReleaseNotePrevNext />
                  </ReleaseNotesProvider>
                </Nav>
              </Left>
              {noteId && (
                <Flex $gap={12}>
                  <StyledDocPanelRealtime />
                  <ReleaseNoteDocOpen
                    isReadOnly={isReadonly}
                    onCopyContent={() => setIsCopying({
                      isCopying: true,
                      action: 'copy',
                    })}
                    onTwitterShare={() => setIsCopying({
                      isCopying: true,
                      action: 'share-x',
                    })}
                    onLinkedinShare={() => setIsCopying({
                      isCopying: true,
                      action: 'share-linkdin',
                    })}
                    releaseId={releaseId}
                    noteId={noteId}
                  />
                </Flex>
              )}
            </HeaderContent>
          )}
        </ReleaseNoteHeader>

        <Switch>
          <Route path={routing[PageId.ReleaseDoc]}>
            <DocPanelWithBoardConfig />
          </Route>
        </Switch>
      </Container>

      {copy.isCopying && (
        <HiddenEditorContainer ref={hiddenEditorContainerRef}>
          <ReleaseNoteEditorTitle
            releaseNoteId={noteId}
            isReadOnly
          />
          <ReleaseNoteEditor
            releaseNoteId={noteId}
            isReadOnly
            onLoad={async (editor) => {
              // Create a new paragraph at the end
              // Images are not copied if they are the last element
              editor?.chain().focus('end').createParagraphNear().run();

              await getNodeContents(hiddenEditorContainerRef.current, (text) => {
                if (copy.action === 'copy') {
                  document.execCommand('copy');
                  addToaster({ title: 'Release note copied to clipboard' });
                }
                const releaseNotePublicId = releaseNote?.publicReleaseNote?.id;
                if (changelog && releaseNotePublicId && (copy.action === 'share-x' || copy.action === 'share-linkdin')) {
                  const url = releaseNoteUrl(productSlug, changelog.domain, releaseNotePublicId);
                  const encodedText = encodeURIComponent(text || '');
                  const encodedUrl = encodeURIComponent(url);
                  if (copy.action === 'share-x') {
                    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
                    window.open(twitterUrl, '_blank', 'width=550,height=420');
                  }
                  if (copy.action === 'share-linkdin') {
                    const linkedinUrl = `https://www.linkedin.com/feed/?shareActive&mini=true&shareUrl=${encodedUrl}&text=${encodedText}`;
                    window.open(linkedinUrl, '_blank', 'width=600,height=600');
                  }
                }
                setIsCopying({
                  isCopying: false,
                  action: null,
                });
              });
            }}
          />
        </HiddenEditorContainer>
      )}
    </>
  );
};

const ReleaseNoteAlert = ({ releaseNoteId }: { releaseNoteId: string }) => {
  const { releaseNote } = useReleaseNote(releaseNoteId);
  if (!releaseNote?.isOther) return null;
  return (
    <Alert>
      <p>
        <InfoIconOutline />
      </p>
      <p>
        {'This release note is in the '}
        <strong>Other improvements & bugs section</strong>
        , the description will not appear in the public change log, only the title.
      </p>
    </Alert>
  );
};

const HeaderSkeleton = () => {
  const closeReleaseNote = useCloseReleaseNote();
  return (
    <HeaderContent>
      <Left>
        <Action onClick={closeReleaseNote}>
          <CloseIcon />
        </Action>
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={60} />
      </Left>
      <Right>
        <Skeleton height={20} width={60} />
        <Skeleton height={20} width={60} />
      </Right>
    </HeaderContent>
  );
};
