import { typo, ActionButton, Badge } from '@cycle-app/ui';
import { mobile, tablet, ColorTheme } from '@cycle-app/utilities';
import { Tabs, TabList, Tab } from '@reach/tabs';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { HEADER_SECONDARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';
import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';

export const DisplayedContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  ${mobile} {
    padding-top: ${HEADER_SECONDARY_HEIGHT}px;
  }
`;

export const NoComment = styled.div`
  margin-top: 42px;
  text-align: center;
  ${typo.body500};

  img {
    max-width: 185px;
  }
`;

export const Hint = styled.div`
  text-align: center;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400};
`;

export const Container = styled(Tabs)<{
  $collapsed: boolean;
}>`
  position: relative;

  display: flex;
  flex-direction: column;

  color: ${p => p.theme.colors.text.primary};
  width: ${p => (p.$collapsed ? 0 : `${RIGHT_PANEL_WIDTH}px`)};
  transition: width 150ms ease-in-out;
  
  border-left: 1px solid;
  border-color: ${p => p.theme.colors.docPanel.rightPanel.border};
  ${mobile} {
    border-left: none;
  }

  ${tablet} {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-color: ${p => (p.theme.isDark ? p.theme.colors.background.secondary : p.theme.colors.background.white)};
    z-index: 1;
  }

  ${mobile} {
    width: ${p => (p.$collapsed ? 0 : '100%')};
  }
`;

const actionCss = css`
  overflow: hidden;
  ${typo.caption500};
  background-color: transparent;
  font-size: 14px;
  color: ${p => p.theme.colors.text.secondary};
  gap: 8px;
  height: 28px;
  width: 28px;

  &.force-focus {
    color: ${p => p.theme.colors.text.primary};
    background: ${p => p.theme.colors.components.Tab.bgHover};
  }
`;

export const StyledAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  :hover, :focus-visible {
    color: ${p => p.theme.colors.components.Tab.textHover};
    background: ${p => p.theme.colors.components.Tab.bgHover};
  }
  :active {
    color: ${p => p.theme.colors.components.Tab.textActive};
    background: ${p => p.theme.colors.components.Tab.bgActive};
  }
  ${actionCss}
  height: 32px;
  width: auto;
  padding: 4px 8px;

  &.force-focus ${Badge} {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey300)};
  }
`;

export const StyledActionButton = styled(ActionButton).attrs({ size: 'L' })`
  ${actionCss}
`;

export const RightActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const StyledTabList = styled(TabList)`
  display: flex;
  padding: 10px 16px 10px 16px;
  align-items: center;
  gap: 4px;
  width: 100%;
`;

export const StyledTab = styled(Tab)<{
  $isHidden: boolean;
}>`
  flex: 1;
  position: relative;
  cursor: pointer;
  ${typo.body400}
  padding: 0;

  &[data-selected] {
    ${typo.body500}
    color: ${p => p.theme.colors.text.primary};
  }

  ${p => p.$isHidden && css`
    display: contents;
  `}
`;
