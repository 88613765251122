import { Emoji, SelectOption, AnalysesIcon, TextHighlighter, Tooltip } from '@cycle-app/ui';
import { useMemo } from 'react';

import { BoardDefaultIcon } from 'src/components/BoardDefaultIcon';
import { useProduct, useProductAddOn } from 'src/hooks';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { SectionParent, getSectionType } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';
import { getSectionName } from 'src/utils/viewSection.utils';

import { PageId } from '../../constants/routing.constant';
import { Option, Name, SectionName } from './ViewsPanel.styles';

export const useViewOptions = (sectionParent: SectionParent) => {
  const { isEnabled: isAnalysesEnabled } = useProductAddOn('ANALYSES');
  const { product } = useProduct();
  const { boards } = useSectionBoards(sectionParent);
  const {
    mappingBoardLinks, sections,
  } = useBoardSections();
  const luzmoSecondDashboardId = product?.luzmoSecondDashboardId;

  return useMemo<SelectOption[]>(() => {
    const options: SelectOption[] = boards.map(board => {
      const boardLink = Object.values(mappingBoardLinks).find(link => link.board.id === board.id);
      const sectionName = boardLink ? getSectionName(boardLink?.section, sections.length) : null;
      return ({
        value: getBoardSlug(board),
        label: `${board.name} ${sectionName}`,
        renderLabel: (filterText) => (
          <Option>
            <Tooltip
              content={board.description}
              placement="bottom"
              withPortal
              withWrapper={false}
              disabled={!board.description}
            >
              <Name>
                <TextHighlighter
                  searchWords={[filterText]}
                  textToHighlight={board.name}
                  className="highlight"
                />
              </Name>
            </Tooltip>

            {sectionName && (
              <SectionName>
                <TextHighlighter
                  searchWords={[filterText]}
                  textToHighlight={sectionName}
                  className="highlight"
                />
              </SectionName>
            )}
          </Option>
        ),
        icon: board.emoji ? (
          <Emoji
            emoji={board.emoji}
            color={board.color}
            size={16}
          />
        ) : (
          <BoardDefaultIcon sectionType={getSectionType(sectionParent)} />
        ),
      });
    });
    if (isAnalysesEnabled && sectionParent === 'insight') {
      if (luzmoSecondDashboardId) {
        options.unshift({
          label: 'Customer Dashboard',
          value: `${PageId.Analyses}/${luzmoSecondDashboardId}`,
          icon: <AnalysesIcon />,
        });
      }
      options.unshift({
        label: 'Analyses',
        value: PageId.Analyses,
        icon: <AnalysesIcon />,
      });
    }

    return options;
  }, [boards, isAnalysesEnabled, mappingBoardLinks, sectionParent, sections.length, luzmoSecondDashboardId]);
};
