import { Feature, Role } from '@cycle-app/graphql-codegen';
import { indexBy } from 'ramda';
import { useMemo } from 'react';

import { useMaybeMeV2 } from './api/useMe';
import { useProduct } from './api/useProduct';

import type { AddOn } from 'src/types/addOn.types';

const HARD_CODED_ADD_ONS_NAMES: AddOn['name'][] = [
  'activity-log',
  'admin-tools',
  'attio',
  'confluence',
  'file-size-unlimited',
  'fresh-desk',
  'grain-sight',
  'jira',
  'microsoft-teams',
  'plan-hat',
  'saml',
  'slack-advanced',
  'snow-flake',
  'unlimited-workspaces',
  'vitally',
  'zendesk',
];

const hardCodedAddOns: AddOn[] = HARD_CODED_ADD_ONS_NAMES.map(addOnName => ({
  name: addOnName,
  isEnabled: false,
  isLoading: false,
  isLoaded: true,
}));

const hardCodedAddOnsByName = indexBy(addOn => addOn.name, hardCodedAddOns);

export const useProductAddOns = (): AddOn[] => {
  const {
    product,
    loading,
  } = useProduct();

  const isLoaded = !!product;

  const unlimitedAiQueries = {
    isEnabled: !!product?.features.some(f => f === Feature.UnlimitedAiQueries),
    isLoading: loading,
    isLoaded,
  };
  const unlimitedDocTypes = {
    isEnabled: !!product?.features.some(f => f === Feature.UnlimitedDoctypes),
    isLoading: loading,
    isLoaded,
  };
  const unlimitedProperties = {
    isEnabled: !!product?.features.some(f => f === Feature.UnlimitedProperties),
    isLoading: loading,
    isLoaded,
  };
  const hubSpot = {
    isEnabled: !!product?.features.some(f => f === Feature.Hubspot),
    isLoading: loading,
    isLoaded,
  };
  const meetings = {
    isEnabled: !!product?.features.some(f => f === Feature.Meetings),
    isLoading: loading,
    isLoaded,
  };
  const analyses = {
    isEnabled: !!product?.features.some(f => f === Feature.Analyses),
    isLoading: loading,
    isLoaded,
  };
  const customerAttributes = {
    isEnabled: !!product?.features.some(f => f === Feature.CustomerAttributes),
    isLoading: loading,
    isLoaded,
  };
  const salesforce = {
    isEnabled: !!product?.features.some(f => f === Feature.Salesforce),
    isLoading: loading,
    isLoaded,
  };
  return useMemo(() => ([
    {
      name: Feature.UnlimitedAiQueries,
      ...unlimitedAiQueries,
    },
    {
      name: Feature.UnlimitedDoctypes,
      ...unlimitedDocTypes,
    },
    {
      name: Feature.UnlimitedProperties,
      ...unlimitedProperties,
    },
    {
      name: Feature.Hubspot,
      ...hubSpot,
    },
    {
      name: Feature.Meetings,
      ...meetings,
    },
    {
      name: Feature.Analyses,
      ...analyses,
    },
    {
      name: Feature.CustomerAttributes,
      ...customerAttributes,
    },
    {
      name: Feature.Salesforce,
      ...salesforce,
    },
    ...hardCodedAddOns,
  ]), [
    unlimitedAiQueries, unlimitedDocTypes,
    unlimitedProperties, hubSpot, meetings, analyses,
    customerAttributes, salesforce,
  ]);
};

type AddOnOptions = {
  enableForSuperAdmin?: boolean;
};

export const useProductAddOn = (
  name: AddOn['name'],
  options: AddOnOptions = {
    enableForSuperAdmin: true,
  },
): AddOn => {
  const { me } = useMaybeMeV2();
  const addons = useProductAddOns();
  const featureFlag = addons.find(a => a.name === name);

  if (HARD_CODED_ADD_ONS_NAMES.includes(name) || !featureFlag) return hardCodedAddOnsByName[name];

  return {
    // name,
    ...featureFlag,
    isEnabled: featureFlag.isEnabled || (!!options.enableForSuperAdmin && me?.role === Role.SuperAdmin),
  };
};
