import { AddIcon } from '@cycle-app/ui/icons';

import { useCreateViewModal } from 'src/hooks/useCreateViewModal';
import { useNavigate } from 'src/hooks/useNavigate';
import { PageId } from 'src/hooks/usePageId';
import { useGetPermission, useIsMobile } from 'src/reactives';
import { SectionParent, getSectionName, getViewPageId } from 'src/utils/section.utils';

import { useViewOptions } from './useViewOptions';
import { StyledSelectPanel, AddView } from './ViewsPanel.styles';

type Props = {
  sectionParent: SectionParent;
  hide: VoidFunction;
};

export const ViewsPanel = ({
  sectionParent, hide,
}: Props) => {
  const isMobile = useIsMobile();
  const { navigate } = useNavigate();
  const options = useViewOptions(sectionParent);
  const {
    isPermissionInit, canCreateView,
  } = useGetPermission();

  const {
    openModal, modal,
  } = useCreateViewModal({
    sectionParent,
    onHide: hide,

  });
  return (
    <>
      <StyledSelectPanel
        listMaxHeight="300px"
        searchPlaceholder={`Search ${getSectionName(sectionParent).toLocaleLowerCase()} views`}
        showCreateOption={!isMobile && isPermissionInit && canCreateView}
        defaultCreateOptionLabel={(
          <AddView>
            <AddIcon size={12} />
            <span>{`Create ${getSectionName(sectionParent).toLocaleLowerCase()} view`}</span>
          </AddView>
        )}
        options={options}
        onOptionChange={(option, event) => {
          hide();
          if (option.value.startsWith(PageId.Analyses)) {
            // analyses/{dashboardId}, allowed to be undefined.
            navigate(PageId.Analyses, { dashboardId: option.value.split('/')[1] }, undefined, event?.metaKey);
          } else {
            navigate(getViewPageId(sectionParent), { boardSlug: option.value }, undefined, event?.metaKey);
          }
        }}
        onCreateOption={async name => openModal(name)}
      />
      {modal}
    </>
  );
};
