import { Skeleton, Flex } from '@cycle-app/ui';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import { useDocV2 } from 'src/hooks';
import { Layer } from 'src/types/layers.types';

type Props = {
  docId: string;
  readOnly?: boolean;
};

export const FeatureCardAttributes = ({
  docId, readOnly = false,
}: Props) => {
  const isOptimisticData = docId.includes('temp');

  const { doc } = useDocV2(docId, {
    skip: isOptimisticData,
  });

  if (!doc || isOptimisticData) {
    return (
      <Flex $gap={8}>
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={80} />
      </Flex>
    );
  }

  return (
    <DocPanelDocAttributes
      doc={doc}
      showDocIdWithDocType
      layer={Layer.DropdownModal}
      hideStatusLabel
      readOnly={readOnly}
    />
  );
};
