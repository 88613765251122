import { make } from 'src/utils/reactives.util';

export type DashboardUpdateModalResult = {
  isOpened: boolean;
};

export const {
  hookState: useDashboardUpdateModal,
  hookValue: useGetDashboardUpdateModal,
  getValue: getDashboardUpdateModal,
  setValue: setDashboardUpdateModal,
  resetValue: resetDashboardUpdateModal,
} = make<DashboardUpdateModalResult>({
  defaultState: {
    isOpened: false,
  },
});
