import { typo, ActionButton, Input } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const PageContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  > * + * {
    border-left: 1px solid ${p => p.theme.colors.releases.border};
  }
`;

export const Section = styled(motion.div)`
  flex: 1;
  height: 100%;
  overflow: hidden;
  background: ${p => p.theme.colors.releases.bg};
`;

export const SectionContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 16px 24px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  h1 {
    ${typo.headerLight}
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderButton = styled(ActionButton)`
  width: 28px;
  height: 28px;
`;

export const SearchInput = styled(Input)`
  input {
    height: 28px;
  }
`;
