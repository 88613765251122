import { useState, useLayoutEffect, RefObject } from 'react';

import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP, QUOTE_CARD_WIDTH } from 'src/constants/editor.constants';

// Width below which cards must be collapsed
const CONTAINER_WIDTH_LIMIT = EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 3 + QUOTE_CARD_WIDTH;

type Props = {
  disabled: boolean;
  containerRef: RefObject<HTMLDivElement>;
};

/**
 * Hook to determine if quote cards should be collapsed based on the width of the container
 */
export const useQuoteCardsCollapsed = ({
  disabled, containerRef,
}: Props) => {
  const [collapsed, setCollapsed] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    let rafId: number;

    const observe = () => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();
      if (collapsed === null) {
        setCollapsed(rect.width < CONTAINER_WIDTH_LIMIT);
      } else if (collapsed && rect.width > CONTAINER_WIDTH_LIMIT) {
        setCollapsed(false);
      } else if (!collapsed && rect.width < CONTAINER_WIDTH_LIMIT) {
        setCollapsed(true);
      }
      rafId = requestAnimationFrame(observe);
    };

    if (!disabled) observe();

    return () => {
      cancelAnimationFrame(rafId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, collapsed]);

  return {
    quoteCardsCollapsed: collapsed,
    containerRef,
  };
};
