import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Flex } from '@cycle-app/ui';
import { getDocSlug } from '@cycle-app/utilities';

import { useNavigate } from '../../hooks';
import { StatusTag } from '../DocStatus';
import { ReleaseDocInsights } from '../ReleaseNotes/ReleaseDocInsights';
import { Container, StyledButton } from './ReleaseNoteDocFeature.styles';

type Props = {
  releaseId: string;
  noteId: string;
  doc: {
    id: string;
    title: string;
    status?: {
      category: StatusCategory;
      value: string;
    } | null;
  };
};

export const ReleaseNoteDocFeature = ({
  doc, noteId, releaseId,
}: Props) => {
  const {
    title, status,
  } = doc;
  const { navigateToReleaseDoc } = useNavigate();
  const docSlug = getDocSlug(doc);
  return (
    <Container>
      <StyledButton
        variant="nospace"
        size="L"
        onClick={(e) => {
          navigateToReleaseDoc(releaseId, noteId, docSlug, {}, e.metaKey);
        }}
      >
        <span>{title}</span>
      </StyledButton>
      <Flex $gap={10} $align="center" style={{ position: 'relative' }}>
        <ReleaseDocInsights noteId={noteId} />
        {status && <StatusTag category={status.category} value={status.value} isReadOnly />}
      </Flex>
    </Container>
  );
};
