import { DocAttribute, DocBaseWithDocSourceFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray, getHighlightHash } from '@cycle-app/utilities';
import { MouseEvent } from 'react';

import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { DocSource } from 'src/components/DocSource';
import { DocStatus } from 'src/components/DocStatus';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { InsightCardOptions } from 'src/components/InsightCardOptions';
import { PropertiesContainer, ParentContainer } from 'src/components/InsightsList/InsightsList.styles';
import { useIsRoadmapsEnabled, useLocation, useNavigateToDocFullOrPanel } from 'src/hooks';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';

import { InsightCard } from './DocHierarchyGroupInsightItem.styles';

export type DocHierarchyGroupInsightItemProps = {
  doc: DocBaseWithDocSourceFragment;
  onClickItem?: (e?: MouseEvent) => void;
  canUpdateQuote?: boolean;
};

export const DocHierarchyGroupInsightItem = ({
  doc, onClickItem, canUpdateQuote = true,
}: DocHierarchyGroupInsightItemProps) => {
  const docType = useGetDocType(doc.doctype.id);
  const location = useLocation();
  const fromStarredBoard = location.state?.fromStarredBoard;
  const navigateToDoc = useNavigateToDocFullOrPanel();
  const {
    contextUpdateEl,
    isClickOnEditForm,
    contextEl,
    startEdit,
  } = useInsightCardEditQuoteProps({
    docTarget: doc.docSource,
  });
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  return (
    <InsightCard
      key={doc.id}
      blockId={doc.docSource?.blockId}
      context={contextEl}
      contextText={doc.docSource?.content ?? ''}
      options={(
        <InsightCardOptions
          layer={Layer.DropdownModalZ3}
          blockId={doc.docSource?.blockId}
          doc={doc}
          editParent={!doc.parent}
          onUpdateQuoteSelect={canUpdateQuote ? startEdit : undefined}
        />
      )}
      onClick={e => {
        /**
         * We do not want to redirect if user is clicking inside the editing
         * quote area
         */
        if (isClickOnEditForm(e)) return;
        onClickItem?.(e);
        /**
         * Customer modal: Insight item opens initiative.
         * Right now the insight as doc hierarchy group is only included in the
         * customer modal, so we do no need extra context for this behavior.
         */
        navigateToDoc(doc.docSource?.doc ? {
          title: doc.docSource.doc.title,
          id: doc.docSource.doc.id,
          hash: getHighlightHash({
            docId: doc.id,
            blockId: doc.docSource?.blockId,
          }),
        } : {
          title: doc.title,
          id: doc.id,
        }, {
          isDocRelative: true,
          fromStarredBoard,
        }, e.metaKey);
      }}
      properties={(
        <PropertiesContainer>
          {doc.status && isRoadmapsEnabled && (
            <DocStatus
              docId={doc.id}
              statusId={doc.status.id}
              docTypeId={doc.doctype.id}
              isDisabled
            />
          )}
          <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />
        </PropertiesContainer>
      )}
      title={doc.title}
      footerRight={(
        <>
          <DocSource doctypeId={doc.doctype.id} docId={doc.id} source={doc.source} />
          {!!doc.assignee?.id && docType && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isDisabled={!!doc.docSource}
              isRemovable={false}
              layer={Layer.DropdownModalZ1}
              dropdownPlacement="bottom-end"
              docId={doc.id}
              docTypeName={docType.name}
              docTypeType={docType.type}
            />
          )}
        </>
      )}
      parentSlot={!!doc.parent && (
        <ParentContainer>
          <DocParentDropdown
            showParentTitle
            docId={doc.id}
            docTypeId={doc.doctype.id}
            minimal
            placeholder="Add"
            context="doc-item"
            layer={Layer.DropdownModalZ3}
            onParentClick={onClickItem}
          />
        </ParentContainer>
      )}
      contextUpdate={canUpdateQuote ? contextUpdateEl : null}
    />
  );
};
