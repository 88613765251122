import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

import { makeBooleanVar } from '../utils/makeVar.utils';

export enum Content {
  Comments = 'Comments',
  Activity = 'Activity',
  Insights = 'Insights',
}

interface DocPanelState {
  content: Content;
  isExpanded: boolean;
  autoFocus: boolean;
  isHovered: boolean;
}

const {
  hookValue,
  getValue,
  setValue,
} = make<DocPanelState>({
  defaultState: {
    content: Content.Comments,
    isExpanded: false,
    autoFocus: true,
    isHovered: false,
  },
});

export const useGetDocPanel = hookValue;
export const getDocPanel = getValue;
export const setDocPanel = (...params: Parameters<typeof setValue>) => {
  if (getVerifyQuotesOpen() && params[0].isExpanded) {
    closeVerifyQuotes();
  }
  setValue(...params);
};

export const closePanel = () => setDocPanel({ isExpanded: false });

export const openCommentsPanel = () => setDocPanel({
  isExpanded: true,
  content: Content.Comments,
});

export const {
  hookValue: useGetInsightSuggestions,
  getValue: getInsightSuggestions,
  setValue: setInsightSuggestions,
} = make<{
  isCollapsed: boolean;
}>({
  localKey: LocalKey.InsightSuggestions,
  crossTabSync: true,
  defaultState: {
    isCollapsed: false,
  },
});

export const toggleInsightSuggestions = () => setInsightSuggestions({ isCollapsed: !getInsightSuggestions().isCollapsed });

export const {
  hookValue: useGetAiGeneratedInsights,
  getValue: getAiGeneratedInsights,
  setValue: setAiGeneratedInsights,
} = make<{
  isCollapsed: boolean;
}>({
  localKey: LocalKey.AiGeneratedInsights,
  crossTabSync: true,
  defaultState: {
    isCollapsed: false,
  },
});

export const toggleAiGeneratedInsights = () => setAiGeneratedInsights({ isCollapsed: !getAiGeneratedInsights().isCollapsed });

/* Opening state of the verify quotes panel in a feedback doc */
export const {
  hook: useVerifyQuotesOpen,
  get: getVerifyQuotesOpen,
  setTrue: openVerifyQuotes,
  setFalse: closeVerifyQuotes,
} = makeBooleanVar(false);
