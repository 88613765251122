import { useApolloClient } from '@apollo/client';
import { AiState, DocBaseFragment, FetchDocLinkedDocsDocument } from '@cycle-app/graphql-codegen';
import { CheckboxInput, Tooltip } from '@cycle-app/ui';
import { CloseIcon, AiIcon } from '@cycle-app/ui/icons';
import { produce } from 'immer';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useMemo, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { DoctypeInfoIcon } from 'src/components/DoctypeInfoIcon';
import { SuggestionInsightCard } from 'src/components/InsightSuggestions/SuggestionInsightCard';
import { useDocInsights, useOptimizedBooleanState } from 'src/hooks';
import { useRemoveDoc } from 'src/hooks/api/mutations/updateDocHooks';
import { useUpdateDocAiState } from 'src/hooks/doc/useUpdateDocAiState';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useGetDocTypes, useInsightParentDocTypeIds } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';
import { isParentOfInsight } from 'src/utils/docType.util';
import { addToaster } from 'src/utils/toasters.utils';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

import {
  StyledPortalModal, CloseButton, Header, ListContainer, List, Footer, SubmitButton, SelectButton,
  Group, GroupHeader, GroupTitle, ListItem, GroupInfo, StyledDocParentDropdown,
} from './AiGeneratedInsightsModal.styles';

export const AiGeneratedInsightsModal = ({
  hide, doc,
}: {
  hide: VoidFunction;
  doc: DocBaseFragment;
}) => {
  const parentDocTypeIds = useInsightParentDocTypeIds();
  const { removeDoc } = useRemoveDoc({ toaster: false });
  const { updateDocAiState } = useUpdateDocAiState();

  const { cache } = useApolloClient();

  const validatedInsightsOptions = {
    query: FetchDocLinkedDocsDocument,
    variables: {
      id: doc.id,
      aiStates: [AiState.UserValidated, null],
    },
  };

  const generatedInsightsOptions = {
    query: FetchDocLinkedDocsDocument,
    variables: {
      id: doc.id,
      aiStates: [AiState.AiCreated],
    },
  };

  const { insights } = useDocInsights(doc.id, {
    aiStates: [AiState.AiCreated],
  });

  const { docTypes } = useGetDocTypes();

  // Insights grouped by parent id
  const groups = groupBy(insights, insight => insight?.doc?.parent?.doctype.id ?? insight?.doc?.suggestedParentDoctype?.id);

  const sortedDocTypeIds = useMemo(() => {
    // Ids of doctypes parent of insight
    const docTypeIds = Object.entries(docTypes)
      .filter(([, d]) => isParentOfInsight(d))
      .map(([id]) => id)
      // Undefined is the group of insights without parent
      .concat('undefined');
    // Empty groups are sorted last
    return orderBy(docTypeIds, id => !!groups[id]?.length, 'desc')
      // The first group is the one with the first insight in the list
      .sort(id => (id === Object.keys(groups)[0] ? -1 : 1));
  }, [docTypes, groups]);

  const [selectedDocIds, setSelectedDocIds] = useState(insights.map(insight => insight?.doc?.id).filter(isPresent));
  const [hoverDocId, setHoverDocId] = useState<string | null>(null);

  const selectAll = () => setSelectedDocIds(insights.map(insight => insight?.doc?.id).filter(isPresent));

  const unselectAll = () => setSelectedDocIds([]);

  const hasUnselected = selectedDocIds.length < insights.length;

  const selectDoc = (id: string) => {
    setSelectedDocIds(prev => (selectedDocIds.includes(id)
      ? prev.filter(item => item !== id)
      : [...prev, id]));
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.SelectDoc]: () => {
        if (hoverDocId === null) return;
        selectDoc(hoverDocId);
      },
      [ShortcutBoard.SelectAllDocs]: selectAll,
    },
    shortcuts: [ShortcutBoard.SelectDoc, ShortcutBoard.SelectAllDocs],
    disableOnLayers: [Layer.Dropdown],
  });

  const [
    isDiscardModalOpen, {
      setTrueCallback: openDiscardModal,
      setFalseCallback: closeDiscardModal,
    },
  ] = useOptimizedBooleanState(false);

  // There are two display modes, depending on whether all or none of the insights initially have a parent.
  // If insights have a parent, we display the insights grouped by parent, and parent can be changed to another parent of the same doctype.
  // If insights don't have a parent, we display the insights ungrouped, and a parent can be added to each insight.
  const groupNames = Object.keys(groups);
  const displayMode = useRef(groupNames.length === 1 && groupNames[0] === 'undefined' ? 'ungrouped' : 'grouped');

  // Each doc parent should always be first for the doc creation in the doc search.
  const getPossibleParentDoctypeIds = (doctypeId: string) => [...new Set([doctypeId, ...parentDocTypeIds])];

  return (
    <StyledPortalModal
      hide={hide}
      layer={Layer.Modal}
    >
      <ListContainer>
        <Header>
          <AiIcon hasGradient />
          {getTitle(doc.docTargetsAiCount)}
        </Header>

        <List>
          {sortedDocTypeIds.map(docTypeId => {
            const docType = docTypes[docTypeId];
            if (!docType && docTypeId !== 'undefined') return null;
            const groupItems = groups[docTypeId] ?? [];
            if (groupItems.length === 0) return null;
            return (
              <Group key={docTypeId}>
                {displayMode.current === 'grouped' && (
                  <GroupHeader>
                    <DocTypeIcon doctype={docType} size={14} />
                    <GroupTitle>{docType?.name ?? 'No parent'}</GroupTitle>
                    <DoctypeInfoIcon doctypeId={docType?.id} />
                    {groupItems.length === 0 && (
                      <GroupInfo>There is no insight to validate</GroupInfo>
                    )}
                  </GroupHeader>
                )}
                {groupItems.map((insight, index) => {
                  const insightDoc = insight?.doc;
                  if (!insightDoc) return null;
                  return (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key -- List is static
                      key={index}
                      $selected={selectedDocIds.includes(insightDoc.id)}
                      onClick={() => selectDoc(insightDoc.id)}
                      onMouseEnter={() => setHoverDocId(insightDoc.id)}
                      onMouseLeave={() => setHoverDocId(null)}
                    >
                      <Tooltip
                        content="Unselected AI-generated insights will be discarded"
                        withPortal
                        placement="top"
                      >
                        <CheckboxInput
                          id={`quote-${insightDoc.id}`}
                          checked={selectedDocIds.includes(insightDoc.id)}
                          onClick={e => e.stopPropagation()}
                          onChange={() => {}}
                        />
                      </Tooltip>
                      <SuggestionInsightCard
                        doc={insightDoc}
                        parent={(
                          <StyledDocParentDropdown
                            docId={insightDoc.id}
                            docTypeId={insightDoc.doctype.id}
                            showStatus
                            showParentTitle
                            showEmoji
                            layer={Layer.DropdownModalZ1}
                            possibleDoctypeIds={getPossibleParentDoctypeIds(docTypeId)}
                            searchVariables={{
                              doctypeIds: getPossibleParentDoctypeIds(docTypeId),
                              childDoctypeId: null,
                            }}
                            showNoneOption
                            minimal
                            context="doc-item"
                            isLinkDisabled
                            style={{ overflow: 'hidden' }}
                            suggestedParentName={insightDoc.suggestedParentName}
                          />
                        )}
                      />
                    </ListItem>
                  );
                })}
              </Group>
            );
          })}
        </List>

        <Footer>
          <div>
            <SelectButton
              onClick={openDiscardModal}
            >
              Discard all
            </SelectButton>

            <SelectButton
              onClick={hasUnselected ? selectAll : unselectAll}
            >
              {hasUnselected ? 'Select all' : 'Unselect all'}
            </SelectButton>
          </div>

          <SubmitButton
            $disabled={selectedDocIds.length === 0}
            size="M"
            full
            onClick={async () => {
              if (!doc) return;

              const idsToDiscard = insights
                .map(insight => insight?.doc?.id)
                .filter(isPresent)
                .filter(id => !selectedDocIds.includes(id));

              // // Validate selected insights
              for (const id of selectedDocIds) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                updateDocAiState(id, AiState.UserValidated);
              }

              // Discard unselected insights
              // TODO: use bulk update mutation when available
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              Promise.all(idsToDiscard.map(removeDoc));

              // Close modal and open 2 toasters
              hide();
              if (idsToDiscard.length > 0) {
                addToaster({
                  title: 'Successfully deleted',
                  message: getDiscardToasterMessage(idsToDiscard.length),
                });
              }
              addToaster({
                title: 'Successfully validated',
                message: getValidateToasterMessage(selectedDocIds.length),
              });

              // Update User-verified insights in cache
              const generatedInsightsQuery = cache.readQuery(generatedInsightsOptions);
              cache.writeQuery({
                ...validatedInsightsOptions,
                data: produce(cache.readQuery(validatedInsightsOptions), draft => {
                  const node = draft?.node;
                  if (node?.__typename !== 'Doc' || generatedInsightsQuery?.node?.__typename !== 'Doc') return;
                  const newEdges = generatedInsightsQuery?.node.docTargets.edges.filter(edge => {
                    const id = edge.node?.doc?.id;
                    return id && selectedDocIds.includes(id);
                  });

                  node.docTargets.edges = [
                    ...newEdges,
                    ...node.docTargets.edges,
                  ];
                }),
              });

              // Update AI-generated insights in cache
              cache.writeQuery({
                ...generatedInsightsOptions,
                data: produce(cache.readQuery(generatedInsightsOptions), draft => {
                  const node = draft?.node;
                  if (node?.__typename !== 'Doc') return;
                  node.docTargets.edges = [];
                }),
              });

              // Update AI states in cache
              cache.writeQuery({
                ...validatedInsightsOptions,
                data: produce(cache.readQuery(validatedInsightsOptions), draft => {
                  const node = draft?.node;
                  if (node?.__typename !== 'Doc') return;
                  for (const edge of node.docTargets.edges) {
                    if (edge.node?.doc?.aiState) edge.node.doc.aiState = AiState.UserValidated;
                  }
                }),
              });

              // Update counters in cache
              cache.modify({
                id: cache.identify(doc),
                fields: {
                  docTargetsCount: (count, { storeFieldName }) => {
                    const variables = parseStoreFieldName(storeFieldName);
                    if (variables?.aiState === AiState.AiCreated) return 0;
                    return count;
                  },
                },
              });
            }}
          >
            {getSubmitLabel(selectedDocIds.length)}
          </SubmitButton>
        </Footer>
      </ListContainer>

      <CloseButton onClick={hide}>
        <CloseIcon />
      </CloseButton>

      {isDiscardModalOpen && (
        <DialogModal
          hide={closeDiscardModal}
          title={getModalTitle(insights.length)}
          info={getModalInfo(insights.length)}
          confirmLabel="Discard"
          autoHide={false}
          onConfirm={() => {
            if (!doc) return;

            // Close modals
            closeDiscardModal();
            hide();

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            Promise.all(insights.map(insight => {
              const id = insight?.doc?.id;
              if (!id) return null;
              return removeDoc(id);
            }));

            // Update AI-generated insights in cache
            cache.writeQuery({
              ...generatedInsightsOptions,
              data: produce(cache.readQuery(generatedInsightsOptions), draft => {
                const node = draft?.node;
                if (node?.__typename !== 'Doc') return;
                node.docTargets.edges = [];
              }),
            });

            // Update counter in cache
            cache.modify({
              id: cache.identify(doc),
              fields: {
                docTargetsCount: (count, { storeFieldName }) => {
                  const variables = parseStoreFieldName(storeFieldName);
                  if (variables?.aiState === AiState.AiCreated) return 0;
                  return count;
                },
              },
            });

            addToaster({
              title: 'Successfully deleted',
              message: getDiscardToasterMessage(insights.length),
            });
          }}
        />
      )}
    </StyledPortalModal>
  );
};

const getTitle = (count?: number) => {
  if (!count) return null;
  const name = count > 1 ? 'insights' : 'insight';
  return `Validate ${count} AI-generated ${name}`;
};

const getSubmitLabel = (count: number) => {
  const name = count > 1 ? 'insights' : 'insight';
  return `Validate ${count} ${name}`;
};

const getModalTitle = (count: number) => {
  if (count === 1) return 'Discard 1 AI-generated insight';
  return `Discard ${count} AI-generated insights`;
};

const getModalInfo = (count: number) => {
  if (count === 1) return 'Are you sure you want to discard this AI-generated insight?';
  return `Are you sure you want to discard these ${count} AI-generated insights?`;
};

const getDiscardToasterMessage = (count: number) => {
  if (count === 1) return 'Your AI-generated insight was successfully deleted';
  return `Your ${count} AI-generated insights were successfully deleted`;
};

const getValidateToasterMessage = (count: number) => {
  if (count === 1) return 'Your AI-generated insight was successfully validated';
  return `Your ${count} docs AI-generated insight were successfully validated`;
};
