import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { CloseIcon, AiIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useExtractQuotesContext } from 'src/contexts/extractQuotesContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { removeAllQuotesFromCache } from 'src/hooks/api/cache/cacheQuotes';
import { useDiscardQuote } from 'src/hooks/api/mutations/quotes/useDiscardQuote';
import { useVerifyQuote } from 'src/hooks/api/mutations/quotes/useVerifyQuote';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useVerticalOverflow } from 'src/hooks/useVerticalOverflow';
import { closeVerifyQuotes } from 'src/reactives/docRightPanel.reactive';
import { useGetDocTypes, useInsightParentDocTypeIds } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';
import { isParentOfInsight } from 'src/utils/docType.util';

import { AnimatedListItem, QuoteListItem } from './QuoteListItem';
import {
  CloseButton, Header, ListContainer, List, Footer, SubmitButton, SelectButton,
  GroupHeader, GroupTitle, GroupInfo,
} from './VerifyQuotes.styles';
import { VerifyQuotesContainer } from './VerifyQuotesContainer';

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

type Props = {
  doc: DocBaseFragment;
};

export const VerifyQuotes = ({ doc }: Props) => {
  if (!quotesEnabled) return null;
  return (
    <VerifyQuotesContainer>
      <VerifyQuotesOpen doc={doc} />
    </VerifyQuotesContainer>
  );
};

const VerifyQuotesOpen = ({ doc }: Props) => {
  const quotes = useExtractQuotesContext(ctx => ctx.quotes);
  const setVerified = useExtractQuotesContext(ctx => ctx.setVerified);
  const { discard } = useDiscardQuote();
  const { verify } = useVerifyQuote();
  const { docTypes } = useGetDocTypes();

  // Quotes grouped by parent doctype id or suggested parent doctype id
  const parentDocTypeIds = useInsightParentDocTypeIds();
  const groups = groupBy(quotes, quote => quote?.parent?.doctype.id ?? quote?.suggestedParentDoctype?.id ?? parentDocTypeIds[0]);

  const sortedDocTypeIds = useMemo(() => {
    // Ids of doctypes parent of quotes
    const docTypeIds = Object.entries(docTypes)
      .filter(([, d]) => isParentOfInsight(d))
      .map(([id]) => id);
    // Empty groups are sorted last
    return orderBy(docTypeIds, id => !!groups[id]?.length, 'desc')
      // The first group is the one with the first quote in the list
      .sort(id => (id === Object.keys(groups)[0] ? -1 : 1));
  // Intentionally no deps to avoid reordering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedDocIds, setSelectedDocIds] = useState(quotes.map(quote => quote?.id).filter(isPresent));
  const [hoverDocId, setHoverDocId] = useState<string | null>(null);

  const selectAll = () => setSelectedDocIds(quotes.map(quote => quote?.id).filter(isPresent));

  const unselectAll = () => setSelectedDocIds([]);

  const hasUnselected = selectedDocIds.length < quotes.length;

  const selectDoc = (id: string) => {
    setSelectedDocIds(prev => (selectedDocIds.includes(id)
      ? prev.filter(item => item !== id)
      : [...prev, id]));
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.SelectDoc]: () => {
        if (hoverDocId === null) return;
        selectDoc(hoverDocId);
      },
      [ShortcutBoard.SelectAllDocs]: selectAll,
    },
    shortcuts: [ShortcutBoard.SelectDoc, ShortcutBoard.SelectAllDocs],
    disableOnLayers: [Layer.Dropdown],
  });

  const [
    isDiscardModalOpen, {
      setTrueCallback: openDiscardModal,
      setFalseCallback: closeDiscardModal,
    },
  ] = useOptimizedBooleanState(false);

  // Used to display borders when the list is scrollable
  const {
    ref: listRef,
    overflowTop,
    overflowBottom,
  } = useVerticalOverflow();

  // Reset the reactive, as quotes section should be closed when the doc opens
  useEffect(() => {
    return () => {
      closeVerifyQuotes();
    };
  }, []);

  // Update selectedDocIds when quotes change (e.g. when discarding quotes in the summary)
  useEffect(() => {
    setSelectedDocIds(state => state.filter(id => quotes.map(quote => quote?.id).includes(id)));
  }, [quotes]);

  return (
    <>
      <ListContainer>
        <Header $withBorder={overflowTop}>
          <AiIcon hasGradient />
          {getTitle(quotes.length)}
        </Header>

        <List ref={listRef}>
          {sortedDocTypeIds.map(docTypeId => {
            const docType = docTypes[docTypeId];
            if (!docType) return null;
            const groupItems = groups[docTypeId] ?? [];
            return (
              <div key={docTypeId}>
                <GroupHeader>
                  <DocTypeIcon doctype={docType} size={14} />
                  <GroupTitle>{docType?.name}</GroupTitle>
                </GroupHeader>
                <AnimatePresence initial={false}>
                  {groupItems.length === 0 && (
                    <AnimatedListItem key={docTypeId}>
                      <GroupInfo>We didn’t find any quote for this category</GroupInfo>
                    </AnimatedListItem>
                  )}
                  {groupItems.map(quote => (
                    <QuoteListItem
                      key={quote.id}
                      quote={quote}
                      selected={selectedDocIds.includes(quote.id)}
                      onClick={() => selectDoc(quote.id)}
                      onMouseEnter={() => setHoverDocId(quote.id)}
                      onMouseLeave={() => setHoverDocId(null)}
                    />
                  ))}
                </AnimatePresence>
              </div>
            );
          })}
        </List>

        <Footer $withBorder={overflowBottom}>
          <div>
            <SelectButton
              onClick={openDiscardModal}
            >
              Discard all
            </SelectButton>

            <SelectButton
              onClick={hasUnselected ? selectAll : unselectAll}
            >
              {hasUnselected ? 'Select all' : 'Unselect all'}
            </SelectButton>
          </div>

          <SubmitButton
            $disabled={selectedDocIds.length === 0}
            size="M"
            full
            onClick={async () => {
              if (!doc) return;

              closeVerifyQuotes();
              setVerified();

              for (const quoteId of selectedDocIds) {
                verify(doc.id, quoteId);
              }

              const idsToDiscard = quotes
                .map(quote => quote?.id)
                .filter(isPresent)
                .filter(id => !selectedDocIds.includes(id));

              removeAllQuotesFromCache(doc.id);

              for (const quoteId of idsToDiscard) {
                discard(doc.id, quoteId);
              }
            }}
          >
            {getSubmitLabel(selectedDocIds.length)}
          </SubmitButton>
        </Footer>
      </ListContainer>

      <CloseButton onClick={closeVerifyQuotes}>
        <CloseIcon />
      </CloseButton>

      {isDiscardModalOpen && (
        <DialogModal
          hide={closeDiscardModal}
          title={getModalTitle(quotes.length)}
          info={getModalInfo(quotes.length)}
          confirmLabel="Discard"
          autoHide={false}
          onConfirm={() => {
            if (!doc) return;

            closeDiscardModal();
            closeVerifyQuotes();
            removeAllQuotesFromCache(doc.id);

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            for (const quote of quotes) {
              discard(doc.id, quote.id);
            }
          }}
        />
      )}
    </>
  );
};

const getTitle = (count?: number) => {
  if (!count) return null;
  const name = count > 1 ? 'quotes' : 'quote';
  return `We found ${count} ${name}`;
};

const getSubmitLabel = (count: number) => {
  const name = count > 1 ? 'quotes' : 'quote';
  return `Verify ${count} ${name}`;
};

const getModalTitle = (count: number) => {
  if (count === 1) return 'Discard 1 quote';
  return `Discard ${count} quotes`;
};

const getModalInfo = (count: number) => {
  if (count === 1) return 'Are you sure you want to discard this quote?';
  return `Are you sure you want to discard these ${count} quotes?`;
};
