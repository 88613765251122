import { Badge } from '@cycle-app/ui';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useThreadsPanel } from 'src/reactives/comments.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { getCommentsCount } from 'src/utils/comments.utils';

export const CommentsCount = ({ doc }: { doc: FullDocWithPublicId }) => {
  const isMobile = useIsMobile();
  const { section } = useThreadsPanel();

  // ctx.doc.threadsCount is the number of open threads in the doc
  // ctx.threadsCount is the number of threads for the selected section, and is obtained from the threads query result
  const threadsCount = useDocPanelContext(ctx => (section !== 'open' ? ctx.threadsCount : ctx.doc?.threadsCount));

  const commentsCount = isMobile ? getCommentsCount(doc) : threadsCount;
  if (commentsCount === undefined) return null;
  return <Badge>{commentsCount}</Badge>;
};
