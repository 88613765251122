/* eslint-disable no-continue */
import { DocTargetFragment } from '@cycle-app/graphql-codegen';

import { QUOTES_GAP } from './QuoteCards.styles';

type QuotesData = Record<string, { blockId?: string; quoteId?: string; height: number }>;

/**
 * Calculate positions of quote cards with blockId based on the position of marks in the editor
 * @param containerElement - container element for quote cards
 * @param quotes - quotes to be placed in the quote cards
 */
export const getQuotePositions = (containerElement: HTMLElement, quotes: DocTargetFragment[]) => {
  // Cards without blockId are positioned at the top
  const quotesOffset = containerElement.getBoundingClientRect().height;

  // Get the height of the cards with blockId
  const quotesWithBlockIdElements = containerElement.querySelectorAll('[data-quote-id]:not([data-block-id="null"])');
  const quotesData = Array.from(quotesWithBlockIdElements).reduce<QuotesData>((acc, el) => {
    const blockId = el.getAttribute('data-block-id');
    const quoteId = el.getAttribute('data-quote-id');
    const { height } = el.getBoundingClientRect();
    if (blockId && quoteId) {
      acc[quoteId] = {
        blockId,
        height,
      };
      acc[blockId] = {
        quoteId,
        height,
      };
    }
    return acc;
  }, {});

  // Get the position of marks in the editor
  const marksContainer = document.querySelector('#marks-container');
  const isEmpty = marksContainer?.querySelector('.is-editor-empty');
  if (!marksContainer || isEmpty) return null;

  const marksContainerTop = marksContainer.getBoundingClientRect().top;
  const marksElements = marksContainer.querySelectorAll('[data-mark-id]');
  const marksPositions = Array.from(marksElements).reduce<Record<string, number>>((acc, el) => {
    const blockId = el.getAttribute('data-mark-id');
    if (!blockId || !!acc[blockId]) return acc;
    const top = el.getBoundingClientRect().top - marksContainerTop;
    if (top) acc[blockId] = top;
    return acc;
  }, {});

  // Calculate positions of the cards based on the previous card and the position of marks in the editor
  const entries: [string, number][] = []; // [quoteId, position]

  // Cards with blockId and without mark are placed at the top, below the cards without blockId
  for (const quote of quotes) {
    if (!quote.blockId) continue;
    const markPosition = marksPositions[quote.blockId];
    if (markPosition) continue;
    const prevPosition = entries.at(-1)?.[1] ?? quotesOffset;
    const prevQuoteId = entries.at(-1)?.[0];
    const prevHeight = !prevQuoteId ? 0 : quotesData[prevQuoteId]?.height ?? 0;
    entries.push([quote.id, prevPosition + prevHeight + QUOTES_GAP]);
  }

  // Positions of cards with blockId and marks present in the editor
  for (const [blockId, markPosition] of Object.entries(marksPositions)) {
    const quoteId = quotesData[blockId]?.quoteId;
    if (!quoteId) continue;

    const prevPosition = entries.at(-1)?.[1] ?? quotesOffset;
    const prevQuoteId = entries.at(-1)?.[0];
    const prevHeight = !prevQuoteId ? 0 : quotesData[prevQuoteId]?.height ?? 0;

    entries.push([quoteId, Math.max(markPosition, prevPosition + prevHeight + QUOTES_GAP)]);
  }

  return Object.fromEntries(entries);
};
