import { DocTargetResultFragment } from '@cycle-app/graphql-codegen';
import { CheckboxInput, Tooltip } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useOptimizedBooleanState } from 'src/hooks';
import { useBulkChangeParent } from 'src/hooks/api/mutations/updateDocHooks';
import { useDiscardRecommendations } from 'src/hooks/api/mutations/useDiscardRecommendations';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';
import { insightName } from 'src/utils/doc.util';

import DialogModal from '../DialogModal/DialogModal';
import { SuggestionInsightCard } from './SuggestionInsightCard';
import {
  CloseButton, StyledPortalModal, ListContainer, Title, List, ListItem,
  Footer, SubmitButton, SelectButton,
} from './SuggestionsModal.styles';

export const SuggestionsModal = ({
  hide, docs,
}: {
  hide: VoidFunction;
  docs: DocTargetResultFragment[];
}) => {
  const docId = useDocPanelContext(ctx => ctx.docId);
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>([]);
  const [hoverDocId, setHoverDocId] = useState<string | null>(null);

  const selectAll = () => setSelectedDocIds(docs.map(item => item.id));

  const unselectAll = () => setSelectedDocIds([]);

  const hasUnselected = selectedDocIds.length < docs.length;

  const selectDoc = (id: string) => {
    setSelectedDocIds(prev => (selectedDocIds.includes(id)
      ? prev.filter(item => item !== id)
      : [...prev, id]));
  };

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.SelectDoc]: () => {
        if (hoverDocId === null) return;
        selectDoc(hoverDocId);
      },
      [ShortcutBoard.SelectAllDocs]: selectAll,
    },
    shortcuts: [ShortcutBoard.SelectDoc, ShortcutBoard.SelectAllDocs],
    disableOnLayers: [Layer.Dropdown],
  });

  const bulkChangeParent = useBulkChangeParent();

  const { discardRecommendations } = useDiscardRecommendations(docId);

  const [
    isDiscardModalOpen, {
      setTrueCallback: openDiscardModal,
      setFalseCallback: closeDiscardModal,
    },
  ] = useOptimizedBooleanState(false);

  return (
    <StyledPortalModal
      hide={hide}
      layer={Layer.Modal}
    >
      <ListContainer>
        <Title>
          {getTitle(docs?.length)}
        </Title>

        <List>
          {docs?.map(doc => (
            <ListItem
              key={doc.id}
              $selected={selectedDocIds.includes(doc.id)}
              onClick={() => selectDoc(doc.id)}
              onMouseEnter={() => setHoverDocId(doc.id)}
              onMouseLeave={() => setHoverDocId(null)}
            >
              <Tooltip
                content="Unselected recommendations will be discarded"
                withPortal
                placement="top"
              >
                <CheckboxInput
                  id={`quote-${doc.id}`}
                  checked={selectedDocIds.includes(doc.id)}
                  onClick={e => e.stopPropagation()}
                  onChange={() => {}}
                />
              </Tooltip>
              <SuggestionInsightCard
                doc={doc}
                showSource
                showAssignee
                showCustomer
              />
            </ListItem>
          ))}
        </List>

        <Footer>
          <div>
            <SelectButton
              onClick={openDiscardModal}
            >
              Discard all
            </SelectButton>

            <SelectButton
              onClick={hasUnselected ? selectAll : unselectAll}
            >
              {hasUnselected ? 'Select all' : 'Unselect all'}
            </SelectButton>
          </div>

          <SubmitButton
            $disabled={selectedDocIds.length === 0}
            size="M"
            full
            onClick={async () => {
              hide();

              // Doc discard must be done before bulk change parent
              const docIdsToDiscard = docs
                .map(doc => doc.id)
                .filter(id => !selectedDocIds.includes(id));

              if (docIdsToDiscard.length > 0) {
                await discardRecommendations({
                  docId,
                  docRecommendationIds: docIdsToDiscard,
                });
              }

              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              bulkChangeParent({
                parentId: docId,
                docIds: selectedDocIds,
              });
            }}
          >
            {getSubmitLabel(selectedDocIds.length || docs.length)}
          </SubmitButton>
        </Footer>
      </ListContainer>

      <CloseButton onClick={hide}>
        <CloseIcon />
      </CloseButton>

      {isDiscardModalOpen && (
        <DialogModal
          hide={closeDiscardModal}
          title={getModalTitle(docs.length)}
          info={getModalInfo(docs.length)}
          confirmLabel="Discard"
          onConfirm={() => {
            hide();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            discardRecommendations({
              docId,
              docRecommendationIds: docs.map(item => item.id),
            });
          }}
        />
      )}
    </StyledPortalModal>
  );
};

const getTitle = (count?: number) => {
  if (!count) return null;
  return `We found ${count} recommended ${insightName({ plural: count > 1 })} to link`;
};

const getSubmitLabel = (count: number) => {
  return `Link ${count} ${insightName({ plural: count > 1 })}`;
};

const getModalTitle = (count: number) => {
  if (count === 1) return `Discard 1 ${insightName()} recommendation`;
  return `Discard ${count} ${insightName({ plural: true })} recommendations`;
};

const getModalInfo = (count: number) => {
  if (count === 1) return `Are you sure you want to discard this ${insightName()} recommendation? It will no longer appear in your suggestions here.`;
  // eslint-disable-next-line max-len
  return `Are you sure you want to discard these ${count} ${insightName({ plural: true })} recommendations? They will no longer appear in your suggestions here.`;
};
