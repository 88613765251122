import { useState } from 'react';

import { BoardStarButton } from 'src/components/BoardHeader/BoardStarButton';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ViewContextMenu } from 'src/components/ViewContextMenu';
import { useViewOptions, ViewsPanel } from 'src/components/ViewsPanel';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { PageId, usePageId } from 'src/hooks/usePageId';
import { useBoardId, useDashboardId } from 'src/hooks/usePathParams';
import { usePrevious } from 'src/hooks/usePrevious';
import { useGetPermission } from 'src/reactives';
import { SectionParent } from 'src/utils/section.utils';

import { DoubleCaret, ViewButton, ViewLabel } from './ViewBreadcrumb.styles';

export const ViewDropdown = ({ sectionParent }: { sectionParent: SectionParent }) => {
  const pageId = usePageId();
  const currentBoardId = useBoardId();
  const currentDashboardId = useDashboardId();
  const previousBoardId = usePrevious(currentBoardId);
  const boardId = currentBoardId ?? previousBoardId;
  const { boards } = useSectionBoards(sectionParent);
  const { isPermissionInit } = useGetPermission();
  const [contextMenu, setContextMenu] = useState<{ clientX: number; clientY: number } | null>(null);
  const options = useViewOptions(sectionParent);
  // Sort by specificity, in instance: `analyses/id` should be before `analyses`
  const selectedOption = options.sort((a, b) => b.value.length - a.value.length).find(o => (
    o.value === pageId ||
    (boardId && o.value.includes(boardId)) ||
    (currentDashboardId && o.value.includes(currentDashboardId))
  ));

  if (!selectedOption) return null;

  return (
    <>
      <ToggleDropdown
        placement="bottom-start"
        withWrapper={false}
        content={props => (
          <ViewsPanel
            sectionParent={sectionParent}
            hide={props.hide}
          />
        )}
        button={props => (
          <ViewButton
            {...props}
            iconEnd={<DoubleCaret />}
            variant="light"
            iconStart={selectedOption.icon}
            onContextMenu={e => {
              if (!isPermissionInit || !boardId || pageId === PageId.Analyses) return;
              setContextMenu({
                clientX: e.clientX,
                clientY: e.clientY,
              });
              e.preventDefault();
            }}
          >
            <ViewLabel>
              {selectedOption.renderLabel?.('') ?? selectedOption.label}
            </ViewLabel>
          </ViewButton>
        )}
      />

      {boardId && pageId !== PageId.Analyses && <BoardStarButton boardId={boardId} />}

      {boardId && contextMenu && (
        <ViewContextMenu
          viewId={boardId}
          clientX={contextMenu.clientX}
          clientY={contextMenu.clientY}
          hide={() => setContextMenu(null)}
          isDeleteDisabled={boards.length === 1}
        />
      )}
    </>
  );
};
