import { CommentAltIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Placement } from 'tippy.js';

import DocAction from 'src/components/DocAction/DocAction';
import useDocComments from 'src/hooks/api/queries/useDocComments';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getThreadsPanel, setMainThread, setThreadsPanel, useMainThread } from 'src/reactives/comments.reactive';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { preventClick } from 'src/utils/mouse.util';

import { DocChat } from './DocChat';
import { StyledDropdownLayer, StyledPortalModal } from './DocComments.styles';

interface Props {
  docId: string;
  dropdownPlacement?: Placement;
  tooltipPlacement?: Placement;
  nbComments: number;
  maxHeight?: string;
  showLabel?: boolean;
  size?: 'S' | 'M' | 'L';
  buttonIcon?: ReactNode;
  context?: 'doc-item';
  className?: string;
  threadId?: string;
}

export const DocItemComments: FC<React.PropsWithChildren<Props>> = (props) => {
  const [isDropdownVisible, {
    toggleCallback: onToggleDropdown,
    setFalseCallback: onHideDropdown,
  // eslint-disable-next-line react/destructuring-assignment
  }] = useOptimizedBooleanState(false);

  return (
    <DocComments
      {...props}
      isDropdownVisible={isDropdownVisible}
      onToggleDropdown={onToggleDropdown}
      onHideDropdown={onHideDropdown}
    />
  );
};

export const DocPanelComments: FC<React.PropsWithChildren<Props>> = (props) => {
  const { isVisible } = useMainThread();
  return (
    <DocComments
      {...props}
      isDropdownVisible={isVisible}
      onToggleDropdown={() => {
        setThreadsPanel({
          openBlockId: props.threadId,
          hoverBlockId: undefined,
        });
        setMainThread({ isVisible: !isVisible });
      }}
      onHideDropdown={() => setMainThread({ isVisible: false })}
    />
  );
};

type DocCommentsProps = Props & {
  isDropdownVisible: boolean;
  onHideDropdown: VoidFunction;
  onToggleDropdown: VoidFunction;
};

const DocComments: FC<React.PropsWithChildren<DocCommentsProps>> = ({
  docId,
  dropdownPlacement = 'bottom',
  tooltipPlacement,
  nbComments,
  maxHeight = '50vh',
  showLabel = false,
  size,
  buttonIcon = <CommentAltIcon size={16} />,
  context,
  isDropdownVisible,
  onHideDropdown,
  onToggleDropdown,
  className,
}) => {
  const isMobile = useIsMobile();
  const [skip, setSkip] = useState(true);

  const {
    comments, loading, previousData,
  } = useDocComments({
    docId,
    blockId: null,
    skip,
    resolved: false,
  });

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    if (skip && isDropdownVisible) {
      setSkip(false);
    }
  }, [isDropdownVisible, skip]);

  const hide = () => {
    onHideDropdown();
    setThreadsPanel({ openBlockId: undefined });
  };

  const content = (
    <DocChat
      docId={docId}
      blockId={null}
      comments={comments}
      maxHeight={maxHeight}
      isCommentsLoading={!previousData && loading}
      commentsCount={nbComments}
      showHeader={context !== 'doc-item'}
      hide={hide}
      isResolved={false}
      autoFocus
    />
  );

  const button = (
    <DocAction
      onClick={() => {
        // The right panel should show the open threads when opening the main thread of comments
        if (getThreadsPanel().section === 'closed') {
          setThreadsPanel({
            section: 'open',
            openBlockId: null,
          });
        }
        onToggleDropdown();
      }}
      tooltipPlacement={tooltipPlacement}
      buttonIcon={buttonIcon}
      label={(() => {
        if (context !== 'doc-item' && nbComments === 0) return undefined;
        return nbComments > 0 ? 'Comments' : 'Add comment';
      })()}
      forceFocus={isDropdownVisible}
      size={size}
      counter={showLabel ? undefined : nbComments}
      formatCounter={c => (c > 99 ? '99+' : c)}
      context={context}
    >
      {showLabel && (nbComments > 0 ? nbComments : 'Comment')}
    </DocAction>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={preventClick} className={className}>
      {isMobile ? (
        <>
          {button}
          {isDropdownVisible && (
            <StyledPortalModal
              animate={false}
              isFullOnMobile
              hide={hide}
            >
              {content}
            </StyledPortalModal>
          )}
        </>
      ) : (
        <StyledDropdownLayer
          visible={isDropdownVisible}
          hide={hide}
          placement={dropdownPlacement}
          content={content}
        >
          {button}
        </StyledDropdownLayer>
      )}
    </div>
  );
};

export default DocComments;
