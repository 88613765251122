import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useMemo, ReactNode } from 'react';

import { DocSearchDropdownProps } from 'src/components/DocSearchDropdown/DocSearchDropdown';
import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { InsightCreateModal, InsightCreateModalProps } from 'src/components/InsightCreateModal';
import { useOptimizedBooleanState, useDocV2, useDocTypeInsight } from 'src/hooks';
import { Layer } from 'src/types/layers.types';
import { getDocTypeNames } from 'src/utils/docType.util';
import { requestInsightCreate } from 'src/utils/requestInsightCreate.utils';

type InsightCreateProps =
  Pick<DocSearchDropdownProps, 'searchVariables'> &
  Pick<InsightCreateModalProps, 'onInsightCreated' | 'linearId' | 'linearUrl' | 'linearDisabled' | 'dropdownProps'> & {
    feedbackDoc: DocBaseFragment;
    isDisabled?: boolean;
    dropdownLayer?: Layer;
    formLayer?: Layer;
    button: (props: {
      onClick: VoidFunction;
      isFocus: boolean;
      isDisabled: boolean;
    }) => ReactNode;
  };

export const InsightCreate = ({
  feedbackDoc,
  isDisabled = false,
  dropdownLayer,
  formLayer,
  button,
  onInsightCreated,
  searchVariables,
  linearId,
  linearUrl,
  linearDisabled,
  dropdownProps,
}: InsightCreateProps) => {
  const [isVisible, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const {
    doc: parentDoc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);
  const { insight } = useDocTypeInsight();
  const possibleDocTypes = useMemo(() => nodeToArray(insight?.parents), [insight?.parents]);
  return (
    <InsightCreateModal
      isOpen={!!parentDoc?.id}
      isLoading={isParentLoading}
      hide={() => setInsightDocParentId('')}
      parentDoc={parentDoc}
      feedbackDoc={feedbackDoc}
      dropdownLayer={dropdownLayer}
      formLayer={formLayer}
      onInsightCreated={onInsightCreated}
      linearId={linearId}
      linearUrl={linearUrl}
      linearDisabled={linearDisabled}
      dropdownProps={dropdownProps}
    >
      <InsightDocSearchDropdown
        hideSearchDropdown={() => setFalseCallback()}
        isSearchDropdownVisible={isVisible}
        onAdd={(docId) => {
          setInsightDocParentId(docId);
          setFalseCallback();
        }}
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDocTypes}
        placeholder={getDocTypeNames(possibleDocTypes, {
          prefix: 'Search',
          suffix: '',
        })}
        dropdownProps={{
          placement: 'bottom',
          layer: Layer.DropdownModalZ4,
        }}
        searchVariables={searchVariables}
      >
        {button({
          isFocus: isVisible || !!insightDocParentId,
          isDisabled,
          onClick: () => {
            if (isDisabled) return;
            requestInsightCreate(setTrueCallback);
          },
        })}
      </InsightDocSearchDropdown>
    </InsightCreateModal>
  );
};
