import { typo, ButtonV2 as Button, boxShadowZ1, ShyScrollbarCss } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 340px;
`;

export const PopoverContent = styled.div`
  ${boxShadowZ1}
  outline: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : '#0000000F')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px #00000033;
  background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
`;

export const ButtonsList = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  > * + * {
    :before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      left: 0;
      background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
    }
  }
`;

export const ButtonItem = styled(Button).attrs({ color: 'gray' })`
  background: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
  :hover {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey100)};
  }
  :active {
    background: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  }
  ${typo.caption500}
  color: ${p => p.theme.colors.text.primary};
  border: 0;
  border-radius: 0;
  gap: 6px;
  outline: none;
`;

export const QuoteContent = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
  max-height: 180px;
  ${ShyScrollbarCss}

  .ProseMirror {
    width: fit-content;
    max-width: 100%;
    padding: 0 4px !important;
    background: ${p => (p.theme.isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)')};
    border-radius: 4px;
  }
`;

export const FeatureContent = styled(PopoverContent)`
  padding: 8px 12px 12px 12px;
`;
