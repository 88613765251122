import { UpdateDashboardDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from '../../contexts/workspaceContext';
import useSafeMutation from '../useSafeMutation';

export const useUpdateDashboard = () => {
  const [mutation, { loading: isLoading }] = useSafeMutation(UpdateDashboardDocument);
  const productId = useWorkspaceContext(ctx => ctx.productId);

  const updateDashboard = ({
    dashboardId, datasetId, secondDashboardId,
  }: { dashboardId: string; datasetId: string; secondDashboardId: string }) => mutation({
    variables: {
      productId,
      // Values can be reset with null
      dashboardId: dashboardId || null,
      datasetId: datasetId || null,
      secondDashboardId: secondDashboardId || null,
    },
    update: (cache, { data }) => {
      if (data?.updateLuzmoVariables) {
        cache.modify({
          id: productId,
          fields: {
            luzmoSecondDashboardId: () => {
              return secondDashboardId || null;
            },
            luzmoDashboardId: () => {
              return dashboardId || null;
            },
          },
        });
      }
    },
  });

  return {
    isLoading,
    updateDashboard,
  };
};
