import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  DocNodeDocument,
  DocFullNodeDocument,
  DocBaseFragment,
  DocChildrensDocument,
  DocChildrensQueryVariables,
  DocNodeQuery,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { useLazyQueryAsync } from 'src/hooks/useLazyQueryAsync';
import { useDocId } from 'src/hooks/usePathParams';
import { getCreateDoc } from 'src/reactives/createDoc.reactive';
import { getDocImport } from 'src/reactives/docImport.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { extract } from 'src/types/graphql.types';
import { getDocKey } from 'src/utils/doc.util';
import { defaultHierarchyPagination, hierarchyPaginationMostRecent } from 'src/utils/pagination.util';

import useOptimizedBooleanState from '../useOptimizedBooleanState';
import { useProduct, useProductBase } from './useProduct';

export const useDoc = (docId?: string | null, skip = false, fetchPolicy?: WatchQueryFetchPolicy) => {
  const { product } = useProduct();
  const routeDocId = useDocId();

  const {
    data, loading,
  } = useQuery(DocNodeDocument, {
    skip: skip || (!docId && !routeDocId),
    variables: {
      id: docId || routeDocId || '',
    },
    fetchPolicy,
  });

  const doc = data?.node
    ? {
      ...data?.node,
      _docKey: getDocKey(product?.key, extract('Doc', data?.node)?.publicId),
    } as DocBaseFragment : null;

  return {
    doc,
    loading,
  };
};

type UseDocV2Options = {
  onError?: VoidFunction;
  onCompleted?: (data: DocNodeQuery) => void;
  skip?: boolean;
};

export const useDocV2 = (docId?: string | null, options?: UseDocV2Options) => {
  const { product } = useProduct();

  const {
    data, loading,
  } = useQuery(DocNodeDocument, {
    skip: !docId || options?.skip,
    variables: {
      id: docId || '',
    },
    onError: options?.onError,
    onCompleted: options?.onCompleted,
  });

  const doc = data?.node
    ? {
      ...data?.node,
      _docKey: getDocKey(product?.key, extract('Doc', data?.node)?.publicId),
    } as DocBaseFragment : null;

  return {
    doc,
    isLoading: loading,
  };
};

export const useLazyDoc = () => {
  const getDoc = useLazyQueryAsync(DocNodeDocument);
  return async (docId: string) => {
    const res = await getDoc({ id: docId });
    return res.data.node;
  };
};

type UseFullDocParams = {
  docId?: string | null;
  draft?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
} | undefined;

export const useFullDoc = ({
  docId,
  draft,
  fetchPolicy,
}: UseFullDocParams = {}) => {
  const product = useProductBase();
  const routeDocId = useDocId();
  // TODO: Refactor - Getting the daft id should not be the responsibility of this hook
  const draftId = getDocImport().draftIdOpen ?? getCreateDoc().drafts[getCreateDoc().doctypeId || ''];
  let docIdToFetch = draft ? null : (docId || routeDocId);
  if (draft) {
    docIdToFetch = draftId;
  }

  const {
    data, loading,
  } = useQuery(DocFullNodeDocument, {
    skip: !docIdToFetch,
    fetchPolicy: fetchPolicy ?? 'cache-first',
    variables: {
      id: docIdToFetch as string,
    },
  });

  const doc = data?.node
    ? {
      ...data.node,
      _docKey: getDocKey(product?.key, extract('Doc', data?.node)?.publicId),
    } as FullDocWithPublicId : null;

  return {
    doc,
    loading,
  };
};

export type UseDocChildrenOptions = undefined | {
  skip?: boolean;
  pagination?: 'most-recent' | 'default';
};

export const useDocChildren = (
  variables: Partial<Pick<DocChildrensQueryVariables, 'docId' | 'doctypeId' | 'userId' | 'direction'>>,
  options: UseDocChildrenOptions = {
    skip: false,
    pagination: 'default',
  },
) => {
  const product = useProductBase();
  const pagination = options.pagination === 'default' ? defaultHierarchyPagination : hierarchyPaginationMostRecent;
  const {
    data,
    previousData,
    loading,
    fetchMore,
  } = useQuery(DocChildrensDocument, {
    variables: {
      ...pagination,
      ...variables as DocChildrensQueryVariables,
    },
    skip: options.skip || !variables.docId || !variables.doctypeId,
  });
  const [isPaginationLoading, {
    setTrueCallback: setPaginationLoading, setFalseCallback: unsetPaginationLoading,
  }] = useOptimizedBooleanState(false);

  const loadMore = useCallback(async (cursor: string) => {
    setPaginationLoading();
    await fetchMore({
      variables: {
        ...pagination,
        ...variables,
        cursor,
      },
    });
    unsetPaginationLoading();
  }, [setPaginationLoading, fetchMore, pagination, variables, unsetPaginationLoading]);

  const children = extract('Doc', data?.node)?.children;

  const docs = useMemo(() => nodeToArray(children).map(doc => ({
    ...doc,
    _docKey: getDocKey(product?.key, doc.publicId) || '',
  })), [children, product?.key]);

  return {
    data,
    previousData,
    docs,
    count: children?.count ?? 0,
    pageInfo: children?.pageInfo,
    loading,
    loadMore,
    isPaginationLoading,
  };
};
