import { Flex, Input, Button, ActionButton } from '@cycle-app/ui';
import { LinkArrowIcon, BrushIcon, DuplicateIcon, PenIcon } from '@cycle-app/ui/icons';

import { ChangelogModalDomain } from 'src/components/ChangelogModalDomain';
import { ChangelogModalDomainRemove } from 'src/components/ChangelogModalDomainRemove';
import DialogModal from 'src/components/DialogModal/DialogModal';
import { NewBadge } from 'src/components/NewBadge';
import { PageId } from 'src/constants/routing.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { navigate, useOptimizedBooleanState } from 'src/hooks';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useChangelogDomainCheck } from 'src/hooks/releases/useChangelogDomainCheck';
import { useChangelogUpdate } from 'src/hooks/releases/useChangelogUpdate';
import { useGetPermission } from 'src/reactives';
import { changelogUrl } from 'src/utils/changelog.utils';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { ChangelogStatus } from './ChangelogStatus';
import {
  Title, Description, StyledInput, ChangeLogContainer, ChangeLogRecordContainer, Bold, ChangelogRecordLabel, ChangelogRecordValue,
} from './SettingsReleases.styles';

export const SettingsChangelog = () => {
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const { changelog } = useChangelog();
  const { canUpdateRelease } = useGetPermission();
  const domainStatus = useChangelogDomainCheck(changelog?.id);
  const [isDomainModalOpened, domainModal] = useOptimizedBooleanState(false);
  const [isPublishModalOpened, publishModal] = useOptimizedBooleanState(false);
  const [isDomainModalRemoveOpened, domainModalRemove] = useOptimizedBooleanState(false);
  if (!changelog) return null;
  const domainUrl = changelogUrl(productSlug, changelog.domain);
  return (
    <ChangeLogContainer>
      <div style={{ width: '100%' }}>
        <Flex $justify="space-between" style={{ width: '100%' }}>
          <Flex $gap={11}>
            <Title>Public changelog</Title>
            <NewBadge />
          </Flex>
          {canUpdateRelease && (
            <Button
              size="M"
              variant="secondary"
              style={{ padding: '4px 12px' }}
              iconStart={<BrushIcon size={21} />}
              onClick={() => navigate(PageId.Changelog, undefined, {
                changelogBuilder: {
                  previousPageId: PageId.SettingsReleases,
                },
              })}
            >
              Customize
            </Button>
          )}
        </Flex>
        <Description style={{ marginTop: '4px' }}>Create your own public changelog to share your releases with the world</Description>
      </div>
      <Flex $justify="space-between" $align="flex-end" $widthFull>
        <Flex $gap={8} $align="flex-end">
          <StyledInput
            label="Changelog URL"
            value={domainUrl}
            disabled
            iconAfter={(
              <Flex $gap={8}>
                <ActionButton
                  tooltip="Copy to clipboard"
                  tooltipPlacement="top"
                  onClick={() => copyToClipboard({
                    text: domainUrl,
                    notification: 'Changelog URL copied to clipboard',
                  })}
                >
                  <DuplicateIcon
                    size={16}
                  />
                </ActionButton>
                <ActionButton
                  onClick={domainModal.setTrueCallback}
                  tooltip={changelog.domain ? 'Edit custom domain' : 'Add custom domain'}
                  tooltipPlacement="top"
                >
                  <PenIcon size={16} />
                </ActionButton>
              </Flex>
            )}
          />
          <Button
            variant="outlined-alt"
            size="L"
            onClick={() => {
              if (changelog.isPublished) {
                window.open(changelogUrl(productSlug, changelog?.domain), '_blank');
              } else {
                publishModal.setTrueCallback();
              }
            }}
            iconStart={<LinkArrowIcon size={16} />}
            style={{
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
          >
            {changelog.isPublished ? 'Open changelog' : 'Publish changelog'}
          </Button>
        </Flex>
        {!domainStatus.isLoading && changelog.domain && (
          <ChangelogStatus isValid={domainStatus.isValid} refetch={domainStatus.refetch} />
        )}
      </Flex>
      {changelog.domain && (
        <ChangeLogRecordContainer>
          <div>
            Please point
            {' '}
            <Bold>{changelog.domain}</Bold>
            {' '}
            to Cycle by configuring the following A records.
          </div>
          <Flex $widthFull style={{ marginTop: '24px' }}>
            <Flex $column $grow={1} $gap={8} $align="flex-start" style={{ maxWidth: '170px' }}>
              <ChangelogRecordLabel>Record type</ChangelogRecordLabel>
              <ChangelogRecordValue $isFirst>A</ChangelogRecordValue>
            </Flex>
            <Flex $column $grow={1} $gap={8} $align="flex-start" style={{ overflow: 'hidden' }}>
              <ChangelogRecordLabel>Host</ChangelogRecordLabel>
              <ChangelogRecordValue>
                <span>{changelog.domain}</span>
              </ChangelogRecordValue>
            </Flex>
            <Flex $column $grow={1} $gap={8} $align="flex-start" style={{ maxWidth: '300px' }}>
              <ChangelogRecordLabel>Value</ChangelogRecordLabel>
              <ChangelogRecordValue $isLast>
                137.66.44.150
                <ActionButton tooltip="Copy to clipboard" tooltipPlacement="top" style={{ marginLeft: '4px' }}>
                  <DuplicateIcon
                    size={16}
                    onClick={() => copyToClipboard({
                      text: '137.66.44.150',
                      notification: 'IP copied to clipboard',
                    })}
                  />
                </ActionButton>
              </ChangelogRecordValue>
            </Flex>
          </Flex>
        </ChangeLogRecordContainer>
      )}
      {isDomainModalOpened && (
        <ChangelogModalDomain
          onRemove={() => {
            domainModal.setFalseCallback();
            domainModalRemove.setTrueCallback();
          }}
          onComplete={domainStatus.refetch}
          domain={changelog.domain || ''}
          changelogId={changelog.id}
          hide={domainModal.setFalseCallback}
        />
      )}
      {changelog.domain && isDomainModalRemoveOpened && (
        <ChangelogModalDomainRemove
          domain={changelog.domain}
          changelogId={changelog.id}
          hide={domainModalRemove.setFalseCallback}
        />
      )}
      {isPublishModalOpened && <ChangelogPublishModal hide={publishModal.setFalseCallback} />}
    </ChangeLogContainer>
  );
};

export const ChangelogPublishModal = ({ hide }: { hide: VoidFunction }) => {
  const productSlug = useWorkspaceContext(ctx => ctx.productSlug);
  const {
    changelogUpdate, isLoading: isUpddateLoading,
  } = useChangelogUpdate();
  const { changelog } = useChangelog();
  return (
    <DialogModal
      type="default"
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      loading={isUpddateLoading}
      title="Publish changelog"
      confirmLabel="Publish"
      hide={hide}
      onConfirm={async () => {
        if (changelog) {
          const result = await changelogUpdate({
            id: changelog.id,
            isPublished: true,
          });
          if (result?.data?.updateChangelog?.id) {
            hide();
          }
        }
      }}
      info={(
        <Flex $column $gap={8} $align="stretch">
          <div>Your changelog will become public at the address:</div>
          <Input
            disabled
            value={changelogUrl(productSlug, changelog?.domain)}
          />
        </Flex>
      )}
    />
  );
};
