import { createGlobalStyle } from 'styled-components';

import { InputCss } from './components/Inputs/Input/Input.styles';
import { Spacing } from './constants/styles.constant';
import { body400 } from './theme/typo';

export const GlobalStyles = createGlobalStyle`
  :root {
    /* Spacing variables */
    --s-1: ${Spacing.S1}px;
    --s-2: ${Spacing.S2}px;
    --s-3: ${Spacing.S3}px;
    --s-4: ${Spacing.S4}px;
    --s-5: ${Spacing.S5}px;
    --s-6: ${Spacing.S6}px;
    --s-7: ${Spacing.S7}px;
    --s-8: ${Spacing.S8}px;
    --s-9: ${Spacing.S9}px;
    --s-10: ${Spacing.S10}px;
    --s-11: ${Spacing.S11}px;
    --s-12: ${Spacing.S12}px;
    --s-13: ${Spacing.S13}px;
  }

  html {
    height: 100%;
  }

  body {
    font-family: Inter, sans-serif;
    ${body400}
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

    margin: 0;
    padding: 0;

    background-color: ${p => p.theme.colors.body.bg};
    color: ${p => p.theme.colors.body.color};

    overflow: hidden;
    height: 100%;
  }

  /* Firefox hack: https://www.lockedownseo.com/firefox-font-weight-too-bold */
  @-moz-document url-prefix() {
    body {
      font-weight: lighter !important;
    }
  }

  #app {
    width: 100%;
    height: 100%;
  }

  .highlight {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    mark {
      color: ${p => p.theme.colors.mark.color};
      background-color: ${p => p.theme.colors.mark.bg};
    }
  }

  img {
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ul, li, input, label, strong, u, s, em  {
    padding: 0;
    margin: 0;
    user-select: text;
    span {
      user-select: text;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  * {
    box-sizing: border-box;
    user-select: none;
  }

  pre, code {
    font-family: Roboto Mono, monospace;
  }

  button {
    border: none;
    background: none;
    font-family: Inter, sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    color: inherit;
  }

  input {
    accent-color: hsl(var(--cycle));
  }

  /* to unset the default css values set globally */
  input, mark, textarea {
    color: unset;
    font-family: unset;
  }

  /* Chrome: ellipsis is applied only when the input is not focused */
  input::placeholder {
    text-overflow: ellipsis;
  }

  /* @Todo handle focus styles */
  *[role="button"], *[tabindex] {
    outline: none;
  }

  ::selection {
    background-color: ${p => p.theme.userColors.textHighlight};
  }

  #portal-selection {
    z-index: 200;
    position: absolute;
    background-color: hsla(var(--cycle), 0.1);
    border-color: hsla(var(--cycle), 0.15);
    border-width: 0;
    border-style: solid;
  }

  #portal-toaster {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .emoji-mart {
    background-color: transparent;
    border-radius: 0;
    border: none;
    button {
      cursor: pointer;
    }
  }

  .emoji-mart-category {
    li, button.emoji-mart-emoji, .emoji-mart-emoji span {
      cursor: pointer;
    }
  }

  .emoji-mart-category .emoji-mart-emoji:hover:before {
    border-radius: 4px;
  }

  .emoji-mart-search-icon {
    display: none;
  }

  .emoji-mart-search {
    margin-top: 8px;
    padding-bottom: 6px;
    input {
      ${InputCss};
    }
  }

  .tippy-box {
    max-width: auto !important;
  }

  /* default */
  --keyboard-height: 0px;

  :root {
    --cycle: var(--cycle500);
    --cycle100: 232, 79.7%, 94%;
    --cycle200: 232, 79.7%, 90%;
    --cycle300: 232, 79.7%, 86%;
    --cycle400: 232, 79.7%, 80%;
    --cycle500: 232, 79.7%, 53.7%;
    --cycle600: 232, 79.7%, 48%;
    --cycle700: 232, 79.7%, 41%;
    --cycle800: 232, 79.7%, 35%;
    --cycle900: 232, 79.7%, 30%;
  }
`;

export const GlobalStorybookStyles = createGlobalStyle`
  body {
    overflow: visible;
  }
`;
