import { FC } from 'react';

import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { useTippyOffsetAdapter } from 'src/hooks/useTippyOffsetAdapter';
import { Layer } from 'src/types/layers.types';

import { DocSearch, DocSearchProps, SubmitParams } from '../DocSearch/DocSearch';

import type { Placement } from 'tippy.js';

type TargetContent = 'link' | 'docId';

export interface DocSearchDropdownProps extends DocSearchProps {
  className?: string;
  dropdownProps?: Partial<DropdownLayerProps>;
  hideSearchDropdown: VoidFunction;
  isSearchDropdownVisible?: boolean;
  onSubmit?: (p: SubmitParams) => void;
  placeholder?: string;
  placement?: Placement;
  targetContent?: TargetContent;
  layer?: Layer;
}

const DocSearchDropdown: FC<React.PropsWithChildren<DocSearchDropdownProps>> = ({
  children,
  className = '',
  dropdownProps,
  hideSearchDropdown,
  inheritedAttributes,
  isSearchDropdownVisible = false,
  onAdd: onAddProps,
  onSubmit,
  placeholder = 'Search...',
  placement = 'bottom-start',
  targetContent = 'docId',
  layer,
  ...docSearchProps
}) => {
  const offsetAdapter = useTippyOffsetAdapter();
  return (
    <DropdownLayer
      visible={isSearchDropdownVisible}
      hide={hideSearchDropdown}
      placement={placement}
      layer={layer}
      content={(
        <DocSearch
          className={className}
          inheritedAttributes={inheritedAttributes}
          onAdd={onAddProps}
          onSubmit={onSubmit}
          placeholder={placeholder}
          targetContent={targetContent}
          onOptionsChange={offsetAdapter.forceUpdate}
          {...docSearchProps}
        />
      )}
      {...offsetAdapter.tippyProps}
      {...dropdownProps}
    >
      {children}
    </DropdownLayer>
  );
};

export default DocSearchDropdown;
