import { CycleLogo } from '@cycle-app/ui';
import { InboxIcon } from '@cycle-app/ui/icons';
import { Gradient } from '@cycle-app/ui/icons/Gradient';

import { useProductBase } from 'src/app/NewDoc/Product/useProductBase';
import { WorkspaceProvider } from 'src/contexts/workspaceContext';

import { Form, FormContent } from './Form';
import { Me } from './Me';
import {
  NewDocContainer, Header, Title, Body, Scrollable, Content,
} from './NewDoc.styles';
import { ProductCurrent } from './Product';
import { Settings } from './Settings/Settings';

export const NewDoc = () => {
  const { product } = useProductBase();
  return (
    <NewDocContainer>
      <Header>
        <CycleLogo animation="hover" size={20} />

        <Title>
          New
          <InboxIcon />
          Feedback
        </Title>

        <ProductCurrent />

        <Me />

        <Settings />
      </Header>

      <Body>
        <Scrollable>
          <Content>
            <Form>
              {!!product?.id && (
                <WorkspaceProvider productId={product.id} productSlug={product.slug}>
                  <FormContent />
                </WorkspaceProvider>
              )}
            </Form>
          </Content>
        </Scrollable>
      </Body>

      <Gradient />
    </NewDocContainer>
  );
};
