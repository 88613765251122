import { DocBaseFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, ReactNode, FC } from 'react';

import { InsightDocSearchDropdown } from 'src/components/DocSearchDropdown/InsightDocSearchDropdown';
import { InsightCreateModal } from 'src/components/InsightCreateModal';
import { InsightsSearch } from 'src/components/InsightsSearch';
import { useDocTypeInsight, useOptimizedBooleanState, useDocV2 } from 'src/hooks';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocTypeNames } from 'src/utils/docType.util';

export const OpenInsightCreateModal: FC<React.PropsWithChildren<{
  layer?: Layer;
  parentDoc: DocBaseFragment;
  button: (props: {
    onClick: VoidFunction;
    isOpen: boolean;
  }) => ReactNode;
}>> = ({
  layer = Layer.DropdownModalZ2,
  button,
  parentDoc,
}) => {
  const [isOpen, actions] = useOptimizedBooleanState(false);
  const parentDocType = useGetDocType(parentDoc.doctype.id);

  if (parentDocType?.type === DoctypeType.Feedback) {
    return (
      <OpenFeedbackInsightCreateModal
        layer={layer}
        parentDoc={parentDoc}
        button={button}
      />
    );
  }

  return (
    <InsightsSearch
      parentId={parentDoc.id}
      visible={isOpen}
      hide={actions.setFalseCallback}
      layer={layer}
      createModal={modalProps => (
        <InsightCreateModal
          hide={modalProps.hide}
          hideParentDoc
          isOpen={modalProps.visible}
          parentDoc={parentDoc}
          dropdownProps={{
            placement: 'bottom-start',
            layer,
          }}
          createFeedback
          inheritPropertiesFrom="parent"
        >
          {button({
            onClick: actions.setTrueCallback,
            isOpen: isOpen || modalProps.visible,
          })}
        </InsightCreateModal>
      )}
    />
  );
};

const OpenFeedbackInsightCreateModal: FC<React.PropsWithChildren<{
  layer?: Layer;
  parentDoc: DocBaseFragment;
  button: (props: {
    onClick: VoidFunction;
    isOpen: boolean;
  }) => ReactNode;
}>> = ({
  layer = Layer.DropdownModalZ2,
  button,
  parentDoc,
}) => {
  const [isOpen, actions] = useOptimizedBooleanState(false);
  const [insightDocParentId, setInsightDocParentId] = useState('');
  const {
    doc, isLoading: isParentLoading,
  } = useDocV2(insightDocParentId);

  const { insight } = useDocTypeInsight();
  const possibleDocTypes = nodeToArray(insight?.parents);

  return (
    <InsightCreateModal
      isOpen={!!doc?.id}
      isLoading={isParentLoading}
      hide={() => setInsightDocParentId('')}
      parentDoc={doc}
      feedbackDoc={parentDoc}
      defaultContent={parentDoc.title}
      dropdownProps={{
        placement: 'bottom-start',
        layer,
      }}
    >
      <InsightDocSearchDropdown
        hideSearchDropdown={actions.setFalseCallback}
        isSearchDropdownVisible={isOpen}
        onAdd={(docId) => {
          setInsightDocParentId(docId);
          actions.setFalseCallback();
        }}
        childDoctypeId={insight?.id}
        possibleDoctypes={possibleDocTypes}
        placeholder={getDocTypeNames(possibleDocTypes, {
          prefix: 'Link to',
          suffix: '',
        })}
        layer={Layer.DropdownZ1}
      >
        {button({
          onClick: actions.setTrueCallback,
          isOpen,
        })}
      </InsightDocSearchDropdown>
    </InsightCreateModal>
  );
};
