import { Input, ActionButton, Button } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useId } from 'react';
import { useForm } from 'react-hook-form';

import { useProduct } from '../../hooks';
import { useUpdateDashboard } from '../../hooks/dashboard/useUpdateDashboard';
import { setDashboardUpdateModal, useGetDashboardUpdateModal } from '../../reactives/dashboardUpdateModal.reactive';
import { addToaster } from '../../utils/toasters.utils';
import { Form, FormFooter, HeaderModal, StyledPortalModal, Title } from './DashboardUpdateModal.styles';

export const DashboardUpdateModal = () => {
  const { isOpened } = useGetDashboardUpdateModal();
  return isOpened ? <DashboardUpdateModalContent /> : null;
};

type FormValues = { dashboardId: string; datasetId: string; secondDashboardId: string };

const DashboardUpdateModalContent = () => {
  const { product } = useProduct();
  const {
    isLoading, updateDashboard,
  } = useUpdateDashboard();
  const {
    register, handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      dashboardId: product?.luzmoDashboardId || '',
      datasetId: '',
      secondDashboardId: product?.luzmoSecondDashboardId || '',
    },
  });

  const hide = () => setDashboardUpdateModal({ isOpened: false });

  const onSubmit = async (formValues: FormValues) => {
    const result = await updateDashboard({
      dashboardId: formValues.dashboardId,
      datasetId: formValues.datasetId,
      secondDashboardId: formValues.secondDashboardId,
    });
    if (result.data?.updateLuzmoVariables) {
      addToaster({
        title: 'Dashboards updated',
      });
      hide();
    }
  };

  const dashboardId = useId();
  const datasetId = useId();
  const secondDashboardId = useId();
  return (
    <StyledPortalModal hide={hide}>
      <HeaderModal>
        <Title>Update dashboards</Title>
        <ActionButton onClick={hide}>
          <CloseIcon />
        </ActionButton>
      </HeaderModal>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id={dashboardId}
          label="Dashboard ID"
          helperSize="S"
          autoFocus
          {...register('dashboardId')}
        />
        <Input
          id={datasetId}
          label="Dataset ID"
          helperSize="S"
          {...register('datasetId')}
        />
        <Input
          id={secondDashboardId}
          label="Second Dashboard ID"
          helperSize="S"
          {...register('secondDashboardId')}
        />
        <p>Nb: Empty fields will reset the values</p>
        <FormFooter>
          <Button
            size="M"
            variant="secondary"
            onClick={hide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            size="M"
            isLoading={isLoading}
          >
            Update
          </Button>
        </FormFooter>
      </Form>
    </StyledPortalModal>
  );
};
