/**
 * Functions for creating Appolo reactive variables
 * Can be used to replace make(), which has typing issues and can only create objects
 *
 * Features
 * - Variable updatable with a new value or using an updater function, as with React.useState
 * - Immer support, useful for updating objects
 * - Optional local storage persistence and validation using Zod
 * - Optional cross-tab synchronization
 * - Toggle function for boolean variables
 *
 * Performance optimizations
 * - Rerenders only when the value changes
 * - Shallow comparison for object variables
 * - Optional equality function
 */

import { makeVar as apolloMakeVar, useReactiveVar } from '@apollo/client';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import shallowequal from 'shallowequal';
import { z } from 'zod';

import { getLocalStorageItem, subscribeToLocalStorage } from 'src/utils/localStorage.utils';

const isFunction = <T>(value: T | ((value: T) => T)): value is (value: T) => T => {
  return typeof value === 'function';
};

type StorageOptions<T> = {
  key: string;
  schema: z.Schema<T>;
  crossTabSync?: boolean;
} | {
  key?: never;
  schema?: never;
  crossTabSync?: never;
};

type MakeVarOptions<T> = StorageOptions<T> & {
  areEqual?: (a: T, b: T) => boolean;
};

/**
 * Create a reactive variable
 *
 * @example // Basic usage
 * export const countVar = makeVar(0);
 * export const useCount = countVar.hook;
 * const count = useCount();
 * countVar.get();
 * countVar.set(1);
 * countVar.set(n => n + 1);
 * countVar.reset();
 *
 * @example // All options
 * export const itemsVar = makeVar([], {
 *   key: 'items',
 *   schema: z.array(z.object({ id: z.string() })),
 *   areEqual: lodash.isEqual,
 * });
 * itemsVar.produce(items => { items.push({ id: '123' }) });
 */
export const makeVar = <T>(defaultValue: T, {
  schema,
  key,
  crossTabSync = false,
  areEqual = shallowequal,
}: MakeVarOptions<T> = {},
) => {
  const initialValue = !key ? defaultValue : getLocalStorageItem({
    key,
    schema,
    defaultValue,
  });

  const variable = apolloMakeVar<T>(initialValue);

  const persist = key
    ? debounce((value: T) => localStorage.setItem(key, JSON.stringify(value)), 1000)
    : undefined;

  if (key && crossTabSync) {
    subscribeToLocalStorage({
      key,
      callback: variable,
      schema,
    });
  }

  const setValue = (value: T) => {
    if (areEqual(variable(), value)) return;
    variable(value);
    persist?.(value);
  };

  return {
    get: () => variable(),
    set: (param: T | ((prev: T) => T)) => {
      const newValue = isFunction(param) ? param(variable()) : param;
      setValue(newValue);
    },
    produce: (updater: (draft: T) => void) => {
      const newValue = produce(variable(), updater);
      setValue(newValue);
    },
    reset: () => {
      variable(defaultValue);
      if (key) localStorage.removeItem(key);
    },
    // eslint-disable-next-line react-hooks/rules-of-hooks
    hook: () => useReactiveVar(variable),
  };
};

type MakeBooleanOptions = Omit<MakeVarOptions<boolean>, 'schema' | 'areEqual'>;

/**
 * Create a reactive boolean variable
 *
 * @example // Basic usage
 * export const openVar = makeBooleanVar(false);
 * export const useOpen = openVar.hook;
 * const open = useOpen();
 * openVar.toggle();
 *
 * @example // All options
 * export const openVar = makeBooleanVar(false, {
 *   key: 'open',
 *   crossTabSync: true,
 * });
 */
export const makeBooleanVar = (defaultValue: boolean, {
  key, crossTabSync,
}: MakeBooleanOptions = {}) => {
  const variable = !key
    ? makeVar(defaultValue)
    : makeVar(defaultValue, {
      key,
      crossTabSync,
      schema: z.boolean(),
    });

  return {
    ...variable,
    toggle: () => variable.set(prev => !prev),
    setTrue: () => variable.set(true),
    setFalse: () => variable.set(false),
  };
};
