import { Role } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { EmptyState } from 'src/components/Views';
import { WorkspaceProvider } from 'src/contexts/workspaceContext';
import { useMaybeMeV2, useProduct } from 'src/hooks';
import { useInitPermission } from 'src/hooks/useInitPermission';
import { setProductStatus, useProductStatus } from 'src/reactives/product.reactive';
import { getProductStatus } from 'src/utils/product.utils';

import { Main } from './Main';
import { TrialEnded, ProEnded } from './ProductEmptyState';

export const ProductSwitch = () => {
  const { me } = useMaybeMeV2();
  const isSuperAdmin = me?.role === Role.SuperAdmin;

  useInitPermission();
  const { product } = useProduct();
  const { productStatus } = useProductStatus();
  useEffect(() => {
    setProductStatus({
      productStatus: getProductStatus(product, isSuperAdmin),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product?.id,
    product?.endOfFreeTrial,
    product?.subscriptionPeriodEnd,
    product?.plan,
  ]);

  switch (productStatus) {
    case 'trial':
    case 'pro':
    case 'pro-cancelled':
      if (!product) return null;
      return (
        <WorkspaceProvider productId={product.id} productSlug={product.slug}>
          <Main />
        </WorkspaceProvider>
      );
    case 'pro-ended':
      return <ProEnded />;
    case 'trial-ended':
      return <TrialEnded />;
    case 'unavailable':
      return <EmptyState mode="workspace-access" />;
    default:
      return null;
  }
};
