import {
  ActivityIcon,
  AiIcon,
  AiShadowIcon,
  AttioIcon,
  ChartIcon,
  ConfluenceIcon,
  CustomerIcon,
  FileIcon,
  FreshDeskIcon,
  GainsightIcon,
  GearIcon,
  GeneralOutlineIcon,
  GridCircleIcon,
  HubSpotIcon,
  JiraIcon,
  LayerIcon,
  LayoutIcon,
  MicrosoftTeamsIcon,
  PhoneIcon,
  RecordIcon,
  SalesforceIcon,
  SignalIcon,
  SlackIcon,
  SnowflakeIcon,
  SuitCaseWorkIcon,
  TagIcon,
  WheelIcon,
  ZendeskIcon,
} from '@cycle-app/ui/icons';
import planhatUrl from '@cycle-app/ui/icons/social/planhat.png';
import vitallyUrl from '@cycle-app/ui/icons/social/vitally.png';

import { AddOn } from 'src/types/addOn.types';

import { MAX_MONTHLY_AI_QUERIES } from './ai.constants';

import type { ReactNode } from 'react';

export const mapAddOnsData: Record<AddOn['name'], {
  logo: ReactNode;
  logoV2?: ReactNode;
  name: string;
  description: string;
}> = {
  ANALYSES: {
    logo: <ChartIcon />,
    logoV2: <LayoutIcon isBump />,
    name: 'Customer voice dashboard',
    description: 'Visualize your customer insights in a whole new way with a powerful and interactive dashboard.',
  },
  HUBSPOT: {
    logo: <HubSpotIcon />,
    name: 'HubSpot integration',
    description: 'Sync your customers, send feedback and get updates in Hubspot.',
  },
  SALESFORCE: {
    logo: <SalesforceIcon />,
    name: 'Salesforce',
    description: 'Sync your customers, send feedback and get updates in Salesforce.',
  },
  'microsoft-teams': {
    logo: <MicrosoftTeamsIcon />,
    name: 'Microsoft Teams',
    description: 'Sync your customers, send feedback and get updates in Microsoft Teams.',
  },
  attio: {
    logo: <AttioIcon />,
    name: 'Attio',
    description: 'Sync your customers, send feedback and get updates in Attio.',
  },
  zendesk: {
    logo: <ZendeskIcon />,
    name: 'Zendesk',
    description: 'Sync your customers, send feedback and get updates in Zendesk.',
  },
  'snow-flake': {
    logo: <SnowflakeIcon />,
    name: 'Snowflake',
    description: 'Sync your customers, send feedback and get updates in Snowflake.',
  },
  'fresh-desk': {
    logo: <FreshDeskIcon />,
    name: 'Freshdesk',
    description: 'Sync your customers, send feedback and get updates in Freshdesk.',
  },
  'grain-sight': {
    logo: <GainsightIcon />,
    name: 'Gainsight',
    description: 'Sync your customers, send feedback and get updates in Grainsight.',
  },
  'plan-hat': {
    logo: <img width={18} src={planhatUrl} alt="planhat-logo" />,
    name: 'Planhat',
    description: 'Sync your customers, send feedback and get updates in Planhat.',
  },
  jira: {
    logo: <JiraIcon />,
    name: 'Jira',
    description: "Create user stories or epics right from Cycle or link existing ones to Cycle's Features.",
  },
  confluence: {
    logo: <ConfluenceIcon />,
    name: 'Confluence',
    description: "Embed Confluence's pages and create or update them from Cycle.",
  },
  vitally: {
    logo: <img width={18} src={vitallyUrl} alt="vitally-logo" />,
    name: 'Vitally',
    description: 'Sync your customers, send feedback and get updates in Vitally.',
  },
  'slack-advanced': {
    logo: <SlackIcon />,
    name: 'Advanced Slack features',
    description: 'Use your branding for public notifications, link several workspaces, and create reacjis.',
  },
  'unlimited-workspaces': {
    logo: <LayerIcon />,
    logoV2: <SuitCaseWorkIcon />,
    name: 'Unlimited workspaces',
    description: 'Create as many workspaces as you want and duplicate any workspace setup.',
  },
  UNLIMITED_PROPERTIES: {
    logo: <TagIcon />,
    logoV2: <TagIcon isBump />,
    name: 'Unlimited properties',
    description: 'Drop the limit of 15 and create unlimited custom properties for advanced tagging, clustering & prioritization.',
  },
  CUSTOMER_ATTRIBUTES: {
    logo: <CustomerIcon />,
    logoV2: <CustomerIcon isBump />,
    name: 'Customers attributes',
    description: 'Add attributes to your customers, sync them with your sources, and use them for prioritization',
  },
  UNLIMITED_AI_QUERIES: {
    logo: <AiIcon hasGradient />,
    logoV2: <AiShadowIcon />,
    name: 'Unlimited Cycle AI',
    description: `Drop the ${MAX_MONTHLY_AI_QUERIES} AI queries limit and automatically find insights in feedback and features or write PRDs with predefined and custom prompts.`,
  },
  saml: {
    logo: <WheelIcon />,
    logoV2: <GearIcon isBump />,
    name: 'SAML-based SSO',
    description: 'Scale administration with secure Single Sign On.',
  },
  'activity-log': {
    logo: <ActivityIcon />,
    logoV2: <SignalIcon isBump />,
    name: 'Activity log',
    description: 'Track activity across your workspaces and access the full history of changes',
  },
  'admin-tools': {
    logo: <WheelIcon />,
    logoV2: <GearIcon isBump />,
    name: 'Admin tools & Private teams',
    description: 'Control who are the admins and let them restrict access to private teams',
  },
  UNLIMITED_DOCTYPES: {
    logo: <GeneralOutlineIcon />,
    logoV2: <GridCircleIcon />,
    name: 'Unlimited doc types',
    description: 'Drop the doc types limit of 5 and create as many doc types as you want with unlimited flexibility.',
  },
  MEETINGS: {
    logo: <PhoneIcon />,
    logoV2: <RecordIcon isBump />,
    name: 'Meeting recording',
    description: 'Use Cycle to record & transcribe meetings from Zoom, Meet & Teams.',
  },
  'file-size-unlimited': {
    logo: <FileIcon />,
    logoV2: <FileIcon isBump />,
    name: 'Unlimited file size',
    description: 'Drop the 20\u00A0MB limit for images/PDFs and allow files of up to 100\u00A0MB to be uploaded.',
  },
};
