import { PenIcon, TrashIcon } from '@cycle-app/ui/icons';
import { useMemo } from 'react';

import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openUpdateReleaseNote, openRemoveReleaseNote } from 'src/reactives/releases.reactive';

import { StyledDotsMenu } from './ReleaseNoteCard.styles';

export const ReleaseNoteActionsMenu = ({
  noteId,
  actions,
  disabled,
}: {
  noteId: string;
  actions?: string[];
  disabled?: boolean;
}) => {
  const {
    canUpdateReleaseNote, canDeleteReleaseNote,
  } = useGetPermission();

  const options = useMemo(() => {
    const result = [];

    if (!actions || actions?.includes('edit')) {
      result.push({
        value: 'edit',
        label: 'Edit',
        icon: <PenIcon />,
        onSelect: canUpdateReleaseNote ? openUpdateReleaseNote(noteId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
        disabled,
        tooltipContent: disabled ? 'Make updates or unpublish first' : null,
      });
    }

    if (!actions || actions?.includes('delete')) {
      result.push({
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        onSelect: canDeleteReleaseNote ? openRemoveReleaseNote(noteId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
        disabled,
        tooltipContent: disabled ? 'Make updates or unpublish first' : null,
      });
    }

    return result;
  }, [actions, canDeleteReleaseNote, canUpdateReleaseNote, noteId, disabled]);

  if (options.length === 0) return null;

  return (
    <StyledDotsMenu
      $forceVisible={false}
      placement="bottom-end"
      options={options}
    />
  );
};
